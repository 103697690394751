<template>
    <v-container>
         <v-card>
            <v-card-title>
            <span class="headline"></span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form">
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="editedItem.Password"
                                    :label="$store.state.translations[$store.state.lang].tempPassword"
                                    @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="editedItem.Forename"
                                    :label="$store.state.translations[$store.state.lang].forename"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="editedItem.Surname"
                                    :label="$store.state.translations[$store.state.lang].surname"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>                     
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="$router.push('/reportHome')"
                >
                    {{$store.state.translations[$store.state.lang].cancel}}
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="save"
                >
                    {{$store.state.translations[$store.state.lang].save}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
const axios = require('axios')
import consts from './../helpers/Consts'

export default {
    
    data () {
        return {
            show: false,
            editedItem: {},
            rules: {
                required: value => !!value || 'Verplicht',
                counter: value => value.length <= 20 || 'Maximaal 20 karakters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Ongeldig e-mail'
                },
                password: value => !!value || 'Verplicht',
            }
        }
    },
    computed: {

    },
    methods: {
        save: async function(){
            try {
                let validForm = false;

                try {
                    validForm =this.$refs.form.validate()
                    this.uiKey = Math.random()
                } catch (e) {
                    //console.log(e)
                    validForm = false
                }

                //console.log(`valid form is ${validForm}`)

                if(validForm){
                    //console.log(`item is ${JSON.stringify(this.editedItem, undefined, 2)}`)
                    if(this.editedItem.ID > 0){
                        await axios.post(consts[consts.env].apiURL + '/editAllInObjectReportUser/reportUserModel', [this.editedItem],
                            {
                                headers: {
                                     email: this.$store.state.reportUser.Email,
                                    password: this.$store.state.reportUser.Password
                                }
                            }
                        )
                        // replace local version
                        this.$store.state.reportUser = JSON.parse(JSON.stringify(this.editedItem))
                    } 
                    this.dialog = false
                    this.$router.push('/reportHome')
                }
            } catch (e) {
                console.log(e)
                this.dialog = false
            }        
        }
    },
    created: function(){
        this.editedItem = JSON.parse(JSON.stringify(this.$store.state.reportUser))
    }
}
</script>