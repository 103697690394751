<template>
    <span>
        <v-row justify="center">
            <v-col cols="12" class="pa-1 ma-1">
                <span v-for="sub in subSections" :key="sub.subSectionDetails.ID">

                    <span v-if="sub.subSectionDetails.EnDesc==null || sub.subSectionDetails.EnDesc.length==0">
                        <questions 
                            :questions="sub.questions" 
                            :subSectionDetails="sub.subSectionDetails">
                        </questions>
                    </span>
                    <v-expansion-panels accordion v-else>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <h5> {{sub.subSectionDetails[`${$store.state.lang}Desc`]}} </h5>
                            </v-expansion-panel-header>
                            
                            <v-expansion-panel-content>
                                <!-- headings above tables -->
                                <span v-if="sub.subSectionDetails.SubsectionSectionAppID==17">
                                    <h5> 
                                        Het voorkomen van bepaalde aandoeningen in de afgelopen 12 maanden op basis van het aantal aanwezige volwassen runderen/ jongvee.
                                    </h5>
                                    <br>
                                </span>
                                <span v-if="sub.subSectionDetails.SubsectionSectionAppID==18">
                                    <h5> 
                                        Het antibioticagebruik wordt ingelezen vanuit MediRund rapportage op basis van de meest recent beschikbare kwartaalrapportage.
                                    </h5>
                                    <br>
                                </span>
                                <questions 
                                    :questions="sub.questions" 
                                    :subSectionDetails="sub.subSectionDetails">
                                </questions>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>



                </span>
            </v-col>
        </v-row>
        
    </span>
</template>

<script>
import questions from '@/components/QuestionForm.vue'

export default {
    props: ['subSections'], 
    components: {questions},
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    created: function(){

    }
}
</script>