const axios = require('axios')
const _ = require('lodash')

export default {
    data () {
        return {
            refreshQuestions: true,
            refreshCowAssesment: true,
            refreshAbnormal: true,
            refreshActionPoints: true,
            refreshEvaluationPoints: true,
            offline: false,
        }
    },
    methods: {
        checkIfOfflineAndShowCacheSnackbar: async function(){
            try {
                let offline = await axios.get('/testOnline')
                return false
                // we have connection but a different error, e.g. auth
            } catch (e) {
                // we don't have connection
                //console.log(e)
                this.$store.state.snackBarText = `${this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection}`
                this.$store.snackBarColour = 'error'
                this.$store.state.showSnackBar = true
                return true
            }
        },
        generateID: function(){
            return this.$uuid.v4()
        },
        saveAll: async function(){
            //console.log(`saving all`)
            try {
                let res = await axios.get('/testOnline')
                //console.log(`online is ${JSON.stringify(res, undefined, 2)}`)
                this.offline = false
            } catch (e) {
                console.log(e)
                this.offline = true
            }
            if(this.$store.state.savingAll == false){
                this.$store.state.savingAll = true
                try {
                    if(this.offline==true){
                        throw('Device is offline or API is unreachable')
                    }

                    this.refreshAbnormal = false
                    this.refreshActionPoints = false
                    this.refreshCowAssesment = false
                    this.refreshEvaluationPoints = false
                    this.refreshQuestions = false
     
                    let questionsToAdd = []
                    let questionsToEdit = []
    
                    let cowAssessmentToAdd = []
                    let cowAssessmentEdit = []
    
                    let abnormalToAdd = []
                    let abnormalToEdit = []
    
                    let actionPointsToAdd = []
                    let actionPointsToEdit = []
    
                    let evaluationPointsToAdd = []
                    let evaluationPointsToEdit = []
    
                    let headers = await this.$store.state.zlogin.getHeaders()
    
    
                    for(let ansKey in this.$store.state.bgp.answers){
                        // cowAssessment and abnormal have their own tables
                        if(ansKey == 'cowAssessment'){
                            this.$store.state.bgp.answers.cowAssessment.forEach((assess)=>{
                                if(assess.UUID){
                                    cowAssessmentEdit.push(
                                            assess
                                        )
                                } else {
                                    if(assess.AnimalID.length>0){
                                        cowAssessmentToAdd.push({
                                            'ID': null,
                                            'UUID': this.generateID(),
                                            'BGPID': this.$store.state.bgp.UUID,
                                            'AnimalID': assess.AnimalID,
                                            'BCS': assess.BCS,
                                            'MobilityScore': assess.MobilityScore,
                                            'ReumenFill': assess.ReumenFill,
                                            'HockScore': assess.HockScore,
                                            'NumberOfLesions': assess.NumberOfLesions,
                                            'HygeneScore': assess.HygeneScore,
                                            'FaecalScore': assess.FaecalScore,
                                        })
                                    }
                                }
                            })
                            ////console.log('processing cow assessment for saving')
                        } else if (ansKey == 'abnormal'){
                            //console.log('processing abnormal for saving')
                            this.$store.state.bgp.answers.abnormal.forEach((abnormal)=>{
                                if(abnormal.UUID){
                                    abnormalToEdit.push(abnormal)
                                } else {
                                    if(abnormal.AnimalID.length>0){
                                        abnormalToAdd.push({
                                            'ID': null,
                                            'UUID': this.generateID(),
                                            'BGPID': this.$store.state.bgp.UUID,
                                            'AnimalID': abnormal.AnimalID,
                                            'GeneralImpression': abnormal.GeneralImpression,
                                            'Respiration': abnormal.Respiration,
                                            'HeartRate': abnormal.HeartRate,
                                            'Temperature': abnormal.Temperature,
                                            'BCS': abnormal.BCS,
                                            'ReumenFill': abnormal.ReumenFill,
                                            'MobilityScore': abnormal.MobilityScore,
                                            'ExcessiveDischarge': abnormal.ExcessiveDischarge,
                                            'Udder': abnormal.Udder,
                                            'OtherAbnormalities': abnormal.OtherAbnormalities,
                                            'NEBData': abnormal.NEBData,
                                            'CaseOfNEB':abnormal.CaseOfNEB,
                                        })
                                    }
                                }
                            })
                        } else {
                            if(this.$store.state.bgp.answers[ansKey].UUID){
                                ////console.log(`patching question ${ansKey}`)
                                questionsToEdit.push({
                                    'ID': null,
                                    'UUID': this.$store.state.bgp.answers[ansKey].UUID,
                                    'FormQuestionAppID': ansKey,
                                    'BGPID': this.$store.state.bgp.UUID,
                                    'Value': this.$store.state.bgp.answers[ansKey].Value,
                                    'PrevBGPValue': this.$store.state.bgp.answers[ansKey].PrevBGPValue,
                                    'TargetValue': this.$store.state.bgp.answers[ansKey].TargetValue,
                                    'Usage': this.$store.state.bgp.answers[ansKey].Usage,
                                })
                            } else {
                                questionsToAdd.push({
                                    'ID': null,
                                    'UUID': this.generateID(),
                                    'FormQuestionAppID': ansKey,
                                    'BGPID': this.$store.state.bgp.UUID,
                                    'Value': this.$store.state.bgp.answers[ansKey].Value,
                                    'PrevBGPValue': this.$store.state.bgp.answers[ansKey].PrevBGPValue,
                                    'TargetValue': this.$store.state.bgp.answers[ansKey].TargetValue,
                                    'Usage': this.$store.state.bgp.answers[ansKey].Usage,
                                })
                            }
                        }
    
                    }
    
                    //console.log(`store action points are ${JSON.stringify(this.$store.state.bgp.actionPoints, undefined, 2)}`)
                    this.$store.state.bgp.actionPoints.forEach((actionPoint)=>{
                        // tempID is used when controlling which date dialog to show in the table. it can be removed
                        actionPoint = _.omit(actionPoint, ['TempID'])
    
                        //console.log(`actionpoint id == null is ${actionPoint.ID == null} length >0 = ${actionPoint.What.length>0}`)
                        //console.log(`action point pre id is ${JSON.stringify(actionPoint, undefined, 2)}`)
                        if(actionPoint.What.length>0 && actionPoint.UUID == null){
                            //console.log(`adding action point`)
                            actionPoint.UUID = this.generateID()
                            actionPointsToAdd.push(actionPoint)
                        } else if (actionPoint.What.length>0 && actionPoint.UUID != null){
                            //console.log('editing action point')
                            actionPointsToEdit.push(actionPoint)
                        }
                        //console.log(`action point post id is ${JSON.stringify(actionPoint, undefined, 2)}`)
                    })
    
                    this.$store.state.bgp.evaluationPoints.forEach((evaluationPoint)=>{
                        if(evaluationPoint.What.length>0 && evaluationPoint.UUID == null){
                            evaluationPoint.UUID = this.generateID()
                            evaluationPointsToAdd.push(evaluationPoint)
                        } else if (evaluationPoint.What.length>0 && evaluationPoint.UUID != null){
                            evaluationPointsToEdit.push(evaluationPoint)
                        }
                    })
    
                    // requests are wrapped in individual try / catch in case one fails when offline
                    let sentServerRequest = false
                    let serverErrors = []
    
                    try {
                        if(actionPointsToAdd.length>0){
                            await axios.post('/createObject/actionPointsModel', actionPointsToAdd, headers)
                            sentServerRequest = true
                            this.refreshActionPoints = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Add Action Points')
                    }
                    try {
                        if(actionPointsToEdit.length>0){
                            await axios.post('/editAllInObject/actionPointsModel', actionPointsToEdit, headers)
                            sentServerRequest = true
                            this.refreshActionPoints = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Edit Action Points')
                    }
                    try {
                        if(evaluationPointsToAdd.length>0){
                            await axios.post('/createObject/evaluationPointsModel', evaluationPointsToAdd, headers)
                            sentServerRequest = true
                            this.refreshEvaluationPoints = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Add Evaluation Points')
                    }
                    try {
                        if(evaluationPointsToEdit.length>0){
                            await axios.post('/editAllInObject/evaluationPointsModel', evaluationPointsToEdit, headers)
                            sentServerRequest = true
                            this.refreshEvaluationPoints = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Edit Evaluation Points')
                    }
                    try {
                        if(questionsToAdd.length>0){
                            await axios.post('/createObject/answerModel', questionsToAdd, headers)
                            sentServerRequest = true
                            this.refreshQuestions = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Adding Answer')
                    }
                    try {
                        if(questionsToEdit.length>0){
                            await axios.post('/editAllInObject/answerModel', questionsToEdit, headers)
                            sentServerRequest = true
                            this.refreshQuestions = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Editing Answer')
                    }
                    try {
                        if(cowAssessmentToAdd.length>0){
                            await axios.post('/createObject/assessmentModel', cowAssessmentToAdd, headers)
                            sentServerRequest = true
                            this.refreshCowAssesment = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Add Assessment')
                    }
                    try {
                        if(cowAssessmentEdit.length>0){
                            await axios.post('/editAllInObject/assessmentModel', cowAssessmentEdit, headers)
                            sentServerRequest = true
                            this.refreshCowAssesment = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Edit Assessment')
                    }
                    try {
                        if(abnormalToAdd.length>0){
                            await axios.post('/createObject/abnormalModel', abnormalToAdd, headers)
                            sentServerRequest = true
                            this.refreshAbnormal = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Add Abnormal')
                    }
                    try {
                        if(abnormalToEdit.length>0){
                            await axios.post('/editAllInObject/abnormalModel', abnormalToEdit, headers)
                            sentServerRequest = true
                            this.refreshAbnormal = true
                        }
                    } catch (e){
                        //console.log(e)
                        serverErrors.push('Edit Abnormal')
                    }
                         
                    if (sentServerRequest){
                        this.$store.state.snackBarText = `${this.$store.state.translations[this.$store.state.lang].saved}`
                        this.$store.state.snackBarColour = 'green'
                        this.$store.state.showSnackBar = true
                    }
    
                    if (serverErrors.length>0){
                        if(this.offline){
                            this.$store.state.snackBarText = `${this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection}`
                            this.$store.state.snackBarColour = 'error'
                            this.$store.state.showSnackBar = true
                        } else {
                            let text = ''
                            serverErrors.forEach(error => text+=` ${error} `)
                            this.$store.state.snackBarText = `Server errors: ${text} - Please try again.`
                            this.$store.state.snackBarColour = 'error'
                            this.$store.state.showSnackBar = true
                        }
                    }
    
                    this.refreshFromServer()
                    
                } catch (e) {
                    if (this.offline){
                        console.log(e)
                        this.$store.state.snackBarText = `${this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection}`
                        this.$store.state.snackBarColour = 'error'
                        this.$store.state.showSnackBar = true
                    } else {
                        console.log(e)
                        this.$store.state.snackBarText = `Fout Bij!`
                        this.$store.state.snackBarColour = 'error'
                        this.$store.state.showSnackBar = true
                    }
                    // save was unsuccessful
                    this.$store.state.savingAll = false
                    console.log('returning false')
                    return false
                }

                this.$store.state.savingAll = false
                // save was successful
                console.log('returning true')
                return true
            }
        },
        refreshFromServer: async function(){
            try {
                let res = await axios.get('/testOnline')
                //console.log(`online is ${JSON.stringify(res, undefined, 2)}`)
                this.offline = false
            } catch (e) {
                //console.log(e)
                this.offline = true
            }
            try {
                let headers = await this.$store.state.zlogin.getHeaders()

                if(this.refreshQuestions){
                    let answers = await axios.get(`/getObject/answerModel/BGPID/${this.$store.state.bgp.UUID}`, headers)
    
                    answers.forEach((answer)=>{
                        _.setWith(this.$store.state.bgp.answers, answer.FormQuestionAppID.toString(), answer)
                    })  
                }
                
                if(this.refreshCowAssesment){
                    let assessment = await axios.get(`/getObject/assessmentModel/BGPID/${this.$store.state.bgp.UUID}`, headers)
                    this.$store.state.bgp.answers.cowAssessment = assessment
                }

                if(this.refreshAbnormal){
                    let abnormal = await axios.get(`/getObject/abnormalModel/BGPID/${this.$store.state.bgp.UUID}`, headers)
                    this.$store.state.bgp.answers.abnormal = abnormal
                }
                
                if(this.refreshActionPoints){
                    let actionPoints = await axios.get(`/getObject/actionPointsModel/BGPID/${this.$store.state.bgp.UUID}`, headers)
                    ////console.log(`actionPoints are ${JSON.stringify(actionPoints, undefined, 2)}`)
                    this.$store.state.bgp.actionPoints = actionPoints
                }

                if(this.refreshEvaluationPoints){
                    let evaluationPoints = await axios.get(`/getObject/evaluationPointsModel/BGPID/${this.$store.state.bgp.UUID}`, headers)
                    ////console.log(`evaluationPoints are ${JSON.stringify(evaluationPoints, undefined, 2)}`)
                    this.$store.state.bgp.evaluationPoints = evaluationPoints
                }
                
            } catch (e) {
                if (await this.offline){
                    //console.log(e)
                    this.$store.state.snackBarText = `${this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection}`
                    this.$store.snackBarColour = 'error'
                    this.$store.state.showSnackBar = true
                } else {
                    //console.log(e)
                    this.$store.state.snackBarText = `Fout Bij!`
                    this.$store.snackBarColour = 'error'
                    this.$store.state.showSnackBar = true
                }
            }

        }
    }
}