
import {router, store} from './../main.js'
import axios from 'axios'
import consts from './../helpers/Consts.js'
import jwt_decode from "jwt-decode";
const qs = require('qs')
const moment = require('moment')
// secure ls used for encrypting data on local storage
const secureLS = require('secure-ls')
const crypto = require('crypto')

class ZLogin {
    authParameters = {
        responseType: 'code',
        scope: ['openid', 'profile', 'email'],
        clientID: 'geborgdedierenarts',
        clientSecret: '',
        redirectURI: `${consts[consts.env].appURL}/zlogincallback`,
    }

    tenants = []

    ls = new secureLS()

    constructor (){
      
    }

    loginToZLogin = async function(authCode, state){
        //console.log(`getting tokens for auth code ${authCode} and state ${state}`)
        
        try {
          store.state.gettingAuthTokens = true

          let verifier = await axios.get(`${consts[consts.env].apiURL}/auth/getVerifierByState/${state}`)

          //console.log(`verifier, challenge and state from db is ${JSON.stringify(verifier, undefined, 2)}`)

          let form = {
            'grant_type': 'authorization_code',
            'code': authCode,
            'redirect_uri': this.authParameters.redirectURI,
            'code_verifier': verifier.Verifier,
            'client_id': this.authParameters.clientID,
            'client_secret': '',
            'scope': 'openid geborgdedierenarts'
          }

          let config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }

          let result = await axios.post(
            consts[consts.env].tokenEndpoint,
            qs.stringify(form),
            config
          )
          
          //console.log(`result is ${JSON.stringify(result, undefined, 2)}`)
          store.state.firstLogin = false
          this.setSession(result)

          if (typeof(store.state.user.UDN)=='undefined'){
            store.state.udnError = true
            return Promise.reject()
          } else {
            store.state.udnError = false
          }

          store.state.gettingAuthTokens = false
          return Promise.resolve()
        } catch(e) {
          console.log(e)
          return Promise.reject()
        }
    }

    logout () {
      try {
        let logoutURL = consts[consts.env].logoutURL + `?id_token_hint=${this.ls.get('id_token')}&post_logout_redirect_uri=https%3A%2F%2Fbgp.geborgdedierenarts.nl/zlogincallback`
        this.ls.removeAll()
        location.href = logoutURL
      } catch (e) {
        console.log(e)
      }
    }

    // set tokens based on result of call to token endpoint
    setSession = async function(tokens) {
      try {   
        //console.log(`setting session tokens = ${JSON.stringify(tokens.access_token, undefined, 2)}`)
 
          // Set the time that the Access Token will expire at. Subtracting 30s to accomodate any mismatch
          // between expiry here and expiry encoded in JWT
        let expiresAt = ((tokens.expires_in - 30) * 1000) + moment().valueOf()
        this.ls.set('expires_at', expiresAt)
        //console.log(`expires at in set session is ${expiresAt}`) 

        this.ls.set('access_token', tokens.access_token)
        this.ls.set('id_token', tokens.id_token)

        try {
          let decodedIDToken = jwt_decode(tokens.id_token)
        
            store.state.user.UDN = decodedIDToken.zlogin.udn,
            store.state.user.Email = decodedIDToken.zlogin.email,
            store.state.user.Username = decodedIDToken.preferred_username,
            store.state.user.given_name = decodedIDToken.given_name,
            store.state.user.family_name = decodedIDToken.family_name
          
          //console.log(`user is ${JSON.stringify(store.state.user, undefined, 2)}`)
        } catch (e) {
          console.log(e)
        }

        // if there is a refresh token in the result - there is no refresh token when call the token
        // endpoint with a refresh token
        if(tokens.refresh_token){
        //localStorage.setItem('refresh_token', authResult.refresh_token)
            this.ls.set('refresh_token', tokens.refresh_token)
            let refreshExpires = ((tokens.refresh_expires_in - 30) * 1000) + moment().valueOf()
            this.ls.set('refresh_expires_at', refreshExpires)
        }
     
        await this.isLoggedIn()
        return Promise.resolve()

      } catch (e) {
        console.log(e)
        return Promise.reject(e)
      }

    }

    isLoggedIn = async function () {
      try {
        // Check whether the current time is past the
        // Access Token's expiry time
        let expiresAt = moment(this.ls.get('expires_at')).valueOf()
        //console.log(`expiresAt is ${expiresAt} now is ${new Date().getTime()}`)
    
        // check if the tokens are in local storage
        let refreshTokenPresent = this.ls.get('refresh_token').length > 0
        let authTokenPresent = this.ls.get('access_token').length > 0
        let expiresAtPresent = expiresAt > 0
        let refreshExpiresAt = moment(this.ls.get('refresh_expires_at')).valueOf()

        //console.log(`refresh expires at ${JSON.stringify(refreshExpiresAt, undefined, 2)}`)
    
        //console.log(`${this.ls.get('access_token')}`)
        //console.log(`${refreshTokenPresent} ${authTokenPresent} ${expiresAtPresent}`)
        // if the tokens are not present then the user has logged out / new user / cleared cache
        if(!(refreshTokenPresent & authTokenPresent & expiresAtPresent)){
          //console.log('necessary tokens are not present not logged in')
          store.state.isLoggedIn = false
          return Promise.reject(false)
        }
        // if the token has expired get a new token by calling refresh endpoint

        if (moment().valueOf() > expiresAt){
          store.state.gettingAuthTokens = true
          //console.log(`tokens have expired get new tokens`)

          // if refresh token is valid
          if (moment().valueOf() < refreshExpiresAt){
            //console.log('refresh is valid')
            let form = qs.stringify({
              'grant_type':'refresh_token',
              'client_id': this.authParameters.clientID,
              'refresh_token': this.ls.get('refresh_token')
            })

            try {
              let newTokens = await axios.post(consts[consts.env].tokenEndpoint, form,
                  {'headers': {'Content-Type':'application/x-www-form-urlencoded'}},
                )

                //console.log(`new tokens are ${JSON.stringify(newTokens, undefined, 2)}`)

                await this.setSession(newTokens)

                return Promise.resolve(true)
            
            } catch (e) {
              console.log(e)
              return Promise.resolve(false)
            }
          } else {
            //console.log('refresh is invalid, redirecting to zlogin url')
            let url = await this.getLoginURL()
            location.href = url
          }

          store.state.gettingAuthTokens = false
              
        } else {
          // our token has not expired. set logged in to true.
          //console.log('token is ok')

          try {
            let decodedIDToken = jwt_decode(this.ls.get('id_token'))

            store.state.user.UDN = decodedIDToken.zlogin.udn,
            store.state.user.Email = decodedIDToken.zlogin.email,
            store.state.user.Username = decodedIDToken.preferred_username,
            store.state.user.given_name = decodedIDToken.given_name,
            store.state.user.family_name = decodedIDToken.family_name

            //console.log(`user is ${JSON.stringify(store.state.user, undefined, 2)}`)
          } catch (e) {
            console.log(e)
          }

          if (typeof(store.state.user.UDN)=='undefined'){
            store.state.udnError = true
            return Promise.reject()
          } else {
            store.state.udnError = false
          }

          store.state.gettingAuthTokens = false
          store.state.isLoggedIn = true
          return Promise.resolve(true)
        }
       } catch (e) {
         console.log(e)
         store.state.gettingAuthTokens = false
         return Promise.reject(false)
       }
      }

      // getHeaders = async function(){
      //   let headers = {
      //     'headers': {'Content-Type':'application/json',
      //         'idtoken': consts.idToken,
      //         'accesstoken': consts.accessToken,
      //       }
      //   }
      //   try {
      //     let online = await axios.get(consts.apiURL + '/testOnline')
      //   } catch (e) {
      //     console.log(e)
      //     headers.headers.offline = true
      //   }
      //   //console.log(`headers are ${JSON.stringify(headers, undefined, 2)}`)
      //   // dummy set session to replicate login flow
      //   try {
      //     this.setSession(
      //        {
      //         'id_token': consts.idToken,
      //         'access_token': consts.accessToken,
      //         'expires_in': 12345
      //       }
      //     )
      //   } catch (e) {
      //     console.log(e)
      //   }
      //   return headers
      // }

      getHeaders = async function(){
        if(consts.env == 'dev'){
          try {
            this.setSession(
                {
                'id_token': consts.idToken,
                'access_token': consts.accessToken,
                'expires_in': 12345
              }
            )
          } catch (e) {
            console.log(e)
          }
          return {   
            'headers': {'Content-Type':'application/json',
                'idtoken': consts.idToken,
                'accesstoken': consts.accessToken,
              }
          }
        } else {
          try {
            if(await this.isLoggedIn()){
              let headers = {
                'headers': {'Content-Type':'application/json',
                  'idtoken': this.ls.get('id_token'),
                  'accesstoken': this.ls.get('access_token'),
                }
              }
              //console.log(`returning headers ${JSON.stringify(headers, undefined, 2)}`)
              return headers
            } else {
              throw ("Not logged in!")
            }
          } catch (e) {
            try {
              let online = await axios.get(consts[consts.env].apiURL + '/testOnline')
              //console.log(`online is ${JSON.stringify(online, undefined, 2)}`)
              router.to('/login')
            } catch (e) {
              //console.log(`no network let through **** check here for presence of token! ***** ${e}`)
              return {
                'headers': {'Content-Type':'application/json',
                  'idtoken': this.ls.get('id_token'),
                  'accesstoken': this.ls.get('access_token'),
                  'offline': true
                }
              }
            }
          } 
        }
      }

      tokensPresent = async function(){
        let refreshTokenPresent = this.ls.get('refresh_token').length > 0
        let authTokenPresent = this.ls.get('access_token').length > 0
        let idTokenPresent = this.ls.get('id_token').length > 0
        return refreshTokenPresent && authTokenPresent && idTokenPresent
      }

      

      getLoginURL = async function(){

        try {
          let verifierAndChallenge = await axios.get(`${consts[consts.env].apiURL}/auth/verifierAndChallenge`)

          //console.log(`original string, verifier and challenge is ${JSON.stringify(verifierAndChallenge, undefined, 2)}`)
  
          store.state.verifier = verifierAndChallenge.verifier
          store.state.challenge = verifierAndChallenge.challenge
  
          let url = `${consts[consts.env].authTokenEndpoint}?state=${verifierAndChallenge.state}&response_type=${this.authParameters.responseType}&client_id=${this.authParameters.clientID}&redirect_uri=${this.authParameters.redirectURI}&scope=openid%20geborgdedierenarts&code_challenge=${store.state.challenge}&code_challenge_method=S256` 
          return url
        } catch (e) {
          console.log(e)
          return 'https://bgp.geborgdedierenarts.nl'
        }
        
      }

}

export default ZLogin