<template>
    <span>
        <v-col cols="12" class="pa-0 ma-0">
            <v-row justify="center" class="pa-0 ma-0">
                <subSections 
                    v-if="Object.keys(sections).length==1"
                    :subSections="sections[Object.keys(sections)[0]].subSections"
                />
                <v-expansion-panels v-else accordion class="pa-0 ma-0">
                    <v-expansion-panel v-for="section in sections" :key="section.sectionDetails.ID">
                        <v-expansion-panel-header>
                            <h4> {{section.sectionDetails[`${$store.state.lang}Desc`]}} </h4>
                        </v-expansion-panel-header>     
                        <v-expansion-panel-content>
                            <span v-if="section.sectionDetails.SectionAppID==3">
                                <h5>{{$store.state.translations[$store.state.lang].healthStatusFoundAtPart1}}
                                <a :href="getURLOfLink()" target='_blank'>
                                    {{$store.state.translations[$store.state.lang].here}}
                                </a>
                                {{$store.state.translations[$store.state.lang].healthStatusFoundAtPart2}}
                                </h5>
                                <br>
                            </span>

                            <subSections :subSections="section.subSections"/>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </v-col>
    </span>
</template>

<script>
import subSections from '@/components/SubSection.vue'

export default {
    props: ['sections'],
    components: {subSections},    
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {
        getURLOfLink: function(){
            let originalUBN = this.$store.state.bgp.details.UBN
            //console.log(`original ubn is ${originalUBN}`)
            let parsedUBN = parseInt(originalUBN).toString()
            //console.log(`parsedUBN is ${parsedUBN}`)
            return `https://www.veeonline.nl/home/veehouderhome?StartVeeOnlineMetUbn=${parsedUBN}`
        }
    },
    created: function(){
        ////console.log(`sections is ${JSON.stringify(this.sections, undefined, 2)}`)
    }
}
</script>