<template>
    <v-container>
        <v-row justify="center" v-if="loading">
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
        </v-row>
        
        <span v-else>
            <v-col :key="uiKey">
 
                <p class="smallQuestionText"> {{$store.state.questions['5'].sections['14'].subSections['29'].questions[0][`${$store.state.lang}Desc`]}} 
                </p>
              
                <v-textarea
                    v-model="$store.state.bgp.answers['183'].Value"
                    :id="'183'"
                    required
                    rows=3
                    auto-grow
                    @change="$store.state.madeBGPChanges = true"
                ></v-textarea>
                
                <v-divider/>

                <h3> {{$store.state.questions['5'].sections['15'].sectionDetails[`${$store.state.lang}Desc`]}} </h3>
            
                <v-data-table
                     :headers="evaluationPointsHeader"
                     :items="$store.state.bgp.evaluationPoints"
                     :hide-default-footer="true"
                >

                    <template v-slot:item.What="{item}">
                        <v-textarea
                            v-model="item.What"
                            :label="$store.state.translations[$store.state.lang].what"
                            single-line
                            @change="$store.state.madeBGPChanges = true; "
                        ></v-textarea>
                    </template>

                     <template v-slot:item.Result="{item}">
                        <v-textarea
                            v-model="item.Result"
                            :label="$store.state.translations[$store.state.lang].result"
                            single-line
                            @change="$store.state.madeBGPChanges = true;"
                        ></v-textarea>
                    </template>

                       <template v-slot:item.Evaluation="{item}">
                        <v-textarea
                            v-model="item.Evaluation"
                            :label="$store.state.translations[$store.state.lang].explanationEvaluation"
                            :rows="5"
                            @change="$store.state.madeBGPChanges = true;"
                        ></v-textarea>
                    </template>

                    <template v-slot:item.Realised="{item}">
                        <span>
                            <p class="smallQuestionText"> {{$store.state.translations[$store.state.lang].realised}} 
                            </p>
                            <v-radio-group v-model="item.Realised">
                                <v-radio
                                    color="primary"
                                    v-for="option in realisedOptions"
                                    :key="option.EnDesc"
                                    :label="`${option[$store.state.lang+'Desc']}`"
                                    :value="option.NlDesc"
                                    @change="$store.state.madeBGPChanges = true;"
                                ></v-radio>
                            </v-radio-group>
                        </span>
                    </template>

                </v-data-table>

                <v-btn @click="addEvaluationPoint()" fab color="primary">
                    <v-icon>add</v-icon>
                </v-btn>


                <br><br><br>
                <v-divider/>

                <h3> {{$store.state.questions['5'].sections['16'].sectionDetails[`${$store.state.lang}Desc`]}} </h3>
                
                <v-data-table
                     :headers="actionPointsHeader"
                     :items="$store.state.bgp.actionPoints"
                     :hide-default-footer="true"
                >
                
                        <template v-slot:item.What="{item}">
                            <v-textarea
                                v-model="item.What"
                                :label="$store.state.translations[$store.state.lang].what"
                                single-line
                                @change="$store.state.madeBGPChanges = true;"
                            ></v-textarea>
                        </template>

                        <template v-slot:item.Who="{item}">
                            <v-textarea
                                v-model="item.Who"
                                :label="$store.state.translations[$store.state.lang].who"
                                single-line
                                @change="$store.state.madeBGPChanges = true"
                            ></v-textarea>
                        </template>

                        <template v-slot:item.When="{item}">
                            <v-dialog
                                :ref="`ref${item.TempID != null ? item.TempID : item.UUID}`"
                                v-model="dateModal[item.TempID != null ? item.TempID : item.UUID]"
                                :return-value.sync="item.When"
                                :key="item.TempID != null ? item.TempID : item.UUID"
                                width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="item.When"
                                    :label="$store.state.translations[$store.state.lang].date"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    :key="item.TempID != null ? item.TempID : item.UUID"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    :key="item.TempID != null ? item.TempID : item.UUID" 
                                    v-model="item.When" scrollable
                                    @change="$store.state.madeBGPChanges = true"    
                                >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dateModal[item.TempID != null ? item.TempID : item.UUID]=false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs[`ref${item.TempID != null ? item.TempID : item.UUID}`].save(item.When)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>

                        </template>
                    
                        <template v-slot:item.Result="{item}">
                            <v-textarea
                                v-model="item.Result"
                                :label="$store.state.translations[$store.state.lang].result"
                                single-line
                                @change="$store.state.madeBGPChanges = true"
                            ></v-textarea>
                        </template>

                        <template v-slot:item.bppRelated="{item}">
                            <v-switch 
                                v-model="item.bppRelated"
                                required
                                @change="$store.state.madeBGPChanges = true;"
                                :label="item.bppRelated ? (item.bppRelated == 1 ? `${$store.state.translations[$store.state.lang].yes}` : `${$store.state.translations[$store.state.lang].no}`) : `${$store.state.translations[$store.state.lang].no}`"
                            ></v-switch>
                        </template>
                   
                
                </v-data-table>

                <v-btn @click="addItem()" fab color="primary">
                    <v-icon>add</v-icon>
                </v-btn>

            
            </v-col>
        </span>

    </v-container>
</template>

<script>
const axios = require('axios')
import serverMixins from './../helpers/ServerOperations'

export default {
    mixins: [serverMixins],
    
    data () {
        return {
            dateModal: {
            },
            loading: false,
            realisedOptions: [
                {
                    'EnDesc': 'fully realised',
                    'NlDesc': 'volledig gerealiseerd'
                },
                {
                    'EnDesc': 'partially realised',
                    'NlDesc': 'gedeeltelijk gerealiseerd'
                },
                {
                    'EnDesc': 'not realised',
                    'NlDesc': 'niet gerealiseerd '
                },
            ],
            actionPointsHeader: [
                {
                    text: this.$store.state.translations[this.$store.state.lang].what ,
                    value: 'What'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].who ,
                    value: 'Who'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].when ,
                    value: 'When'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].result ,
                    value: 'Result'
                },
                 {
                    text: this.$store.state.translations[this.$store.state.lang].bppRelated ,
                    value: 'bppRelated'
                },
            ],
            evaluationPointsHeader: [
                {
                    text: this.$store.state.translations[this.$store.state.lang].what ,
                    value: 'What'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].result ,
                    value: 'Result'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].realised ,
                    value: 'Realised'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].explanationEvaluation,
                    value: 'Evaluation'
                },
            ],
            uiKey: Math.random(),
        }
    },
    computed: {

    },
    methods: {
       addItem: function(){
           //console.log('adding item')
            this.$store.state.bgp.actionPoints.push({
                'ID': null,
                'BGPID': this.$store.state.bgp.UUID,
                'What': '',
                'Who': '',
                'When': '',
                'Result': '',
                'bppRelated': false,
                'TempID': parseInt(Math.random()*100000)
            })
            this.uiKey = Math.random()
            // add date modal

       },
       addEvaluationPoint: function(){
            this.$store.state.bgp.evaluationPoints.push({
                'ID': null,
                'BGPID': this.$store.state.bgp.UUID,
                'What': '',
                'Result': '',
                'Realised': '',
                'Evaluation': '',
            })
            this.uiKey = Math.random()
       }
    },
    created: async function(){

        try {
            this.loading = true
            let headers = await this.$store.state.zlogin.getHeaders()

            let actionPoints = await axios.get(`/getObject/actionPointsModel/BGPID/${this.$store.state.bgp.UUID}`, headers)
            //console.log(`actionPoints are ${JSON.stringify(actionPoints, undefined, 2)}`)
            this.$store.state.bgp.actionPoints = actionPoints

            console.log(`store action points are ${JSON.stringify(this.$store.state.bgp.actionPoints, undefined, 2)}`)

            // for each action point initilise the date modal dialog to false
            this.$store.state.bgp.actionPoints.forEach((actionPoint)=>{
                this.dateModal[actionPoint.UUID] = false
            })

            console.log(`store action points are ${JSON.stringify(this.$store.state.bgp.actionPoints, undefined, 2)}`)

            //this.addItem()

            let evaluationPoints = await axios.get(`/getObject/evaluationPointsModel/BGPID/${this.$store.state.bgp.UUID}`, headers)
            //console.log(`evaluationPoints are ${JSON.stringify(evaluationPoints, undefined, 2)}`)
            this.$store.state.bgp.evaluationPoints = evaluationPoints
            //this.addEvaluationPoint()

            this.loading = false
        } catch (e) {
            //console.log(e)
            this.loading = false
        }



        if(! this.$store.state.bgp.answers['183']){
            this.$store.state.bgp.answers['183'] = {
                'Value': '',
                'PrevBGPValue': '',
                'TargetValue': ''
            }
        } 

    }
}
</script>

<style scoped>
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td{
    font-size: .875rem;
    height: 150px;
}
</style>