<template>
    <v-container>
        <v-progress-circular v-if="loading" size="150px" color="primary"/>
        <v-col cols="12" v-else>
            <v-data-table
                :headers="userHeaders"
                :items="getTableData()"
                calculate-widths
                mobile-breakpoint="0"
                v-bind:key="randomTableKey"
            >
                <template v-slot:top>
                    <v-toolbar
                        flat
                    >
                        <v-toolbar-title>{{$store.state.translations[$store.state.lang].userManagement}}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="dialog"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{$store.state.translations[$store.state.lang].addNewUser}}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-form ref="form" v-bind:key="uiKey">
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                >
                                                    <v-text-field
                                                        v-model="editedItem.Email"
                                                        :label="$store.state.translations[$store.state.lang].email"
                                                        :rules="[rules.required, rules.email]"
                                                        @blur="checkEmailIsUnique()"
                                                        :error-messages="emailErrorMessage"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                >
                                                    <v-text-field
                                                        v-model="editedItem.Password"
                                                        :label="$store.state.translations[$store.state.lang].tempPassword"
                                                        :rules="[]"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                >
                                                    <v-text-field
                                                        v-model="editedItem.Forename"
                                                        :label="$store.state.translations[$store.state.lang].forename"
                                                        :rules="[rules.required]"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                >
                                                    <v-text-field
                                                        v-model="editedItem.Surname"
                                                        :label="$store.state.translations[$store.state.lang].surname"
                                                        :rules="[rules.required]"
                                                    ></v-text-field>
                                                </v-col>
                                                    <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                                >
                                                    <v-checkbox
                                                        v-model="editedItem.Admin"
                                                        :label="$store.state.translations[$store.state.lang].canAddAndEditUsers"
                                                    ></v-checkbox>
                                                </v-col>
                                            
                                            
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="close"
                                    >
                                        {{$store.state.translations[$store.state.lang].cancel}}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="save"
                                    >
                                        {{$store.state.translations[$store.state.lang].save}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">{{$store.state.translations[$store.state.lang].areYouSure}}</v-card-title>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDelete">{{$store.state.translations[$store.state.lang].cancel}}</v-btn>
                                <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-col cols="2">
                            <v-row justify="center" align="center">
                                <v-btn
                                    @click="getUsers()"
                                    color="primary"
                                    icon
                                >
                                    <v-icon color="primary">refresh</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-toolbar>
                </template>


              <template v-slot:item.Password="{ item }">
                    <v-row>
                        <!-- <v-textfield
                            type="password"
                            v-model="item.password"
                        >
                        </v-textfield> -->
                        <v-btn @click="editedItem = Object.assign({}, item); dialog=true" text color="primary">
                            {{$store.state.translations[$store.state.lang].reset}}
                        </v-btn>
                    </v-row>
                </template>
                <template v-slot:item.Admin="{ item }">
                    {{item.Admin ? 'Ja' : 'Nee'}}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-btn @click="editedItem = Object.assign({}, item); dialog=true" icon>
                            <v-icon color="primary">edit</v-icon>
                        </v-btn>
                        <v-btn @click="itemToDelete = item; dialogDelete=true" icon :disabled="item.ID == $store.state.reportUser.ID">
                            <v-icon color="primary">delete</v-icon>
                        </v-btn>
                    </v-row>
                </template>
            </v-data-table>
        </v-col>

    </v-container>
</template>

<script>
const axios = require('axios')
const _ = require('lodash')
import consts from './../helpers/Consts'

export default {
    
    data () {
        return {
            loading: false,
            randomTableKey: 1,
            uiKey: 2,
            users: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            itemToDelete: {},
            editedItem: {
                ID: -1,
                surname: '',
                forename: '',
                email: '',
                password: '',
                admin: 0,
            },
            userHeaders: [
                { text: this.$store.state.translations[this.$store.state.lang].surname,
                  value: 'Surname'
                },
                { text: this.$store.state.translations[this.$store.state.lang].forename,
                  value: 'Forename'
                },
                { text: this.$store.state.translations[this.$store.state.lang].email,
                  value: 'Email'
                },
                { text: this.$store.state.translations[this.$store.state.lang].password,
                  value: 'Password'
                },
                { text: this.$store.state.translations[this.$store.state.lang].admin,
                  value: 'Admin'
                },
                { text: this.$store.state.translations[this.$store.state.lang].actions,
                  value: 'actions'
                },
            ],
            emailErrorMessage: '',
            rules: {
                required: value => !!value || 'Verplicht',
                counter: value => value.length <= 20 || 'Maximaal 20 karakters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Ongeldig e-mail'
                },
                password: value => !!value || 'Verplicht',
                validPassword: (value) => {
                    return true
                },
            }
        }
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? this.$store.state.translations[this.$store.state.lang].addNewUser : this.$store.state.translations[this.$store.state.lang].editUser
        },
    },
    methods: {
        checkEmailIsUnique: async function(){
            try {
                if(this.editedItem.Email){
                    let email = await axios.post(consts[consts.env].apiURL + '/checkEmailUniqueReportUser',{
                        email: this.editedItem.Email
                    }, {
                                headers: {
                                     email: this.$store.state.reportUser.Email,
                                    password: this.$store.state.reportUser.Password
                                }
                            })
                    //console.log(`email is ${email}`)
                    this.emailErrorMessage = null
                }
            } catch (e) {
                console.log(e)
                this.emailErrorMessage = 'email bestaat'
            }  
        },
        refreshTableKey: function(){
            this.randomTableKey = Math.random()
        },
        resetEditedItem: function(){
            this.editedItem = {
                ID: -1,
                Surname: '',
                Forename: '',
                Email: '',
                Password: '',
                Admin: 0,
            }
        },
        resetPassword: function(){
            //console.log('resetting password')
        },
        save: async function(){
            try {
                let validForm = false;

                try {
                    validForm =this.$refs.form.validate()
                    this.uiKey = Math.random()
                } catch (e) {
                    console.log(e)
                    validForm = false
                }

                //console.log(`valid form is ${validForm}`)

                if(validForm){
                    //console.log(`item is ${JSON.stringify(this.editedItem, undefined, 2)}`)
                    if(this.editedItem.ID > 0){
                        await axios.post(consts[consts.env].apiURL + '/editAllInObjectReportUser/reportUserModel', [this.editedItem], {
                                headers: {
                                     email: this.$store.state.reportUser.Email,
                                    password: this.$store.state.reportUser.Password
                                }
                            })
                        // replace local version
                        for(let i=0; i<this.users.length; i++){
                            if(this.users[i].ID == this.editedItem.ID){
                                this.users[i] = this.editedItem
                                break
                            }
                        }
                        //console.log(`new users is ${JSON.stringify(this.users, undefined, 2)}`)
                        this.resetEditedItem()
                        this.refreshTableKey()
                    } else {
                        //console.log('saving')
                        let newUser = await axios.post(consts[consts.env].apiURL + '/createObjectReportUser/reportUserModel',
                            _.omit(this.editedItem, ['ID']), {
                                headers: {
                                     email: this.$store.state.reportUser.Email,
                                    password: this.$store.state.reportUser.Password
                                }
                            }
                        )
                        //console.log(`new user is ${JSON.stringify(newUser, undefined, 2)}`)
                        this.users.push(newUser)
                        this.resetEditedItem()
                    }
                    this.dialog = false
                }
            } catch (e) {
                console.log(e)
                this.dialog = false
            }            
        },
        close: function(){
            this.dialog = false
            this.resetEditedItem()
        },
        closeDelete: function(){
            this.dialogDelete = false
        },
        deleteItemConfirm: async function(){
            //console.log('deleting')
            try {
                await axios.delete(consts[consts.env].apiURL + `/deleteInModelReportUser/reportUserModel/${this.itemToDelete.ID}`, {
                                headers: {
                                     email: this.$store.state.reportUser.Email,
                                    password: this.$store.state.reportUser.Password
                                }
                            })
                this.users = _.remove(this.users, (user)=>{
                    return user.ID != this.itemToDelete.ID
                })
                //console.log(`deleted users is ${JSON.stringify(this.users, undefined, 2)}`)
                this.itemToDelete = {}
                this.refreshTableKey()
            } catch (e) {
                console.log(e)
            }
            this.dialogDelete = false
        },
        getTableData: function(){
            let res = []

            this.users.forEach((user)=>{
                res.push({
                    ID:user.ID,
                    Surname: user.Surname,
                    Forename: user.Forename,
                    Email: user.Email,
                    Password: user.Password ? user.Password : '',
                    Admin: user.Admin 
                })
            })

            //console.log(`table res is ${JSON.stringify(res, undefined, 2)}`)
            return res
        },
        getUsers: async function(){
            try {
                this.loading = true

                this.users = await axios.get(consts[consts.env].apiURL + '/getAllInModelReportUser/reportUserModel', {
                                headers: {
                                     email: this.$store.state.reportUser.Email,
                                    password: this.$store.state.reportUser.Password
                                }
                            })

                //console.log(`users are ${JSON.stringify(this.users, undefined, 2)}`)

                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        }

    },
    created: async function(){
        this.getUsers()
    }
}
</script>