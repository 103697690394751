<template>
    <v-container>
 
        <v-col>
          <v-row>
            <h5> Selectie vindt risico gestuurd plaats. Dat houdt in dat dieren die tijdens de koppel inspectie zijn opgevallen sowieso in de steekproef zitten. Indien er geen dieren zijn opgevallen worden er aselect dieren gekozen. Er worden minimaal 5 dieren onderzocht. </h5>
          </v-row>
          <v-row justify="center">
             <!-- <h3> Cow Assessment </h3> -->
            <span v-for="item in this.$store.state.bgp.answers.abnormal"
              :key="item.AnimalID"
            >
              <v-chip 
                color="primary"
                pill
                v-if="item.AnimalID.length>0"
                class="pa-2"
              >
                {{item.AnimalID}}
              </v-chip>
            </span>
          </v-row>
          <v-row justify="center">
             <v-data-table
                :headers="headers"
                :items="this.$store.state.bgp.answers.abnormal"
                :mobile-breakpoint="2000"
                class="tallRows"
              >

               <template v-slot:item.AnimalID="{item}">
                 <v-row>
                      <v-tooltip
                            bottom
                       >
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="pr-5">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        Diernummer is het vier-cijferige werknummer
                    </v-tooltip>
                      <v-text-field
                        v-model="item.AnimalID"
                        :label="$store.state.lang == 'En' ? 'Animal ID' : 'Diernummer'"
                        single-line
                        @change="$store.state.madeBGPChanges = true;"
                      ></v-text-field>
                 </v-row>
                </template>

                 <template v-slot:item.GeneralImpression="{item}">
                   <v-row justify="end">
                    <v-col cols="8">
                      <v-select
                        v-model="item.GeneralImpression"
                        :items="generalImpressionDropdownValues[$store.state.lang]"
                        :disabled="item.AnimalID.length==0"
                        @change="$store.state.madeBGPChanges = true"
                      />
                    </v-col>
                   </v-row>
                </template>

                <template v-slot:item.Respiration="{item}">
                      <v-text-field
                        v-model="item.Respiration"
                        :label="$store.state.lang == 'en' ? 'Respiration' : 'Ademhaling'"
                        single-line
                         @change="$store.state.madeBGPChanges = true"
                      ></v-text-field>
                </template>

                <template v-slot:item.HeartRate="{item}">
                      <v-text-field
                        v-model="item.HeartRate"
                        :label="$store.state.lang == 'En' ? 'Heart Rate' : 'Pois'"
                        single-line
                         @change="$store.state.madeBGPChanges = true"
                      ></v-text-field>
                </template>

                 <template v-slot:item.Temperature="{item}">
                      <v-text-field
                        v-model="item.Temperature"
                        :label="$store.state.lang == 'En' ? 'Temperature' : 'Temperatuur'"
                        single-line
                         @change="$store.state.madeBGPChanges = true"
                      ></v-text-field>
                </template>

                <template v-slot:item.BCS="{item}">
                  <v-row justify="end">
                    <v-col cols="8">
                      <v-select
                        v-model="item.BCS"
                        :items="numericDropDownValues"
                        :disabled="item.AnimalID.length==0"
                        @change="$store.state.madeBGPChanges = true"
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.ReumenFill="{item}">
                  <v-row justify="end">
                    <v-col cols="8">
                      <v-select
                        v-model="item.ReumenFill"
                        :items="numericDropDownValues"
                        :disabled="item.AnimalID.length==0"
                        @change="$store.state.madeBGPChanges = true"
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.MobilityScore="{item}">
                  <v-row justify="end">
                    <v-col cols="8">
                      <v-select
                        v-model="item.MobilityScore"
                        :items="numericDropDownValues"
                        :disabled="item.AnimalID.length==0"
                        @change="$store.state.madeBGPChanges = true"
                      />
                    </v-col>
                  </v-row>
                </template>

                 <template v-slot:item.ExcessiveDischarge="{item}">
                  <v-switch
                    v-model="item.ExcessiveDischarge"
                     @change="$store.state.madeBGPChanges = true"
                     :disabled="item.AnimalID.length==0"
                     :label="item.ExcessiveDischarge ? (item.ExcessiveDischarge == 1 ? `${$store.state.translations[$store.state.lang].yes}` : `${$store.state.translations[$store.state.lang].no}`) : `${$store.state.translations[$store.state.lang].no}`"
                  >{{item.discharge}}</v-switch>
                </template>

                 <template v-slot:item.Udder="{item}">
                    <br>
                    <v-row>
                      <v-tooltip
                            bottom
                       >
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="pr-5">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        Het uier wordt minimaal beoordeeld op de volgende punten: uierspanning, kleur uier, samenstelling melk
                    </v-tooltip> 
                      <v-textarea
                        v-model="item.Udder"
                        rows=2
                         @change="$store.state.madeBGPChanges = true"
                         :disabled="item.AnimalID.length==0"
                      ></v-textarea>
                    </v-row>
                      <br>
                </template>

                 <template v-slot:item.OtherAbnormalities="{item}">
                      <br>
                      <v-textarea
                        v-model="item.OtherAbnormalities"
                        rows=2
                         @change="$store.state.madeBGPChanges = true"
                         :disabled="item.AnimalID.length==0"
                      ></v-textarea>
                      <br>
                </template>

                <template v-slot:item.NEBData="{item}">
                  <v-col class="pa-0">
                    <v-row>
                      <v-tooltip
                              bottom
                        >
                          <template v-slot:activator="{ on }">
                              <v-icon v-on="on" class="pr-5">
                                  mdi-help-circle-outline
                              </v-icon>
                          </template>
                          Of er sprake is van negatieve energiebalans (NEB) is vast te stellen via de melkcontrole uitslag of (routinematige) diagnostiek
                      </v-tooltip>
                      <v-switch
                        v-model="item.NEBData"
                        @change="$store.state.madeBGPChanges = true"
                        :disabled="item.AnimalID.length==0"
                        :label="item.NEBData ? (item.NEBData == 1 ? `${$store.state.translations[$store.state.lang].yes}` : `${$store.state.translations[$store.state.lang].no}`) : `${$store.state.translations[$store.state.lang].no}`"
                      >{{item.NEBData}}</v-switch>
                    </v-row>
                    <v-row v-if="item.NEBData == 1 || item.NEBData == true">
                        <v-textarea
                          v-model="item.CaseOfNEB"
                          :label="$store.state.lang == 'En' ? 'If yes, is it a case of NEB?' : 'Zo ja, is er sprake van NEB?'"
                          rows=2
                          @change="$store.state.madeBGPChanges = true"
                          :disabled="item.AnimalID.length==0"
                        ></v-textarea>
                    </v-row>
                  </v-col>

                </template>
             

             </v-data-table>

          </v-row>

           <v-btn @click="addItem()" fab color="primary" :disabled="this.$store.state.bgp.answers.abnormal[this.$store.state.bgp.answers.abnormal.length-1].AnimalID.length == 0">
              <v-icon>add</v-icon>
          </v-btn>

        </v-col>

    </v-container>
</template>

<script>
import serverMixins from './../helpers/ServerOperations'

export default {
    mixins: [serverMixins],
    data () {
        return {
          numericDropDownValues: ['1','2','3','4','5'],
          generalImpressionDropdownValues: {
              'En': [
                  'excited', 
                  'alert',
                  'slow',
                  'generally ill'
              ],
              'Nl': [
                'geexiteerd',
                'alert',
                'traag',
                'algemeen ziek'
              ]
          },
          localCowAssessment: [],
          headers: [
            {
              text: this.$store.state.lang == 'En' ? 'Animal ID' : 'Diernummer' ,
              value: 'AnimalID'
            },
            {
              text: this.$store.state.lang == 'En' ? 'General Impression' : 'Algemene indruk',
              value: 'GeneralImpression'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Respiration' : 'Ademhaling',
              value: 'Respiration'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Heart rate' : 'Pols',
              value: 'HeartRate'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Temperature' : 'Temperatuur',
              value: 'Temperature'
            },
            {
              text: this.$store.state.lang == 'En' ? 'BCS' : 'BCS',
              value: 'BCS'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Reumen Fill' : 'Pensvulling',
              value: 'ReumenFill'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Mobility Score' : 'Locomotiescore',
              value: 'MobilityScore'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Excessive discharge present': 'Overmatige uitvloeiing aanwezig',
              value: 'ExcessiveDischarge'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Udder':'Uier',
              value: 'Udder'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Other noticeable clinical abnormalities' : 'Andere in het oog springende klinische afwijkingen',
              value: 'OtherAbnormalities'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Data regarding negative energy balance (NEB) present' : 'Gegevens over negatieve energiebalans (NEB) aanwezig',
              value: 'NEBData'
            },
          ]
        }
    },
    computed: {

    },
    methods: {
      addItem: function(){
        //console.log(`adding item`)
        try{
          if(this.$store.state.bgp.answers.abnormal.length == 0 || this.$store.state.bgp.answers.abnormal[ this.$store.state.bgp.answers.abnormal.length-1].AnimalID.length>0){
            this.$store.state.bgp.answers.abnormal.push({
              'AnimalID': '',
              'GeneralImpression': '',
              'Respiration': 0,
              'HeartRate': 0,
              'Temperature': 0,
              'BCS': 0,
              'ReumenFill': 0,
              'MobilityScore': 0,
              'ExcessiveDischarge': false,
              'Udder': '',
              'OtherAbnormalities': '',
              'NEBData': false,
              'CaseOfNEB': ''
            })
          } else {
            //console.log('not pushing')
          }
        } catch (e) {
          //console.log(e)
        }
      }
    },
    created: async function(){
       console.log(`abnormal in store is ${JSON.stringify(this.$store.state.bgp.answers.abnormal, undefined, 2)}`)
       if(this.$store.state.bgp.answers.abnormal.length == 0){
         this.addItem()
       }
      await this.saveAll()
    }  
}
</script>

<style scoped>
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td{
    font-size: .875rem;
    height: 100px;
}
</style>