<template>

    <v-form v-model="$store.state[`formIsValid${subSectionDetails.SubsectionSectionAppID.toString()}`]" ref="form">
        {{refreshFormValidation}}
        <span v-for="q in questions" :key="q.ID">
            <v-col class="pa-0">
                <span v-if="q.AppID == 105">
                    <br>
                    <v-divider/>
                    <h4>{{$store.state.translations[$store.state.lang].youngStockOnly}}</h4>
                    <br>
                </span>
                <span v-if="q.AppID == 1105">
                    <br>
                    <v-divider/>
                    <h4>{{$store.state.translations[$store.state.lang].youngStockOnly}}</h4>
                    <br>
                </span>
                <v-row>
                    <p class="smallQuestionText ma-0 pa-0"> {{q[`${$store.state.lang}Desc`]}}</p>
                     <v-tooltip
                            bottom
                            v-if="!hideTooltipAppIDs.includes(q.AppID) && q[`${$store.state.lang}Tooltip`] != null && q[`${$store.state.lang}Tooltip`].length>0"
                        >
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        {{q[`${$store.state.lang}Tooltip`]}}
                    </v-tooltip>
                </v-row>
            </v-col>
            
            <answer :question="q" :key="q.ID"/>
        </span>
    </v-form>

</template>

<script>
import answer from '@/components/Answer'
import { mapGetters } from 'vuex'

export default {
    components: {answer},
    props: ['questions', 'subSectionDetails'],
    data () {
        return {
            value: '',
            hideTooltipAppIDs: [95,96,97,98,99,100,101,102,103,105,106,107,108,109,110,111,112]
        }
    },
    computed: {
        refreshFormValidation () {
            try {
                if(this.$store.getters.getRefreshFormValidation){
                    let validForm = this.$refs.form.validate()
                    //console.log(`validating form ${this.subSectionDetails.SubsectionSectionAppID.toString()} valid form is ${validForm}`)
                    this.$store.state.validForms[`${this.subSectionDetails.SubsectionSectionAppID.toString()}`].valid = validForm
                    this.$store.state[`formRunValidation${this.subSectionDetails.SubsectionSectionAppID.toString()}`] = false
                    this.$store.state.runFormValidation = false
                }
                return ''
            } catch (e) {
                console.log(e)
            }
        }
    },
    methods: {

    },
    mounted: function(){
        //console.log('mounted')
        try {
            this.$store.state[`formRunValidation${this.subSectionDetails.SubsectionSectionAppID.toString()}`] = true
            this.$store.state.validForms[`${this.subSectionDetails.SubsectionSectionAppID.toString()}`].valid = false
            this.$store.state.runFormValidation = true
            this.$refs.form.validate()
        } catch (e) {
            console.log(e)
        }
    },
    created: function(){
    }
}
</script>