
<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
 
    }
  },
  mounted () {
      //console.log(`mounting chart with data ${JSON.stringify(this.chartdata, undefined, 2)}`)
    this.renderChart(this.chartdata, {
                responsive: false,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [{
                    min: 0,
                    ticks: {
                      min: 0
                    }
                  }]
                }
            })
  },
  
}
</script>