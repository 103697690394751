<template>
    <v-container>
        <v-card>
            <v-card-title>
               <h3> {{part.partDetails[`${$store.state.lang}Desc`]}} </h3>
            </v-card-title>
            <span v-for="section in part.sections" :key="$store.state.randomUIKey + section.sectionDetails.ID">
                <v-card-subtitle>
                   <h4> {{section.sectionDetails[`${$store.state.lang}Desc`]}} </h4>
                </v-card-subtitle>
                 <v-data-table
                    :key="$store.state.randomUIKey + Math.random()"
                    :headers="headersWithPrevBGPTarUsage"
                    :items="getItemsWithPrev(section)"
                    :hide-default-footer="true"
                    :items-per-page="100"
                     class="elevation-1"
                     mobile-breakpoint="0"
                     v-if="section.sectionDetails.SectionAppID == 9 || section.sectionDetails.SectionAppID == 1009"
                >
                     <template v-slot:item.Answer="{ item }">
                        <v-chip
                            :color="item.Colour"
                            :light="item.Colour == '#ffffff'"
                            :dark="item.Colour != '#0ffffff'"
                        >
                            {{ item.Answer }}
                        </v-chip>
                    </template>
                 </v-data-table>

                <v-data-table
                    :key="$store.state.randomUIKey + Math.random()"
                    :headers="headers"
                    :items="getItems(section)"
                    :hide-default-header="true"
                    :hide-default-footer="true"
                    :items-per-page="100"
                     class="elevation-1"
                     mobile-breakpoint="0"
                     v-else
                >

                <template v-slot:item.Answer="{ item }">
                    <v-chip
                        :color="item.Colour"
                        :light="item.Colour == '#ffffff'"
                        :dark="item.Colour != '#ffffff'"
                        v-if="item.Answer.length<50"
                    >
                        {{ item.Answer }}
                    </v-chip>
                    <span v-else>
                        {{item.Answer}}
                    </span>
                    
                </template>
                </v-data-table>
            </span>

        </v-card>
    </v-container>
</template>

<script>
export default {
    
    props: ['part', 'thresholds'],
    data () {
        return {
            headersWithPrevBGPTarUsage: [
                {'value': 'Question', 'text': 'Question'},
                {'value': 'PrevBGP', 'text': `${this.$store.state.translations[this.$store.state.lang].prevBGP}`},
                {'value': 'Value', 'text': `${this.$store.state.translations[this.$store.state.lang].value}`},
                {'value': 'Usage', 'text': `${this.$store.state.translations[this.$store.state.lang].usageIs}`},
                {'value': 'Target', 'text': `${this.$store.state.translations[this.$store.state.lang].target}`},
            ],
            headers: [{'value': 'Question', 'text': 'Question'}, {'value': 'Answer', 'text': 'Answer'}]
        }
    },
    computed: {

    },
    methods: {
        getItemsWithPrev: function(section){
            let res = []
            ////console.log('getting items with prev')
            for(let subSection in section.subSections){
                ////console.log(`subsection is ${JSON.stringify(subSection, undefined, 2)}`)
                section.subSections[subSection].questions.forEach((question)=>{
                    ////console.log(`qustion is ${JSON.stringify(question, undefined, 2)}`)
                    // human readable answer for evaluation
                    try{
                       this.getQuestionAnswerWithPrevAndTarget(question).Question != null ? res.push(this.getQuestionAnswerWithPrevAndTarget(question)) : ()=>{}

                        if(question.FollowUpPositiveID != null){
                            this.getQuestionAnswerWithPrevAndTarget(question.FollowUpPositiveID).Question != null ? res.push(this.getQuestionAnswerWithPrevAndTarget(question.FollowUpPositiveID)) : ()=>{}
                        }

                        if(question.FollowUpPositiveID2 != null){
                            this.getQuestionAnswerWithPrevAndTarget(question.FollowUpPositiveID2).Question != null ? res.push(this.getQuestionAnswerWithPrevAndTarget(question.FollowUpPositiveID2)) : ()=>{}
                        }

                        if(question.answer.Type == 'option'){
                            let followUp = this.chosenOptionHasFollowUp(question, this.$store.state.bgp.answers[question.AppID].Value)
                            if (followUp.hasFollowUp){
                                res.push(this.getQuestionAnswerWithPrevAndTarget(followUp.followUpQuestion))
                            } 
                        }   

                    } catch (e){
                        //console.log(e)
                    }
                })
            }

            ////console.log(`res is ${JSON.stringify(res, undefined, 2)}`)
            return res
        }, 
        getQuestionAnswerWithPrevAndTarget: function(question){
            ////console.log(`getQuestionAnswerWithPrevAndTarget q is ${JSON.stringify(question, undefined, 2)}`)
            let ans = ''
            //try {
                if(question.answer.Type == 'option'){
                    let chosenOption = question.answer.options.find((option)=>{
                        ////console.log(`option is ${JSON.stringify(option, undefined, 2)} answer is ${JSON.stringify(this.$store.state.bgp.answers[question.AppID], undefined, 2)}`)
                        let res = option.AppID == (this.$store.state.bgp.answers[question.AppID].Value) || option.AppID == (this.$store.state.bgp.answers[question.AppID].TargetValue)
                        return res
                    })
                    ////console.log(`chosen option is ${JSON.stringify(chosenOption, undefined, 2)}`)
                    ans = chosenOption ? chosenOption[`${this.$store.state.lang}Desc`] : ''
                } else if(question.answer.Type == 'bool'){
                    ans = this.$store.state.bgp.answers[question.AppID].Value == 1 ? this.$store.state.translations[this.$store.state.lang].yes : this.$store.state.translations[this.$store.state.lang].no
                } else {
                    ans = this.$store.state.bgp.answers[question.AppID].Value + ''
                }

                let prevBGP = this.$store.state.bgp.answers[question.AppID].PrevBGPValue ? this.$store.state.bgp.answers[question.AppID].PrevBGPValue : ''
                let target = this.$store.state.bgp.answers[question.AppID].TargetValue ? this.$store.state.bgp.answers[question.AppID].TargetValue + '' : ''
                let usage = this.$store.state.usageOptions.find((option)=>{
                    return option.AppID.toString() == this.$store.state.bgp.answers[question.AppID].Usage
                })

                ////console.log(`prev bgp question is ${question.EnDesc} answer is ${ans}`)
                ////console.log(`prev bgp ${prevBGP} target ${target}`)

                return {
                    'Question': question[`${this.$store.state.lang}Desc`],
                    'Value': ans,
                    'PrevBGP': prevBGP,
                    'Usage': usage ? usage[`${this.$store.state.lang}Desc`] : '',
                    'Target': target,
                    'Colour': this.getColour()
                }
            //} catch (e) {
               // //console.log(`Error! question is ${JSON.stringify(question, undefined, 2)} + ${e}`)
                // return {
                //     'Question': null,
                //     'Answer': null,
                //     'PrevBGP': null,
                //     'Usage': null,
                //     'Target': null,
               // }
                // return {
                //     'Question': '',
                //     'Answer': ''
                // }
            //}
        },
        getItems: function(section){
            let res = []

            for(let subSection in section.subSections){
                console.log(`subsection is ${JSON.stringify(subSection, undefined, 2)}`)
                section.subSections[subSection].questions.forEach((question)=>{
                    
                    // human readable answer for evaluation
                    try{
                       this.getQuestionAnswer(question).Question != null ? res.push(this.getQuestionAnswer(question)) : ()=>{}

                        if(question.FollowUpPositiveID != null){
                            this.getQuestionAnswer(question.FollowUpPositiveID).Question != null ? res.push(this.getQuestionAnswer(question.FollowUpPositiveID)) : ()=>{}
                        }

                        if(question.FollowUpPositiveID2 != null){
                            this.getQuestionAnswer(question.FollowUpPositiveID2).Question != null ? res.push(this.getQuestionAnswer(question.FollowUpPositiveID2)) : ()=>{}
                        }

                        if(question.FollowUpPositiveID3 != null){
                            this.getQuestionAnswer(question.FollowUpPositiveID3).Question != null ? res.push(this.getQuestionAnswer(question.FollowUpPositiveID3)) : ()=>{}
                        }

                        if(question.answer.Type == 'option'){
                            let followUp = this.chosenOptionHasFollowUp(question, this.$store.state.bgp.answers[question.AppID].Value)
                            if (followUp.hasFollowUp){
                                res.push(this.getQuestionAnswer(followUp.followUpQuestion))
                            } 
                        }   

                    } catch (e){
                        console.log(e)
                    }
                })
            }

            console.log(`res is ${JSON.stringify(res, undefined, 2)}`)
            return res
        },
        chosenOptionHasFollowUp: function(question, pOptionID){

            ////console.log(`checking if has followup pOptionID = ${pOptionID}`)
            let res = {
                'hasFollowUp': false,
                'followUpQuestion': {}
            }

            let option = question.answer.options.find((op)=>{
                return op.AppID == pOptionID
            })

            ////console.log(`option is ${JSON.stringify(option, undefined, 2)}`)

            if(option && option.FollowUpQuestionAppID != null && option.FollowUpQuestionAppID != 0){
                res.hasFollowUp = true
                res.followUpQuestion = option.followUpQuestion
            }

            ////console.log(`result is ${JSON.stringify(res, undefined, 2)}`)

            return res
        },
        getQuestionAnswer: function(question){
            let ansText = ''
            let ansObject = {}
            try {
                if(question.answer.Type == 'option'){
                    let chosenOption = question.answer.options.find((option)=>{
                        ////console.log(`option is ${JSON.stringify(option, undefined, 2)} answer is ${JSON.stringify(this.$store.state.bgp.answers[question.AppID], undefined, 2)}`)
                        let res = option.AppID == (this.$store.state.bgp.answers[question.AppID].Value) || option.AppID == (this.$store.state.bgp.answers[question.AppID].TargetValue)
                        return res
                    })
                    ////console.log(`chosen option is ${JSON.stringify(chosenOption, undefined, 2)}`)
                    ansText = chosenOption ? chosenOption[`${this.$store.state.lang}Desc`] : ''
                    ansObject = this.$store.state.bgp.answers[question.AppID.toString()]
                } else if(question.answer.Type == 'bool'){
                    ansText = this.$store.state.bgp.answers[question.AppID.toString()].Value == 1 ? this.$store.state.translations[this.$store.state.lang].yes : this.$store.state.translations[this.$store.state.lang].no
                    ansObject = this.$store.state.bgp.answers[question.AppID.toString()]
                } else {
                    ansText = this.$store.state.bgp.answers[question.AppID.toString()].Value ? this.$store.state.bgp.answers[question.AppID.toString()].Value + '' : ''
                    ansObject = this.$store.state.bgp.answers[question.AppID.toString()]
                }

                ////console.log(`question is ${question.EnDesc} answer is ${ans}`)

                return {
                    'Question': question[`${this.$store.state.lang}Desc`],
                    'Answer': ansText,
                    'Colour': this.getColour(question, ansObject)
                }
            } catch (e) {
                ////console.log(`Error! question is ${JSON.stringify(question, undefined, 2)} + ${e}`)
                return {
                    'Question': null,
                    'Answer': null,
                    'Colour': this.getColour()
                }
                // return {
                //     'Question': '',
                //     'Answer': ''
                // }
            }
        },
        getColour(question, answer){
            let primaryColour = '#9c27b0'
            let black = '#000000'
            let grey = '#605d5d'
            let white = '#ffffff'
            let highThreshColour = '#00580a'
            let lowThreshColour = '#901600'
            let medThreshColour = '#ecb814'

            let returnColour = white

            console.log(`get colour for question ${JSON.stringify(question, undefined, 2)} answer ${JSON.stringify(answer, undefined, 2)}`)

            try {
                    
                let thresh = this.thresholds[answer.FormQuestionAppID.toString()]

                console.log(`thresholds are ${JSON.stringify(thresh, undefined, 2)}`)
                        
                if (answer && thresh){
                    if(question.answer.Type == 'bool' || question.answer.Type == 'option'){
                        let highThresh = thresh.HighThreshold.split(',')
                        let medThresh = thresh.MedThreshold ? thresh.MedThreshold.split(',') : []
                        let lowThresh = thresh.LowThreshold.split(',')

                        // PATCH for scenario where user leaves switch at default
                        if(question.answer.Type == 'bool' && answer.Value.length==0){
                            answer.Value = 0
                        }

                        if(highThresh.includes(answer.Value.toString())){
                            returnColour = highThreshColour
                        } else if (lowThresh.includes(answer.Value.toString())){
                            returnColour = lowThreshColour
                        } else if (medThresh.includes(answer.Value.toString())){
                            returnColour = medThreshColour
                        }
                    //} else if (question.answer.Type == 'int' || question.answer.Type == 'decimal'){
                    } else {
                        //console.log(`question ${JSON.stringify(question, undefined, 2)} answer is ${JSON.stringify(answer, undefined, 2)} thresh is ${JSON.stringify(thresh, undefined, 2)}`)
                        
                        // number is calculated pc for these questions
                        let pcQuestions = [17,72,73,76,191,198,1017, 1072, 1076]
                        if(pcQuestions.includes(question.AppID)){
                            try {
                                //console.log(`*********** pc question ************`)
                                let pc = answer.Value.split('(')
                                let pcNumberComponent = pc[1].split('%')
                                answer.pcValue = pcNumberComponent[0]
                                //console.log(`SET ANSWER value TO ${answer.Value} pc value = ${answer.pcValue}`)
                            } catch (e) {
                                console.log(e)
                            }
                        }

                        let valToUseForThresh = answer.pcValue ? parseFloat(answer.pcValue) : parseFloat(answer.Value)

                        // if first value is a number check for '-' otherwise first number is an operator, e.g. >
                        if(! Number.isFinite(thresh.HighThreshold[0])){
                            let threshNumber = thresh.HighThreshold.substring(1, thresh.HighThreshold.length)
                            if (this.satisfiesCriteria(thresh.HighThreshold[0], valToUseForThresh, parseFloat(threshNumber))){
                                returnColour = highThreshColour
                                return returnColour
                            }
                        } 
                        
                        if (! Number.isFinite(thresh.LowThreshold[0])){
                            let threshNumber = thresh.LowThreshold.substring(1, thresh.LowThreshold.length)
                            if (this.satisfiesCriteria(thresh.LowThreshold[0], valToUseForThresh, parseFloat(threshNumber))){
                                returnColour = lowThreshColour
                                return returnColour
                            }
                        }
                        
                        if(thresh.MedThreshold != null && thresh.MedThreshold != 0 && thresh.MedThreshold.length > 1){
                            let range = thresh.MedThreshold.split('-')
                            //console.log(`range is ${range}`)
                            
                            if (this.satisfiesCriteria('-', valToUseForThresh, parseFloat(range[0]), parseFloat(range[1]))){
                                returnColour = medThreshColour
                                return returnColour
                            }
                        }
                    }
                } 
                console.log(`return colour = ${returnColour}`)
                return returnColour
            } catch (e) {
                console.log(`${e} question is ${JSON.stringify(question, undefined, 2)} answer is ${JSON.stringify(answer, undefined, 2)}`)
                return returnColour
            }
        },
        satisfiesCriteria(symbol, value, threshold1, threshold2 = null){
            //console.log(`symbol is ${symbol} value is ${value} threshold1 is ${threshold1} threshold2 is ${threshold2}`)
            if(symbol == '<'){
                //console.log(`returning ${value < threshold1}`)
                return value < threshold1
            } else if (symbol == '>'){
                //console.log(`returning ${value > threshold1}`)
                return value > threshold1
            } else if (symbol == '-'){
                //console.log(`returning ${(value >= threshold1 && value <= threshold2)}`)
                return (value >= threshold1 && value <= threshold2)
            }
        }
    },
    created: function(){
        ////console.log(`created evaluation section part is ${JSON.stringify(this.part, undefined, 2)}`)
    }
}
</script>