<template>
    <v-container ref="container" class="pa-0">
        <v-card v-if="creatingPDF || emailingPDF">

            <v-col>
                <v-row justify="center">
                    <v-progress-circular color="primary" size="100" indeterminate/>
                </v-row>
                <v-row justify="center">
                    <h4 v-if="creatingPDF"> {{$store.state.translations[$store.state.lang].genPDF}} </h4>
                    <h4 v-else> {{$store.state.translations[$store.state.lang].emailingPDF}} </h4>
                </v-row>
            </v-col>

        </v-card>

        <v-card class="pa-0" v-else>
            <v-card-title> {{$store.state.translations[$store.state.lang].signature}} </v-card-title>

            <v-col class="pa-0">
                <v-row justify="center">
                    <v-col cols="6" class="pl-5">
                        <h3>{{$store.state.translations[$store.state.lang].vet}}</h3>
                    </v-col>
                    <v-col cols="6">
                        <v-btn text @click="clearVet">
                            {{$store.state.translations[$store.state.lang].clear}}
                            <v-icon color='red'>clear</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center" class="pa-2">
                    <VueSignaturePad :key="vetKey" class="greyBorder" :width="`${this.$store.state.signWidth - 100}px`" height="100px" ref="signaturePad"/>
                </v-row>

                <v-row justify="center">
                    <v-col cols="6" class="pl-5">
                        <h3>{{$store.state.translations[$store.state.lang].farmer}}</h3>
                    </v-col>
                    <v-col cols="6">
                        <v-btn text @click="clearFarmer">
                            {{$store.state.translations[$store.state.lang].clear}}
                            <v-icon color='red'>clear</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center" class="pa-2">
                    <VueSignaturePad :key="farmerKey" class="greyBorder" :width="`${this.$store.state.signWidth - 100 }px`" height="100px" ref="farmerSignaturePad" />
                </v-row>

                <v-divider/>
                
                <v-row justify="center">
                    <v-checkbox
                        v-model="sendEmail"
                        :label="`${$store.state.translations[$store.state.lang].sendEmail}`"
                    ></v-checkbox>
                </v-row>
                <v-row justify="center">
                    <v-textarea
                        class="pa-5"
                        v-if="sendEmail"
                        v-model="emailAddresses"
                        :label="`${$store.state.translations[$store.state.lang].enterEmailAddresses}`"
                    >
                    </v-textarea>
                </v-row>
                <v-divider/>
            </v-col>

            <v-card-actions>
                 <v-col cols="12">
                    <p v-if="showEmptySig" class="errorText">
                        {{emptySigMessage}}
                    </p>

                    <v-row>
                        <v-col cols="6" class="pa-0">
                            <v-row justify="start">
                                <v-btn text text color="error" @click="resetErrorMessages(); $store.state.showCompleteDialog = false">
                                    <v-icon> close </v-icon>
                                    <h3>{{$store.state.translations[$store.state.lang].cancel}}</h3>
                                </v-btn>
                            </v-row>
                        </v-col>

                        <v-col cols="6" class="pa-0">
                            <v-row justify="end">
                                <v-btn text text color="info" @click="completeBGP()">
                                    <v-icon :color="saveIconColour"> done </v-icon>
                                    <h3>{{saveText}}</h3>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                 </v-col>
            </v-card-actions>
        </v-card>

        <!-- <vue-html2pdf
            :show-layout="true"
            :enable-download="false"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="getFileName()"
            :pdf-quality="1"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref='html2Pdf'
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)" 
            >
                <section slot="pdf-content">
                        <evaluationTab/> 
                </section>
        </vue-html2pdf> -->
    </v-container>
</template>

<script>
import evaluationTab from './EvaluationTab'
import VueHtml2pdf from 'vue-html2pdf'
const moment = require('moment')
const axios = require('axios')
import serverMixins from './../helpers/ServerOperations'

export default {
    mixins: [serverMixins],
    components: {
        evaluationTab,
        VueHtml2pdf
    },
    data () {
        return {
            farmerKey: 1,
            vetKey: 1000,
            width: 200,
            creatingPDF: false,
            emailingPDF: false,
            time: moment().format("YYYY-MM-DD-HH-mm"),
            vetSignature: null,
            farmerSignature: null,
            sendEmail: false,
            emailAddresses: '',
            showEmptySig: false,
            vetSigEmpty: false,
            farmerSigEmpty: false,
            proceedNumClick: 0,
            saveText: this.$store.state.translations[this.$store.state.lang].complete,
            saveIconColour: 'green'
        }
    },
    computed: {
        emptySigMessage: function(){
            if (this.vetSigEmpty && this.farmerSigEmpty){
                return this.$store.state.translations[this.$store.state.lang].vetAndFarmerSigEmptyProceed
            } else if (this.vetSigEmpty){
                return this.$store.state.translations[this.$store.state.lang].vetSigEmptyProceed
            } else if (this.farmerSigEmpty){
                return this.$store.state.translations[this.$store.state.lang].farmerSigEmptyProceed
            }
        }
    },
    methods: {
        resetErrorMessages: function(){
            this.showEmptySig = false
            this.vetSigEmpty = false
            this.farmerSigEmpty = false
            this.proceedNumClick = 0
            this.saveText = this.$store.state.translations[this.$store.state.lang].complete,
            this.saveIconColour = 'green'
        },
        clearFarmer: function(){
            this.farmerKey ++
        },
        clearVet: function(){
            this.vetKey ++
        },
        setWindowWidth: function(){
            try {
                this.width = this.$refs.container.clientWidth < 450 ? this.$refs.container.clientWidth : 450
            } catch (e){
                //console.log(e)
                this.width = 250
            }
            //console.log(`window width is ${this.width}`)
        },
        getFileName: function(){
            return `${this.$store.state.bgp.farm.UBN}-${this.$store.state.user.UDN}-${this.time}.pdf`
        },
        completeBGP: async function(){
            
            try {
                
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                this.vetSignature = data

                let saveSigResult = this.$refs.farmerSignaturePad.saveSignature();
                this.farmerSignature = saveSigResult.data

                this.vetSigEmpty = isEmpty

                this.farmerSigEmpty = saveSigResult.isEmpty
       
                if ((this.vetSigEmpty || this.farmerSigEmpty) && this.proceedNumClick > 0){
                    // we have already clicked save once so show proceed
                    this.showEmptySig = false
                    await this.sendSignatureAndCreatePDF()
                }
                else if (this.vetSigEmpty || this.farmerSigEmpty){
                    this.showEmptySig = true
                    this.saveText = this.$store.state.translations[this.$store.state.lang].proceed
                    this.saveIconColour = 'red'
                } else {
                    await this.sendSignatureAndCreatePDF()
                }
        
                this.proceedNumClick++
                //this.$refs.html2Pdf.generatePdf()

            } catch (e) {
                this.$store.state.showCompleteDialog = false
                this.$store.state.snackBarText = this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection
                this.$store.state.snackBarColour = 'error'
                this.$store.state.showSnackBar = true
                console.log(e)
            }

            

        },
        sendSignatureAndCreatePDF: async function(){
            try {
                this.creatingPDF = true

                await this.saveAll()

                let headers = await this.$store.state.zlogin.getHeaders()

                if(this.farmerSigEmpty == false){
                    try {
                        let farmerData = new FormData()
                        farmerData.append('signature', this.farmerSignature)
                        await axios.post(`/signature/${this.$store.state.bgp.UUID}/FarmerSignatureURL`, farmerData, headers)
                    } catch (e) {
                        console.log(e)
                        throw 'connection error'
                    }
                }

                if (this.vetSigEmpty == false){
                    try {
                        let formData = new FormData()
                        formData.append('signature', this.vetSignature)
                        await axios.post(`/signature/${this.$store.state.bgp.UUID}/VetSignatureURL`, formData, headers)
                    } catch (e) {
                        console.log(e)
                        throw 'connection error'
                    }
                }
             
                try {
                    await axios.get(`/bgpPDF/${this.$store.state.bgp.UUID}`, headers)
                } catch (e) {
                    console.log(e)
                    throw 'connection error'
                }

                this.creatingPDF = false
                this.emailingPDF = true

                if(this.emailAddresses.length>0){
                    try {
                        await axios.post(`/bgpEmail/${this.$store.state.bgp.UUID}`,{
                            emailAddresses: this.emailAddresses
                        }, headers)
                    } catch (e) {
                        console.log(e)
                        throw 'connection error'
                        if(this.checkIfOfflineAndShowCacheSnackbar){

                        } else {
                            this.$store.state.snackBarText = this.$store.state.translations[this.$store.state.lang].errorEmailing
                            this.$store.state.showSnackBar = true
                        }
                    }
               }
                                 
                this.emailingPDF = false

                this.$store.state.showCompleteDialog = false
                this.$router.push('/')
            } catch (e) {
                this.creatingPDF = false
                this.$store.state.showCompleteDialog = false
                this.$store.state.snackBarText = this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection
                this.$store.state.snackBarColour = 'error'
                this.$store.state.showSnackBar = true
                console.log(e)
            }
                
        },
        onProgress: function(event){
            //console.log(`event is ${JSON.stringify(event, undefined, 2)}`)
        },
        hasStartedGeneration: function(){
            //console.log('generating pdf...')
        },
        hasGenerated: async function($event){
            //console.log(`finished generating event is ${JSON.stringify($event, undefined, 2)}`)
            this.creatingPDF = false

            try {
                let formData = new FormData()
                let headers = await this.$store.state.zlogin.getHeaders()
                formData.append('bgp', $event)

                await axios.post(`/bgpPDF/${this.$store.state.bgp.ID}`, formData, headers)

                this.uploadingPDF = false
                this.$store.state.showCompleteDialog = false
                this.$router.push('/')
            } catch (e) {
                this.uploadingPDF = false
                this.$store.state.showCompleteDialog = false
                this.$store.state.snackBarText = this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection
                this.$store.state.showSnackBar = true
                this.$store.state.snackBarColour = 'error'
                console.log(e)
            }
            
            
            
        }
    },
    created: function(){
        //console.log('complete dialog created')
        this.$nextTick(() => {
            this.$refs.signaturePad.resizeCanvas();
            this.$refs.farmerSignaturePad.resizeCanvas();
        })
    },
    mounted:function() {
        window.addEventListener('resize', this.setWindowWidth)
        this.setWindowWidth()
    },
}
</script>