var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_c('v-img',{staticClass:"logo pa-2",attrs:{"alt":"SGD Logo","src":_vm.logo,"transition":"scale-transition","max-height":"60","max-width":"150"},on:{"click":function($event){return _vm.$router.push('/');}}})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"purple","text":""},on:{"click":function($event){_vm.$store.state.tooltip.show = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.$store.state.tooltip.show),callback:function ($$v) {_vm.$set(_vm.$store.state.tooltip, "show", $$v)},expression:"$store.state.tooltip.show"}},[_vm._v(" "+_vm._s(_vm.$store.state.tooltip.text)+" ")]),_c('v-spacer'),_c('span',{key:_vm.$store.state.randomUIKey,staticClass:"whiteText"},[_vm._v(" "+_vm._s(_vm.$store.state.title)+" ")]),(_vm.$route.name != 'Login' && _vm.$store.state.reportLoggedIn)?_c('v-menu',{attrs:{"right":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/reportUserSettings')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("settings")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$store.state.translations[_vm.$store.state.lang].settings)+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.$store.state.reportLoggedIn=false;
          _vm.$store.state.title=''; 
          _vm.$router.push('/reportLogin'
          )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.state.translations[_vm.$store.state.lang].logout))])],1)],1)],1)],1):_vm._e(),(_vm.$route.name != 'Login' && !_vm.$store.state.reportLoggedIn)?_c('v-menu',{attrs:{"right":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.state.translations[_vm.$store.state.lang].home))])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/settings')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("settings")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$store.state.translations[_vm.$store.state.lang].settings)+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""},on:{"click":function($event){_vm.$store.state.zlogin.logout()
          _vm.$store.state.loggedIn=false;
          _vm.$store.state.title=''; 
          _vm.$router.push('/login'
          )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$store.state.translations[_vm.$store.state.lang].logout))]),_c('v-list-item-subtitle',[_vm._v("UDN: "+_vm._s(_vm.$store.state.user.UDN))])],1)],1)],1)],1):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"color":"primary","absolute":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("Home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("home")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("BGP")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("plan")])],1)],1)],1)],1),_c('v-content',[_c('v-snackbar',{attrs:{"timeout":"4000","color":_vm.$store.state.snackBarColour},model:{value:(_vm.$store.state.showSnackBar),callback:function ($$v) {_vm.$set(_vm.$store.state, "showSnackBar", $$v)},expression:"$store.state.showSnackBar"}},[_vm._v(" "+_vm._s(_vm.$store.state.snackBarText)+" ")]),_c('router-view')],1),_c('bottomNavigation')],1)}
var staticRenderFns = []

export { render, staticRenderFns }