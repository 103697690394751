<template>
     <v-card>
        <v-toolbar dark color="primary" class="stickyTop">
          <v-btn icon dark @click="$store.state.addInfoDialog = false; saveAll()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{$store.state.translations[$store.state.lang].additionalInformation}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text >Save</v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
          <span>
            <part :part="$store.state.questions['6']"/>
          </span>
      </v-card>
</template>

<script>
import part from '@/components/Part.vue'
import serverOperations from './../helpers/ServerOperations'

export default {
    mixins: [serverOperations],
    components: {
      part
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    created: function(){

    }
}
</script>