export default  {
    'En': {
        'medirundUsername': 'Medirund Username',
        'medirundPassword': 'Medirund Password',
        'medirundLoginDetails': 'Medirund login details', 
        'settings': 'Settings',
        'addMedirund': 'Please add Medirund login details',
        'complete': 'Complete',
        'performance': 'Performance',
        'animalID': 'Animal ID',
        'mobilityScore': 'Mobility Score',
        'bcs': 'BCS',
        'reumenFill': 'Reumen Fill',
        'hockScore': 'Hock Score',
        'numLessions': 'Number of Lesions',
        'hygieneScore': 'Hygiene Score',
        'faecalScore': 'Faecal Score',
        'evaluation': 'Evaluation',
        'errorLoggingIn': 'Error logging in, please check details.',
        'resume': 'Resume',
        'resumeBGP': 'Resume BGP',
        'resumeFoodSafety': 'Resume Official Check',
        'newBGP': 'New BGP',
        'newFoodSafety': 'New Official Check',
        'ubn': 'UBN',
        'farm': 'Farm',
        'forms': 'Forms',
        'actions': 'Actions',
        'search': 'Search',
        'home': 'Home',
        'logout': 'Logout',
        'additionalInfo': 'Addtional Info',
        'assessment': 'Assessment',
        'cancel': 'Cancel',
        'export': 'Export',
        'save': 'Save',
        'additionalInformation': 'Additional Information',
        'sortBy': 'Sort By',
        'bgp': 'BGP',
        'rowsPerPage': 'Rows Per Page',
        'foodSafety': 'Official Check',
        'abnormal': 'Individual Inspection',
        'yes': 'Yes',
        'no': 'No',
        'login': 'Login',
        'errorSaving': 'Error saving please try again!',
        'saved': 'Saved!',
        'errorResuming': 'Error resumming BGP.',
        'changeLanguageTo': 'Change language to:',
        'value': 'Value',
        'target': 'Target',
        'prevBGP': 'Previous BGP',
        'what': 'What',
        'who': 'Who', 
        'when': 'When',
        'result': 'Result',
        'realised': 'Realised',
        'explanationEvaluation': 'Explanation/Evaluation',
        'vet': 'Veterinarian',
        'farmer': 'Farmer',
        'signature': 'Signature',
        'genPDF': 'Generating PDF...',
        'uploadPDF': 'Uploading PDF...',
        'usageIs': 'Usage is',
        'sendEmail': 'Send Email Copy?',
        'enterEmailAddresses': 'Addresses seperated by semicolan (;)',
        'date': 'Date',
        'clear': 'Clear',
        'youngStockOnly': 'Young Stock',
        'errorEmailing': 'Error Emailing - please download .pdf and email manually.',
        'emailingPDF': 'Emailing .pdf ...',
        'maxLengthLessThan': 'Max. length less than ',
        'connectionErrorUsingCache': 'Connection error - using cache',
        'vetLogin': 'Vet Login',
        'reportingLogin': 'Reporting Login',
        'password': 'Password',
        'welcomeToSGDReportingPlatform': 'Welcome to the SGD Reporting Platform',
        'allReports': 'All Reports',
        'datesAndDocuments': 'Documents and Dates',
        'search': 'Search',
        'farmUBN': 'Farm UBN',
        'vetUDN': 'Vet UDN',
        'dateReq': 'Date Required',
        'dateComplete': 'Date Completed',
        'overdue': 'Days Valid',
        'reportLink': 'Report Link',
        'fromDate': 'From',
        'toDate': 'To',
        'using': 'Using',
        'anyDate': 'Any Date',
        'bgp': 'BGP (Parts A-C)',
        'foodSafety': 'Food Safety (Part D)',
        'any': 'Any',
        'type': 'Type',
        'searchWithinResults': 'Search within results',
        'download': 'Download',
        'userManagement': 'User Management',
        'addAndEditUsers': 'Add and Edit Users',
        'surname': 'Surname',
        'forename': 'Forename',
        'email': 'Email',
        'admin': 'Admin',
        'actions': 'Actions',
        'reset': 'Reset',
        'addNewUser': 'Add New User',
        'editUser': 'Edit User',
        'tempPassword': 'Temporary Password',
        'canAddAndEditUsers': 'Can add and edit users (Administrator)',
        'areYouSure': 'Are you sure?',
        'includeDraft': 'Include date completed = blank?',
        'pleaseCheckTheFollowingSections': 'Please check the following sections are complete:',
        'udnError': 'This account is not linked to a UDN.',
        'healthStatusFoundAtPart1': 'The health status for this farmer can be found',
        'here': 'here',
        'healthStatusFoundAtPart2': ' ',
        'dueIn': 'Due in',
        'days': 'days',
        'overdueBy': 'Overdue by',
        'bppRelated': 'BBP Related',
        'dueOn': 'Due on: ',
        'dairy': 'Dairy',
        'beef': 'Beef',
        'areYouSureYouWantToDelete': 'Are you sure you want to delete ',
        'forUBN': 'for UBN',
        'resume': 'Resume',
        'delete': 'Delete',
        'vetSigEmptyProceed': 'Vet signature empty. Are you sure you wish to continue?',
        'farmerSigEmptyProceed': 'Farmer\'s signature empty. Are you sure you wish to continue?',
        'vetAndFarmerSigEmptyProceed': 'Vet and Farmer\'s signature empty. Are you sure you wish to continue?',
        'proceed': 'Continue',
        'errorSavingPleaseCheckNetworkConnection': 'Error saving - Please check network connection',
        'notifiableOfficialChecks': 'Notifiable Official Checks',
        'year': 'Year',
        'chooseYear': 'Choose year:',
        'totalOfficalChecksPerQuarter': 'Total Official Checks Per Quarter',
        'totalUBNsPerQuarter': 'Total UBNs Per Quarter',
        'totalNotifiablePerQuarter': 'Total Notifiable Per Quarter',
        'exportUBNs': 'Export UBNs',
        'exportSummaryData': 'Export Summary Data',
        'noDataPleaseCheckSettings': 'No data please check settings.'
    },
    'Nl': {
        'medirundUsername': 'Medirund gebruikersnaam',
        'medirundPassword': 'Medirund-wachtwoord',
        'medirundLoginDetails': 'Medirund-inloggegevens',
        'settings': 'Instellingen',
        'addMedirund': 'Voeg de inloggegevens van Medirund toe',
        'complete': 'Voltooien',
        'performance': 'Prestatie',
        'animalID': 'Diernummer',
        'mobilityScore': 'Locomotiescore',
        'bcs': 'BCS',
        'reumenFill': 'Pensvulling',
        'hockScore': 'Hakscore',
        'numLessions': 'Aantal kale plekken',
        'hygieneScore': 'Hygiëne Score',
        'faecalScore': 'Mestscore',
        'evaluation': 'Evaluatie',
        'errorLoggingIn': 'Fout bij inloggen, controleer de details.',
        'resume': 'Hervatten',
        'resumeBGP': 'Hervatten BGP',
        'resumeFoodSafety': 'Hervatten Officiële Controle',
        'newBGP': 'BGP',
        'bgp': 'BGP',
        'ubn': 'UBN',
        'farm': 'Bedrijf',
        'forms': 'Formulieren',
        'actions': 'Acties',
        'search': 'zoeken',
        'home': 'Home',
        'logout': 'Uitloggen',
        'additionalInfo': 'Extra informatie',
        'assessment': 'Beoordeling',
        'cancel': 'Annuleer',
        'export': 'exporteren',
        'save': 'Opslaan',
        'additionalInformation': 'Extra informatie',
        'sortBy': 'Sorteer op',
        'rowsPerPage': 'Rijen per pagina',
        'foodSafety': 'Officiële Controle',
        'abnormal': 'Individuele inspectie',
        'yes': 'Ja',
        'no': 'Nee',
        'login': 'Log in',
        'errorSaving': 'Fout bij opslaan, probeer het opnieuw!',
        'saved': 'Opgeslagen!',
        'errorResuming': 'Fout bij het hervatten van BGP.',
        'changeLanguageTo': 'Verander de taal in',
        'value': 'Waarde',
        'target': 'Streefwaarde',
        'prevBGP': 'Vorige BGP',
        'what': 'Wat',
        'who': 'Wie', 
        'when': 'Wanneer',
        'result': 'Resultaat',
        'realised': 'Gerealiseerd',
        'explanationEvaluation': 'Uitleg/Evaluatie',
        'vet': 'Dierenarts',
        'farmer': 'Veehouder',
        'signature': 'Handtekening',
        'genPDF': 'PDF genereren ...',
        'uploadPDF': 'PDF uploaden...',
        'usageIs': 'Dit gebruik is',
        'sendEmail': 'E-mailkopie verzenden?',
        'enterEmailAddresses': 'Adressen gescheiden door puntkomma\'s (;)',
        'newFoodSafety': 'Officiële Controle',
        'date': 'Datum',
        'youngStockOnly': 'Jongvee',
        'errorEmailing': 'Fout bij e-mailen - download .pdf en e-mail handmatig.',
        'emailingPDF': 'E-mailen .pdf ...',
        'maxLengthLessThan': 'Max. lengte minder dan ',
        'clear': 'Wegvagen', 
        'connectionErrorUsingCache': 'Verbindingsfout - gebruikt cache.',
        'vetLogin': 'Dierenarts login',
        'reportingLogin': 'Rapportage Login',
        'password': 'Wachtwoord',
        'welcomeToSGDReportingPlatform': 'Welkom bij het SGD Rapportage Platform',
        'allReports': 'Alle Verslagen',
        'datesAndDocuments': 'Datums and Documenten',
        'search': 'Zoeken',
        'farmUBN': 'Bedrijfs UBN',
        'vetUDN': 'Dierenarts UDN',
        'dateReq': 'Datum Vereist',
        'dateComplete': 'Datum Voltooid',
        'overdue': 'Dagen Geldig',
        'reportLink': 'Verslag Link',
        'fromDate': 'Van',
        'toDate': 'Tot',
        'using': 'Voor',
        'anyDate': 'Alle Datums',
        'foodSafety': 'Officiële Controle',
        'any': 'Alle',
        'type': 'Type',
        'searchWithinResults': 'zoek in de resultaten',
        'download': 'Downloaden',
        'userManagement': 'Gebruikersbeheer',
        'addAndEditUsers': 'Toevoegen en Bewerken Gebruikers',
        'surname': 'Achternaam',
        'forename': 'Voornaam',
        'email': 'E-mail',
        'admin': 'Admin?',
        'actions': 'Acties',
        'reset': 'reset',
        'addNewUser': 'Gebruiker Toevoegen',
        'editUser': 'Bewerk Gebruiker',
        'tempPassword': 'Tijdelijk wachtwoord',
        'canAddAndEditUsers': 'Kan gebruikers toevoegen en bewerken (Administrator)',
        'areYouSure': 'Weet je het zeker?',
        'includeDraft': 'Zonder Datum Voltooid',
        'pleaseCheckTheFollowingSections': 'Foutief ingevulde of ontbrekende velden',
        'udnError': 'Dit Z-Login account is niet gekoppeld aan een UDN.',
        'healthStatusFoundAtPart1': 'De gezondheidsstatussen van deze veehouder zijn',
        'here': 'hier',
        'healthStatusFoundAtPart2': 'te vinden',
        'dueIn': 'Verschuldigd in',
        'days': 'dagen',
        'overdueBy': 'Achterstallig door',
        'bppRelated': 'BBP gerelateerd',
        'dueOn': 'Verloopt op:',
        'dairy': 'Melkvee',
        'beef': 'Vleesvee',
        'areYouSureYouWantToDelete': 'Weet u zeker dat u het concept ',
        'forUBN': 'van UBN',
        'resume': 'Hervatten',
        'delete': 'Verwijderen',
        'vetSigEmptyProceed': 'Handtekening dierenarts is niet gezet. Weet u zeker dat u door wilt gaan?',
        'farmerSigEmptyProceed': 'Handtekening veehouder is niet gezet. Weet u zeker dat u door wilt gaan?',
        'vetAndFarmerSigEmptyProceed': 'Handtekening dierenarts en veehouder zijn niet gezet. Weet u zeker dat u door wilt gaan?',
        'proceed': 'Doorgaan',
        'errorSavingPleaseCheckNetworkConnection': 'Foutmelding bij opslaan - controleer uw internetverbinding',
        'notifiableOfficialChecks': 'Geconstateerde Tekortkomingen',
        'year': 'Jaar',
        'chooseYear': 'Selecteer jaar:',
        'totalOfficalChecksPerQuarter': 'Aantal officiële controles per kwartaal',
        'totalUBNsPerQuarter': 'Aantal UBNs per kwartaal',
        'totalNotifiablePerQuarter': 'Aantal OC met geconstateerde tekortkomingen per kwartaal',
        'exportUBNs': 'Exporteer UBNs',
        'exportSummaryData': 'Exporteer Jaaroverzicht',
        'noDataPleaseCheckSettings': 'No data please check settings.'
    }
}