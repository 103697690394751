<template>
    <v-container :key="randomUIKey">
      <v-row justify="center" v-if="loading">
        <v-progress-circular indeterminate size="100" color="primary"/>
      </v-row>
      <span v-else>
        <span v-if="$store.state.bgp.details.Type=='bgp'">
          <evaluationSection :thresholds="thresholds" :key="$store.state.randomUIKey" :part="$store.state.questions['1']"/>
          <v-divider/>
          <evaluationSection :thresholds="thresholds" :key="$store.state.randomUIKey + 1" :part="$store.state.questions['2']"/>
          <v-divider/>
          <evaluationSection :thresholds="thresholds" :key="$store.state.randomUIKey + 2" :part="$store.state.questions['3']"/>
        </span>
        <span v-else>
          <evaluationSection :thresholds="thresholds" :key="$store.state.randomUIKey + 1000" :part="$store.state.questions['1001']"/>
          <v-divider/>
          <evaluationSection :thresholds="thresholds" :key="$store.state.randomUIKey + 1001" :part="$store.state.questions['1002']"/>
          <v-divider/>
          <evaluationSection :thresholds="thresholds" :key="$store.state.randomUIKey + 1002" :part="$store.state.questions['1003']"/>
        </span>
        
         <v-card>
            <v-card-subtitle>
                <h4> {{$store.state.translations[$store.state.lang].assessment}} </h4>
            </v-card-subtitle>
            <v-data-table
                :key="$store.state.randomUIKey +4"
                :headers="assessmentHeaders"
                :items="$store.state.bgp.answers.cowAssessment"
                :hide-default-header="false"
                :hide-default-footer="true"
                :items-per-page="100"
                    class="elevation-1"
                    mobile-breakpoint="0"
            />
            <evaluationSection :thresholds="thresholds" :key="$store.state.randomUIKey + 5" :part="$store.state.questions['7']"/>
        </v-card>

        <v-divider/>

        <v-card>
            <v-card-title>
               <h5> {{$store.state.questions['5'].partDetails[`${$store.state.lang}Desc`]}} </h5>
            </v-card-title>
            <span>
                <v-card-subtitle>
                   <h6> {{$store.state.translations[$store.state.lang].evaluation}} </h6>
                </v-card-subtitle>
                <v-data-table
                    :key="$store.state.randomUIKey + 6"
                    :headers="evaluationPointsHeader"
                    :items="this.$store.state.bgp.evaluationPoints"
                    :hide-default-header="false"
                    :hide-default-footer="true"
                    :items-per-page="100"
                     class="elevation-1"
                     mobile-breakpoint="0"
                />
            </span>

            <span>
                <v-card-subtitle>
                   <h6> {{$store.state.translations[$store.state.lang].actions}} </h6>
                </v-card-subtitle>
                <v-data-table
                    :key="$store.state.randomUIKey + 7"
                    :headers="actionPointsHeader"
                    :items="this.$store.state.bgp.actionPoints"
                    :hide-default-header="false"
                    :hide-default-footer="true"
                    :items-per-page="100"
                     class="elevation-1"
                     mobile-breakpoint="0"
                >
                  <template v-slot:item.bppRelated="{item}">
                    <span v-if="item.What && item.What.length>0">
                      {{item.bppRelated ? (item.bppRelated == 1 ? `${$store.state.translations[$store.state.lang].yes}` : `${$store.state.translations[$store.state.lang].no}`) : `${$store.state.translations[$store.state.lang].no}`}}
                    </span>
                    </template>
                </v-data-table>
            </span>

        </v-card>

        <v-divider/>
        <evaluationSection :key="$store.state.randomUIKey + 8" :part="$store.state.questions['6']" :thresholds="thresholds"/>
        <v-divider/>

        <v-divider/>
          <!-- <evaluationSection :key="$store.state.randomUIKey + 9" :part="$store.state.questions['4']" :thresholds="thresholds"/> -->

           <!-- <v-card-subtitle>
                   <h6> {{$store.state.translations[$store.state.lang].abnormal}} </h6>
                </v-card-subtitle>
                <v-data-table
                    :key="$store.state.randomUIKey + 10"
                    :headers="abnormalHeaders"
                    :items="this.$store.state.bgp.answers.abnormal"
                    :hide-default-header="false"
                    :hide-default-footer="true"
                    :items-per-page="100"
                     class="elevation-1"
                     mobile-breakpoint="0"
                />
            </span> -->
        <v-divider/>
      </span>
    </v-container>
</template>

<script>
import evaluationSection from './EvaluationSection'
const axios = require('axios')
import consts from './../helpers/Consts'

export default {    
    components: {evaluationSection},
    data () {
        return {
            thresholds: {},
            loading: false,
            assessmentHeaders: [
            {
              text: `${this.$store.state.translations[this.$store.state.lang].animalID}`,
              value: 'AnimalID'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].mobilityScore}`,
              value: 'MobilityScore'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].bcs}`,
              value: 'BCS'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].reumenFill}`,
              value: 'ReumenFill'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].hockScore}`,
              value: 'HockScore'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].numLessions}`,
              value: 'NumberOfLesions'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].hygieneScore}`,
              value: 'HygeneScore'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].faecalScore}`,
              value: 'FaecalScore'
            },
          ],
          actionPointsHeader: [
                {
                    text: this.$store.state.translations[this.$store.state.lang].what ,
                    value: 'What'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].who ,
                    value: 'Who'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].when ,
                    value: 'When'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].result ,
                    value: 'Result'
                },
                  {
                    text: this.$store.state.translations[this.$store.state.lang].bppRelated ,
                    value: 'bppRelated'
                },
            ],
            evaluationPointsHeader: [
                {
                    text: this.$store.state.translations[this.$store.state.lang].what ,
                    value: 'What'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].result ,
                    value: 'Result'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].realised ,
                    value: 'Realised'
                },
                {
                    text: this.$store.state.translations[this.$store.state.lang].explanationEvaluation,
                    value: 'Evaluation'
                },
            ],
            abnormalHeaders: [
            {
              text: this.$store.state.lang == 'En' ? 'Animal ID' : 'Diernummer' ,
              value: 'AnimalID'
            },
            {
              text: this.$store.state.lang == 'En' ? 'General Impression' : 'Algemene indruk',
              value: 'GeneralImpression'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Respiration' : 'Ademhaling',
              value: 'Respiration'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Heart rate' : 'Pols',
              value: 'HeartRate'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Temperature' : 'Temperatuur',
              value: 'Temperature'
            },
            {
              text: this.$store.state.lang == 'En' ? 'BCS' : 'BCS',
              value: 'BCS'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Reumen Fill' : 'Pensvulling',
              value: 'ReumenFill'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Mobility Score' : 'Locomotiescore',
              value: 'MobilityScore'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Excessive discharge present': 'Overmatige uitvloeiing aanwezig',
              value: 'ExcessiveDischarge'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Udder':'Uier',
              value: 'Udder'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Other noticeable clinical abnormalities' : 'Andere in het oog springende klinische afwijkingen',
              value: 'OtherAbnormalities'
            },
            {
              text: this.$store.state.lang == 'En' ? 'Data regarding negative energy balance (NEB) present' : 'Gegevens over negatieve energiebalans (NEB) aanwezig',
              value: 'NEBData'
            },
          ],
          randomUIKey: Math.random()
        }
    },
    computed: {

    },
    methods: {

    },
    created: async function(){

      this.loading = true
      
      try {
        let headers = await this.$store.state.zlogin.getHeaders()
        this.thresholds = await axios.get(`${consts[consts.env].apiURL}/thresholds`, headers)
        console.log(`this thresholds are ${JSON.stringify(this.thresholds, undefined, 2)}`)      
      } catch (e) {
        //console.log(e)
      }

      

      this.loading = false   
      
      this.randomUIKey = Math.random()
    }
}
</script>