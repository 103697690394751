<template>
    <span>
        <span>
        <part v-if="$store.state.bgp.details.Type == 'bgp'" :part="$store.state.questions['2']"/>
        <part v-else :part="$store.state.questions['1002']"/>
    </span>
    </span>
</template>

<script>
import part from '@/components/Part.vue'

export default {
    components: {
        part
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    created: function(){

    }
}
</script>