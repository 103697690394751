<template>
    <v-container ref="container">
        <v-row v-if="!loadedData" justify="center" align="center" class="pa-0 ma-0">
            <v-progress-circular
                color="primary"
                size=100
                indeterminate
            ></v-progress-circular>
        </v-row>
        <v-col cols="12" class="pa-1 ma-0" v-else>
            <v-dialog v-model="$store.state.showCompleteDialog" :width="width" persistent>
                <completeDialog/>
            </v-dialog>

            <v-dialog v-model="$store.state.showErrorDialog" :width="width" persistent :key="$store.state.randomUIKey">
                <errorDialog/>
            </v-dialog>

            <foodSafetyDialog v-if="$store.state.bgp.details.Type=='foodSafety'"/>
            
            <span v-else>
                <v-dialog
                    v-model="$store.state.assessmentDialog"
                    fullscreen hide-overlay transition="dialog-bottom-transition"
                >
                    <assessmentDialog/>
                </v-dialog>

                <v-dialog
                    v-model="$store.state.addInfoDialog"
                    fullscreen hide-overlay transition="dialog-bottom-transition"
                >
                    <addInfoDialog/>
                </v-dialog>

                <v-dialog
                    v-model="$store.state.evaluateDialog"
                    fullscreen hide-overlay transition="dialog-bottom-transition"
                >
                    <evaluationDialog/>
                </v-dialog>

                <v-dialog
                    v-model="$store.state.foodSafetyDialog"
                    fullscreen hide-overlay transition="dialog-bottom-transition"
                >
                    <foodSafetyDialog/>
                </v-dialog>

                <v-row justify="center" v-if="$vuetify.breakpoint.xs">
                    <v-col cols="6">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.addInfoDialog=true; saveAllOnServer()" outlined x-small width=150> {{$store.state.translations[$store.state.lang].additionalInfo}} </v-btn>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.assessmentDialog=true; saveAllOnServer()" outlined x-small width=150> {{$store.state.translations[$store.state.lang].assessment}} </v-btn>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.evaluateDialog=true; saveAllOnServer()" outlined x-small width=150> Evaluatie </v-btn>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="6">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.foodSafetyDialog=true" outlined x-small width=150> Voedselveiligheid </v-btn>
                        </v-row>
                    </v-col> -->
                </v-row>
                <v-row v-else>
                    <v-col cols="4">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.addInfoDialog=true; saveAllOnServer()" outlined > {{$store.state.translations[$store.state.lang].additionalInfo}} </v-btn>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.assessmentDialog=true; saveAllOnServer()" outlined > {{$store.state.translations[$store.state.lang].assessment}} </v-btn>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.evaluateDialog=true; saveAllOnServer()" outlined > Evaluatie </v-btn>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="3">
                        <v-row justify="center">
                            <v-btn color="primary" @click="$store.state.foodSafetyDialog=true" outlined > Voedselveiligheid </v-btn>
                        </v-row>
                    </v-col> -->
                </v-row>
                <v-tabs
                    v-model="tab"
                    background-color="primary"
                    class="elevation-2"
                    dark
                    centered
                >
                <v-tabs-slider></v-tabs-slider>

                <v-tab
                    key="partA"
                    :href="`#tab-partA`"
                    @click="saveAllOnServer"
                > Deel A
                </v-tab>

                <v-tab
                    key="partB"
                    :href="`#tab-partB`"
                    @click="saveAllOnServer"
                > Deel B
                </v-tab>

                <v-tab
                    key="partC"
                    :href="`#tab-partC`"
                    @click="saveAllOnServer"
                > Deel C
                </v-tab>

                <v-tab-item
                    key="partA"
                    :value="`tab-partA`"
                > <partA/>
                </v-tab-item>

                <v-tab-item
                    key="partB"
                    :value="`tab-partB`"
                > <partB/>
                </v-tab-item>

                <v-tab-item
                    key="partC"
                    :value="`tab-partC`"
                > <partC/>
                </v-tab-item>
                
                </v-tabs>
            </span>
            
        </v-col> 
    </v-container>
</template>

<script>
import partA from '@/components/PartA.vue'
import partB from '@/components/PartB.vue'
import partC from '@/components/PartC.vue'
import assessmentDialog from '@/components/AssessmentDialog.vue'
import addInfoDialog from '@/components/AddInfoDialog.vue'
import evaluationDialog from '@/components/EvaluationDialog.vue'
import foodSafetyDialog from '@/components/FoodSafetyDialog.vue'
import completeDialog from '@/components/CompleteDialog'
import serverOperations from './../helpers/ServerOperations'
import errorDialog from '@/components/ErrorDialog.vue'
import consts from './../helpers/Consts'

export default {
    mixins: [serverOperations],
    components: {
        'partA': partA,
        'partB': partB,
        'partC': partC,
        'assessmentDialog': assessmentDialog,
        'addInfoDialog': addInfoDialog,
        'evaluationDialog': evaluationDialog,
        'foodSafetyDialog': foodSafetyDialog,
        completeDialog,
        errorDialog,
    }, 
    data () {
        return {
            loadedData: false,
            tab: '',
            width: 200,
        }
    },
    computed: {

    },
    methods: {
        saveAllOnServer: async function(){
            this.loadedData = false
            await this.saveAll()
            this.loadedData = true
        },
        setWindowWidth: function(){
            try {
                this.width = this.$refs.container.clientWidth < 450 ? this.$refs.container.clientWidth : 450
                this.signWidth = this.width
                if(!this.$store.state.setSignWidth){
                    this.$store.state.signWidth = this.width
                    this.$store.state.setSignWidth = true
                }
                
            } catch (e){
                //console.log(e)
                return 250
            }
            //console.log(`window width is ${this.width}`)
        },
        getDetailsForSubSection(pID){
            console.log(`questions is ${JSON.stringify(this.$store.state.questions, undefined,2)}`)
            for(let p in this.$store.state.questions){
                for(let s in this.$store.state.questions[p].sections){
                    for(let ss in this.$store.state.questions[p].sections[s]){
                        if(this.$store.state.questions[p].sections[s].subSections[ss].subSectionDetails.SubsectionSectionAppID == pID){
                            return {

                            }
                        }
                    }
                }
            }
        }
    },
    created: async function(){
        try {
            // this.getDetailsForSubSection(1)
            // // populate valid forms - need to do this to validate forms that user's haven't opened
            if(this.$store.state.bgp.details.Type == consts.bgpDairyKey){
                this.$store.state.bgpSubSectionIDs.forEach((id) => {
                    this.$store.state.validForms[id.toString()] = {
                        valid: false
                    }
                })
            } else if (this.$store.state.bgp.details.Type == consts.bgpFoodSafetyKey){
                this.$store.state.foodSafetySubSectionIDs.forEach((id) => {
                    this.$store.state.validForms[id.toString()] = {
                        valid: false
                    }
                })
            } else if (this.$store.state.bgp.details.Type == consts.bgpBeefKey){
                this.$store.state.bgpBeefSubSectionIDs.forEach((id) => {
                    this.$store.state.validForms[id.toString()] = {
                        valid: false
                    }
                })
            }
            
            ////console.log(`store bgp is ${JSON.stringify(this.$store.state.bgp)}`)
            if(this.$store.state.bgp.UUID == null){
                this.$router.push('/')
            }

            ////console.log(`questions in store is ${JSON.stringify(this.$store.state.questions)}`)
        } catch (e) {
            //console.log(e)
        }
        this.loadedData = true
    },
    beforeCreate: function(){
        //this.$store.state.title = 'Nieuw BGP ' + (this.$store.state.bgp.farm.farmName ? '(' + this.$store.state.bgp.farm.farmName.name + ')': '')
        
    },
     mounted:function() {
        window.addEventListener('resize', this.setWindowWidth)
        this.setWindowWidth()
    },
    beforeDestroy: async function(){
        await this.$store.dispatch('resetBGP')
    }
}
</script>