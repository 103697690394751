<template>
    <v-row justify="center">

         <v-col cols="12" align="center" justify="center" class="fill-height">
            <v-row justify="center">
                <h2> {{$store.state.translations[$store.state.lang].reportingLogin}} </h2>
            </v-row>
            <v-row justify="center" v-if="!showReportLogin">
                <v-btn text x-large color="primary" @click="showReportLogin=!showReportLogin">Login</v-btn>
            </v-row>
            <!-- <v-row justify="center">
                <v-btn text color="primary" @click="testAPIEndpoint()">Test API Endpoint</v-btn>
            </v-row> -->
        </v-col>
        <v-col cols="12" v-if="showReportLogin">
            <v-row justify="center" v-if="error">
                <p class="errorText"> {{$store.state.translations[$store.state.lang].errorLoggingIn}} </p>
            </v-row>
            <v-row justify="center">
                <v-col cols="8">
                    <v-text-field label="E-mail" v-model="email"/>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="8">
                    <v-text-field @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" :label="$store.state.translations[$store.state.lang].password" v-model="password"/>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-btn color="primary" text @click="login()"> {{$store.state.translations[$store.state.lang].login}}
                    <v-icon>login</v-icon>
                </v-btn>
            </v-row>
        </v-col>


    </v-row>
</template>

<script>
const axios = require('axios')
import consts from './../helpers/Consts'

export default {
  
    data () {
        return {
            error: false,
            showReportLogin: true,
            email: '',
            password: '',
            show: false,
        }
    },
    computed: {

    },
    methods: {
        testAPIEndpoint: async function(){
            try {
                let headers = await this.$store.state.zlogin.getHeaders()
                let result = await axios.get( consts[consts.env].apiURL + '/testAuth', headers)
                ////console.log(`result is ${JSON.stringify(result, undefined, 2)}`)
            } catch (e) {
                ////console.log(e)
            }
        },
        login: async function(){
            try {
                let user = await axios.post('/login', {
                    'email': this.email,
                    'password': this.password
                })
                this.$store.state.reportLoggedIn = true
                this.$store.state.reportUser = user
                
                this.error = false
                this.$router.push('/reportHome')

            } catch (e){
                ////console.log(e)
                this.$store.state.reportLoggedIn = false
                this.error = true
            }
        }
    },
    created: function(){

    }
}
</script>