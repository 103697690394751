<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-toolbar-title>
        <v-img
          alt="SGD Logo"
          class="logo pa-2"
          :src="logo"
          transition="scale-transition"
          max-height="60"
          max-width="150"
          @click="$router.push('/');"
        />
      </v-toolbar-title>

      <v-snackbar
        v-model="$store.state.tooltip.show"
      >
        {{ $store.state.tooltip.text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="purple"
            text
            v-bind="attrs"
            @click="$store.state.tooltip.show = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-spacer></v-spacer>
      <span class="whiteText" :key="$store.state.randomUIKey"> {{$store.state.title}} </span>
      <v-menu
        right
        bottom
        v-if="$route.name != 'Login' && $store.state.reportLoggedIn"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>

          <v-divider/>
            <v-list-item @click="
              $router.push('/reportUserSettings')
            ">
             <v-list-item-icon>
              <v-icon>settings</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{$store.state.translations[$store.state.lang].settings}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider/>

          <v-list-item @click="
            $store.state.reportLoggedIn=false;
            $store.state.title=''; 
            $router.push('/reportLogin'
            )">
             <v-list-item-icon>
              <v-icon>logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$store.state.translations[$store.state.lang].logout}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
         
        </v-list>
      </v-menu>
       <v-menu
        right
        bottom
        v-if="$route.name != 'Login' && !$store.state.reportLoggedIn"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>

          <v-list-item @click="$router.push('/')">
             <v-list-item-icon>
              <v-icon>home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$store.state.translations[$store.state.lang].home}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider/>
            <v-list-item @click="
              $router.push('/settings')
            ">
             <v-list-item-icon>
              <v-icon>settings</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{$store.state.translations[$store.state.lang].settings}} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider/>

          <v-list-item 
            two-line
            @click="
            $store.state.zlogin.logout()
            $store.state.loggedIn=false;
            $store.state.title=''; 
            $router.push('/login'
            )">
             <v-list-item-icon>
              <v-icon>logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$store.state.translations[$store.state.lang].logout}}</v-list-item-title>
              <v-list-item-subtitle>UDN: {{$store.state.user.UDN}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
         
        </v-list>
      </v-menu>

    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        color="primary"
        absolute
        dark
      >
        <v-list
          dense
          nav
          class="py-0"
        >
          <v-list-item>
             <v-list-item-icon>
              <v-icon>Home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

             <v-list-item>
             <v-list-item-icon>
              <v-icon>BGP</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>plan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-navigation-drawer>

    <v-content>
      <v-snackbar 
        v-model="$store.state.showSnackBar"
        timeout=4000
        :color="$store.state.snackBarColour">
          {{$store.state.snackBarText}}
      </v-snackbar>
      <router-view></router-view>
    </v-content>

    <bottomNavigation/>

  </v-app>
</template>

<script>

import bottomNavigation from '@/components/BottomNavigation'
import serverOperations from './helpers/ServerOperations'

export default {
  name: 'App',
  mixins: [serverOperations],
  components: {
    bottomNavigation,
  },

  data: () => ({
    logo: require('./assets/sgdLogo.jpg'),
    drawer: false,
  }),
};
</script>

<style>
.v-navigation-drawer {
  z-index: 999999 !important;
}
.logo {
  width: 120px;
}
</style>
