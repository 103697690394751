<template>
     <v-card :key="randomUIKey">
        <v-toolbar dark color="primary" class="stickyTop">
          <v-btn icon dark @click="$store.state.assessmentDialog = false; saveAll()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{$store.state.translations[$store.state.lang].assessment}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text >Save</v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-row class="pa-5">
          <h5> Het uitvoeren van een klinische inspectie van de lacterende koeien op basis van een a-selecte steekproef. Handvat is 10% van de koppel met min 5 max. 10 dieren.</h5>
        </v-row>
        <v-col>
          <v-row justify="center" class="stickyBelowTop">
            <!-- <h3> Cow Assessment </h3> -->
            <span v-for="item in $store.state.bgp.answers.cowAssessment"
              :key="`${item ? item.AnimalID : Math.random()}`"
            >
              <v-chip 
                color="primary"
                pill
                v-if="item.AnimalID.length>0"
                class="pa-2"
              >
                {{item.AnimalID}}
              </v-chip>
            </span>
          </v-row>
          <v-row justify="center">
             <v-data-table
                :headers="headers"
                :items="$store.state.bgp.answers.cowAssessment"
              >

               <template v-slot:item.AnimalID="{item}">
                      <v-text-field
                        v-model="item.AnimalID"
                        single-line
                        @change="updateNumberOfAssessed(); $store.state.madeBGPChanges = true;"
                      ></v-text-field>
                </template>

                <template v-slot:item.MobilityScore="{item}">
                  <v-select
                    v-model="item.MobilityScore"
                    :items="numericDropDownValues"
                    :disabled="item.AnimalID.length==0"
                    @change="$store.state.madeBGPChanges = true"
                  />
                </template>

                <template v-slot:item.BCS="{item}">
                  <v-select
                    v-model="item.BCS"
                    :items="bcsDropDownValues"
                    :disabled="item.AnimalID.length==0"
                    @change="$store.state.madeBGPChanges = true"
                  />
                </template>

                <template v-slot:item.ReumenFill="{item}">
                  <v-select
                    v-model="item.ReumenFill"
                    :items="numericDropDownValues"
                    :disabled="item.AnimalID.length==0"
                    @change="$store.state.madeBGPChanges = true"
                  />
                </template>

                <template v-slot:item.HockScore="{item}">
                  <v-select
                    v-model="item.HockScore"
                    :items="numericDropDownValues"
                    :disabled="item.AnimalID.length==0"
                    @change="updateMostCommonHockScore(); $store.state.madeBGPChanges = true"
                  />
                </template>

                 <template v-slot:item.NumberOfLesions="{item}">
                   <v-text-field
                        v-model="item.NumberOfLesions"
                        single-line
                        number
                        :disabled="item.AnimalID.length==0"
                        @change="$store.state.madeBGPChanges = true"
                      ></v-text-field>
                </template>

                 <template v-slot:item.HygeneScore="{item}">
                  <v-select
                    v-model="item.HygeneScore"
                    :items="numericDropDownValues"
                    :disabled="item.AnimalID.length==0"
                    @change="updateMostCommonHygieneScore(); $store.state.madeBGPChanges = true"
                  />
                </template>

                 <template v-slot:item.FaecalScore="{item}">
                  <v-select
                    v-model="item.FaecalScore"
                    :items="numericDropDownValues"
                    :disabled="item.AnimalID.length==0"
                    @change="$store.state.madeBGPChanges = true"
                  />
                </template>
             </v-data-table>

          </v-row>

          <v-btn @click="addItem()" fab color="primary" :disabled="this.$store.state.bgp.answers.cowAssessment[this.$store.state.bgp.answers.cowAssessment.length-1].AnimalID.length == 0">
            <v-icon>add</v-icon>
          </v-btn>

          <v-divider/>

          <part :part="$store.state.questions['7']"/>

        </v-col>
      </v-card>
</template>

<script>
import part from '@/components/Part.vue'
import serverOperations from './../helpers/ServerOperations'
const _ = require('lodash')

export default {
    mixins: [serverOperations],
    components: {
      part
    },
    data () {
        return {
          randomUIKey: 0,
          bcsDropDownValues: [1,1.5,2,2.5,3,3.5,4,4.5,5],
          numericDropDownValues: [1,2,3,4,5],
          localCowAssessment: [],
          headers: [
            {
              text: `${this.$store.state.translations[this.$store.state.lang].animalID}`,
              value: 'AnimalID'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].mobilityScore}`,
              value: 'MobilityScore'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].bcs}`,
              value: 'BCS'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].reumenFill}`,
              value: 'ReumenFill'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].hockScore}`,
              value: 'HockScore'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].numLessions}`,
              value: 'NumberOfLesions'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].hygieneScore}`,
              value: 'HygeneScore'
            },
            {
              text: `${this.$store.state.translations[this.$store.state.lang].faecalScore}`,
              value: 'FaecalScore'
            },
          ]
        }
    },
    computed: {

    },
    methods: {
      updateNumberOfAssessed: function(){
        //console.log(`updating number of assessed to ${this.$store.state.bgp.answers.cowAssessment.length.toString()}`)
        this.$store.state.bgp.answers["195"].Value = this.$store.state.bgp.answers.cowAssessment.length.toString()
        this.randomUIKey = Math.random()
      },
      getModeValueFromAssessment: function(pKey){
        let allVals = this.$store.state.bgp.answers.cowAssessment.map((asses)=>{
          return asses[pKey]
        })
        //console.log(`all vals are ${JSON.stringify(allVals, undefined, 2)}`)

        let count = _.countBy(allVals)

        let maxVal = 0
        let maxKey = ''

        for(let key in count){
          if(count[key]>maxVal && key != "0"){
            maxVal = count[key]
            maxKey = key
          }
        }

        //console.log(`count is ${JSON.stringify(count, undefined, 2)} maxVal = ${maxVal} maxKey = ${maxKey}`)

        return maxKey
      },
      updateMostCommonHockScore: function(){
        let mode = this.getModeValueFromAssessment('HockScore')

        //console.log(`question ${JSON.stringify(this.$store.state.questions['7'].sections['17'].subSections['32'], undefined, 2)}`)        

        let hockQuestion = this.$store.state.questions['7'].sections['17'].subSections['32'].questions.find((q)=>{
          return q.AppID == 197
        })

        //console.log(`hock question ${JSON.stringify(hockQuestion, undefined, 2)}`)

        // get ID of option corrosponding to mode value
        let modeOption = hockQuestion.answer.options.find((option)=>{
          return parseInt(option.EnDesc) == mode
        })

        //console.log(`mode option is ${JSON.stringify(modeOption, undefined, 2)}`)

        this.$store.state.bgp.answers["197"].Value = modeOption.AppID.toString()

        //console.log(`answer 197 is ${JSON.stringify(this.$store.state.bgp.answers["197"], undefined, 2)}`)

        this.randomUIKey = Math.random()
      },
      updateMostCommonHygieneScore: function(){
        let mode = this.getModeValueFromAssessment('HygeneScore')

        //console.log(`question ${JSON.stringify(this.$store.state.questions['7'].sections['17'].subSections['32'], undefined, 2)}`)        

        let hygieneQuestion = this.$store.state.questions['7'].sections['17'].subSections['32'].questions.find((q)=>{
          return q.AppID == 196
        })

        //console.log(`hygiene question ${JSON.stringify(hygieneQuestion, undefined, 2)}`)

        // get ID of option corrosponding to mode value
        let modeOption = hygieneQuestion.answer.options.find((option)=>{
          return parseInt(option.EnDesc) == mode
        })

        //console.log(`mode option is ${JSON.stringify(modeOption, undefined, 2)}`)

        this.$store.state.bgp.answers["196"].Value = modeOption.AppID.toString()

        //console.log(`answer 196 is ${JSON.stringify(this.$store.state.bgp.answers["196"], undefined, 2)}`)

        this.randomUIKey = Math.random()
         
      },
      addItem: function(){
        //console.log(`adding item`)
        try{
          if(this.$store.state.bgp.answers.cowAssessment.length == 0 || this.$store.state.bgp.answers.cowAssessment[this.$store.state.bgp.answers.cowAssessment.length-1].AnimalID.length>0){
            this.$store.state.bgp.answers.cowAssessment.push({
              'AnimalID': '',
              'MobilityScore': 0,
              'BCS': 0,
              'ReumenFill': 0,
              'HockScore': 0,
              'NumberOfLesions': 0,
              'HygeneScore': 0,
              'FaecalScore': 0,
            })
          } else {
            //console.log('not pushing')
          }
        } catch (e) {
          //console.log(e)
        }

        this.randomUIKey = Math.random()
      }
    },
    created: function(){  
      //console.log(`cow assessment is ${JSON.stringify(this.$store.state.bgp.answers.cowAssessment, undefined, 2)} lenght is ${this.$store.state.bgp.answers.cowAssessment.length}`)
      if(this.$store.state.bgp.answers.cowAssessment.length==0){
        this.addItem()
      }
      this.randomUIKey = Math.random()
    }
}
</script>