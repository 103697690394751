import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import nl from 'vuetify/es5/locale/nl'
import {store} from './../main'

Vue.use(Vuetify)

const opts = {
    lang: {
        locales: {nl},
        current: store.state.lang == 'Nl' ? 'nl' : 'en'
    },
    theme: {
        themes: {
            light:   {
                primary: '#9c27b0',
                secondary: '#3f51b5',
                accent: '#00bcd4',
                error: '#f44336',
                warning: '#ff5722',
                info: '#4caf50',
                success: '#607d8b'
            }
        }
    }
}

export default new Vuetify(
    opts    )
