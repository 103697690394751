import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueSignaturePad from 'vue-signature-pad';
import consts from './helpers/Consts.js';
import UUID from "vue-uuid";
 
require ('./assets/style.css')

Vue.use(VueSignaturePad)

Vue.use(UUID);

Vue.config.productionTip = false

axios.defaults.baseURL = consts[consts.env].apiURL
axios.interceptors.response.use((body)=>{
  return body.data
})

export {
  store,
  router
}

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('./service-worker.js').then(registration => {
//       //console.log('SW registered: ', registration);
//     }).catch(registrationError => {
//       //console.log('SW registration failed: ', registrationError);
//     });
//   });
// }

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
