<template>
    <v-container ref="container" class="pa-0">

        <v-card class="pa-0">
            <v-card-title> {{$store.state.translations[$store.state.lang].pleaseCheckTheFollowingSections}} </v-card-title>
            <v-divider></v-divider>
            <v-col class="pa-4">
                <span v-for="part in Object.keys($store.state.invalidItems)" :key="Math.random()">
                    <h3> {{part}} </h3>
                    <span v-for="section in Object.keys($store.state.invalidItems[part])" :key="Math.random()">
                        <h4 v-if="section!='null'"> {{section}} </h4>
                
                                <ul>
                                    <li v-for="q in Object.keys($store.state.invalidItems[part][section])" :key="Math.random()">
                                        <p>{{$store.state.invalidItems[part][section][q][`${$store.state.lang}Desc`]}}</p>
                                    </li>
                                </ul>
                            
                    
                    </span>
                </span>
            </v-col>

            <v-card-actions>
                <v-row>
                    <v-col cols="6" class="pa-0">
                        <v-row justify="start">
                            <v-btn text color="error" @click="$store.state.showErrorDialog = false">
                                <v-icon> close </v-icon>
                                <h3>{{$store.state.translations[$store.state.lang].cancel}}</h3>
                            </v-btn>
                        </v-row>
                    </v-col>

                </v-row>
            </v-card-actions>
        </v-card>

        <!-- <vue-html2pdf
            :show-layout="true"
            :enable-download="false"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="getFileName()"
            :pdf-quality="1"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref='html2Pdf'
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)" 
            >
                <section slot="pdf-content">
                        <evaluationTab/> 
                </section>
        </vue-html2pdf> -->
    </v-container>
</template>

<script>
import evaluationTab from './EvaluationTab'
import VueHtml2pdf from 'vue-html2pdf'
const moment = require('moment')
const axios = require('axios')
import serverMixins from './../helpers/ServerOperations'

export default {
    mixins: [serverMixins],
    components: {
        evaluationTab,
        VueHtml2pdf
    },
    data () {
        return {
            errorDetails: []
        }
    },
    computed: {

    },
    methods: {

        setWindowWidth: function(){
            try {
                this.width = this.$refs.container.clientWidth < 450 ? this.$refs.container.clientWidth : 450
            } catch (e){
                //console.log(e)
                this.width = 250
            }
            //console.log(`window width is ${this.width}`)
        },
        
    },
    created: function(){
        //console.log('complete dialog created')
        console.log(`invalid items are ${JSON.stringify(this.$store.state.invalidItems, undefined, 2)}`)
  
    },
    mounted:function() {
        window.addEventListener('resize', this.setWindowWidth)
        this.setWindowWidth()

        try {
            console.log(`showing error dialog`)
        } catch (e) {
            console.log(e)
        }
    },
}
</script>