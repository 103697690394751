<template>
    <v-container>
        <v-col cols="12">
            <v-row justify="center">
                <h2> {{$store.state.translations[$store.state.lang].welcomeToSGDReportingPlatform}} </h2>
            </v-row>
            <v-row justify="center">
                <v-btn
                    @click="$router.push('/reports')"
                    icon
                    color="primary"
                    x-large
                >
                    <v-icon color="primary">chrome_reader_mode</v-icon>
                    {{$store.state.translations[$store.state.lang].allReports}}
                </v-btn>
            </v-row>
              <v-row justify="center">
                <v-btn
                    @click="$router.push('/notifiable')"
                    icon
                    color="primary"
                    x-large
                >
                    <v-icon color="primary">alarm</v-icon>
                    {{$store.state.translations[$store.state.lang].notifiableOfficialChecks}}
                </v-btn>
            </v-row>
             <v-row justify="center">
                <v-btn
                    @click="$router.push('/userManagement')"
                    icon
                    color="primary"
                    x-large
                    v-if="$store.state.reportUser.Admin == 1 || $store.state.reportUser.Admin == true"
                >
                    <v-icon color="primary">supervisor_account</v-icon>
                    {{$store.state.translations[$store.state.lang].addAndEditUsers}}
                </v-btn>
            </v-row>
        </v-col>
        

    </v-container>
</template>

<script>
export default {
    
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    created: function(){

    }
}
</script>