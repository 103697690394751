<template>
    <span>
        <v-progress-circular v-if="saving" size="100px" color="primary">
        </v-progress-circular>
        <v-row justify="center" class="ma-1">
            <v-col cols="12">
                <v-row justify="center">
                    <h4> {{part.partDetails.NLDesc}} </h4>
                </v-row>
                <sections :sections="part.sections"/>
            </v-col>
        </v-row>
    </span>
</template>

<script>
import sections from '@/components/Section.vue'

export default {
    props: ['part'],  
    components: {sections}, 
    data () {
        return {
            saving: false
        }
    },
    computed: {

    },
    methods: {

    },
    created: async function(){
    }
}
</script>