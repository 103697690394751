<template>
     <v-card>
        <!-- <v-toolbar dark color="primary">
          <v-btn icon dark @click="$store.state.foodSafetyDialog = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{$store.state.translations[$store.state.lang].foodSafety}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text >Save</v-btn>
          </v-toolbar-items> -->
        <!-- </v-toolbar>  -->

        <v-tabs
            v-model="tab"
            background-color="primary"
            class="elevation-2"
            dark
            centered
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab
                key="partA"
                :href="`#tab-partA`"
                @click="saveAll()"
            > Inspectie
            </v-tab>

            <v-tab
                key="partB"
                :href="`#tab-partB`"
                @click="saveAll()"
            > {{$store.state.translations[$store.state.lang].abnormal}}
            </v-tab>

            <v-tab-item
                key="partA"
                :value="`tab-partA`"
            > 
              <v-col cols="12" class="pa-0">
                <part :part="$store.state.questions['4']"/>
              </v-col>
            </v-tab-item>

             <v-tab-item
                key="partB"
                :value="`tab-partB`"
            > <abnormal/>
            </v-tab-item>
          
        </v-tabs>

      </v-card>
</template>

<script>
import part from '@/components/Part.vue'
import abnormal from '@/components/PartDAbnormal.vue'
import serverOperations from './../helpers/ServerOperations'

export default {
    mixins: [serverOperations],
    components: {
      part,
      abnormal
    },
    
    data () {
        return {
          tab: ''
        }
    },
    computed: {

    },
    methods: {

    },
    created: function(){

    }
}
</script>