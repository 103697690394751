<template>
     <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="$store.state.evaluateDialog = false; saveAll(); $store.state.randomUIKey = Math.random();">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{$store.state.translations[$store.state.lang].evaluation}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn dark text @click="savePDF" >Save</v-btn> -->
          </v-toolbar-items>
        </v-toolbar>

         <v-tabs
            v-model="tab"
            background-color="primary"
            class="elevation-2"
            dark
            centered
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab
                :key="$store.state.randomUIKey"
                :href="`#tab-performance`"
                @click="saveAll()"
            > {{$store.state.translations[$store.state.lang].performance}}
            </v-tab>

            <v-tab
                key="evaluation"
                :href="`#tab-evaluation`"
                @click="saveAll()"
            > {{$store.state.translations[$store.state.lang].evaluation}}
            </v-tab>

            <v-tab-item
                :key="$store.state.randomUIKey"
                :value="`tab-performance`"
            > 
              <evaluationTab/>
              <!-- <vue-html2pdf
                  :show-layout="false"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="bgp"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"
                  ref='html2Pdf'
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)" 
              >
                  <section slot="pdf-content">
                     <evaluationTab/> 
                  </section>
              </vue-html2pdf> -->
            </v-tab-item>

             <v-tab-item
                key="evaluation"
                :value="`tab-evaluation`"
            > <prevBGPAndActions/>
            </v-tab-item>
          
        </v-tabs>
      </v-card>
</template>

<script>
import prevBGPAndActions from './PrevBGPAndActions'
import evaluationTab from './EvaluationTab'
import VueHtml2pdf from 'vue-html2pdf'
import serverOperations from './../helpers/ServerOperations'

export default {
    mixins: [serverOperations],
    components: {
      prevBGPAndActions,
      evaluationTab,
      VueHtml2pdf
    },
    data () {
        return {
          tab: '',
          randomKey: Math.random()
        }
    },
    computed: {

    },
    methods: {
      savePDF: function(){
        //console.log('saving pdf')
        this.$refs.html2Pdf.generatePdf()
      },
      onProgress: function(event){
        //console.log(`event is ${JSON.stringify(event, undefined, 2)}`)
      },
      hasStartedGeneration: function(){
        //console.log('generating pdf...')
      },
      hasGenerated: function(event){
        //console.log(`event is ${JSON.stringify(event, undefined, 2)}`)
      }
    },
    created: function(){
      //console.log('created evaluation dialog')
    }
}
</script>