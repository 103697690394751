<template>
    <v-row justify="center">
        <!-- <v-col cols="12">
            <v-row justify="center">
                <h1> {{$store.state.translations[$store.state.lang].login}} </h1>
            </v-row>
            <v-row justify="center" v-if="error">
                <p class="errorText"> {{$store.state.translations[$store.state.lang].errorLoggingIn}} </p>
            </v-row>
            <v-row justify="center">
                <v-col cols="8">
                    <v-text-field label="UDN" v-model="udn"/>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="8">
                    <v-text-field label="PIN" v-model="pin"/>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-btn color="primary" text @click="login"> {{$store.state.translations[$store.state.lang].login}}
                    <v-icon>login</v-icon>
                </v-btn>
            </v-row>
        </v-col> -->
        <v-divider/>
        <v-col cols="12" align="center" justify="center" class="fill-height">
            <v-row justify="center" v-if="$store.state.udnError == true && $store.state.firstLogin == false">
                <span class="error"> {{$store.state.translations[$store.state.lang].udnError}} </span>
            </v-row>
            <v-row justify="center">
                <h2> {{$store.state.translations[$store.state.lang].vetLogin}} </h2>
            </v-row>
            <v-row justify="center">
                <v-btn text x-large color="primary" @click="launchZLogin()">Login</v-btn>
            </v-row>
            <!-- <v-row justify="center">
                <v-btn text color="primary" @click="testAPIEndpoint()">Test API Endpoint</v-btn>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<script>
const axios = require('axios')
import consts from './../helpers/Consts'

export default {
  
    data () {
        return {
            pin: '',
            udn: '',
            error: false,
            showReportLogin: false,
            email: '',
            password: '',
            show: false,
        }
    },
    computed: {

    },
    methods: {
        testAPIEndpoint: async function(){
            try {
                let headers = await this.$store.state.zlogin.getHeaders()
                let result = await axios.get( consts[consts.env].apiURL + '/testAuth', headers)
                ////console.log(`result is ${JSON.stringify(result, undefined, 2)}`)
            } catch (e) {
                ////console.log(e)
            }
        },
        launchZLogin: async function(){
            let url = await this.$store.state.zlogin.getLoginURL()
            ////console.log(`launching z loging navigating to ${url}`)
            location.href = url
        },
        login: async function(){
            try {
                let user = await axios.post('/login', {
                    'email': this.email,
                    'password': this.password
                })
                this.$store.state.reportLoggedIn = true
                this.$store.state.reportUser = user
                this.error = false
                this.$router.push('/reportHome')

            } catch (e){
                ////console.log(e)
                this.$store.state.reportLoggedIn = false
                this.error = true
            }
        }
    },
    created: function(){

    }
}
</script>