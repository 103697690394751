const consts = {
    bgpDairyKey: 'bgp',
    bgpBeefKey: 'beefBGP',
    bgpFoodSafetyKey: 'foodSafety',
    //env: 'dev',
    //'env': 'test',
    'env': 'production',
    dev: {
        apiURL: 'https://dev-apigda2-bgp.vetimpress.com',
        appURL: 'https://dev-bgp.vetimpress.com',
        authTokenEndpoint: `https://iam.test.unifiedpost.com/auth/realms/test-ext-geborgdedierenarts/protocol/openid-connect/auth`,
        tokenEndpoint: `https://iam.test.unifiedpost.com/auth/realms/test-ext-geborgdedierenarts/protocol/openid-connect/token`,
        logoutURL: `https://iam.unifiedpost.com/auth/realms/pp-ext-geborgdedierenarts/protocol/openid-connect/logout`
    },
    test: {
        apiURL: 'https://beta-apigda2-bgp.vetimpress.com',
        appURL: 'https://beta-bgp.vetimpress.com',
        authTokenEndpoint: `https://iam.uat.unifiedpost.com/auth/realms/pp-ext-geborgdedierenarts/protocol/openid-connect/auth`,
        tokenEndpoint: `https://iam.uat.unifiedpost.com/auth/realms/pp-ext-geborgdedierenarts/protocol/openid-connect/token`,
        logoutURL: `https://iam.unifiedpost.com/auth/realms/pp-ext-geborgdedierenarts/protocol/openid-connect/logout`
    },

    production: {
        apiURL: 'https://bgpapi1.geborgdedierenarts.nl',
        appURL: 'https://bgp.geborgdedierenarts.nl',
        authTokenEndpoint: `https://iam.unifiedpost.com/auth/realms/ext-geborgdedierenarts/protocol/openid-connect/auth`,
        tokenEndpoint: `https://iam.unifiedpost.com/auth/realms/ext-geborgdedierenarts/protocol/openid-connect/token`,
        logoutURL: `https://iam.unifiedpost.com/auth/realms/ext-geborgdedierenarts/protocol/openid-connect/logout`
    },
    idToken: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJvSjZvS0RhX0ZTV1Q0RkdaOU5IR25rYWVEZENYTmN5TTB2NUVBdXN5UmhZIn0.eyJleHAiOjE2MTA3MDI1MjEsImlhdCI6MTYxMDcwMDcyMSwiYXV0aF90aW1lIjoxNjEwNTU3OTU5LCJqdGkiOiI1N2NiMTgxOS1lYzZhLTRmMWUtOGRkMy1lZTY1Nzg1ZGZkMTUiLCJpc3MiOiJodHRwczovL2lhbS51bmlmaWVkcG9zdC5jb20vYXV0aC9yZWFsbXMvZXh0LWdlYm9yZ2RlZGllcmVuYXJ0cyIsImF1ZCI6ImdlYm9yZ2RlZGllcmVuYXJ0cyIsInN1YiI6IjU2MmZlNjg1LTE3MGEtNDA2My1iYjlhLTNmMmQ4Y2RkMTZjMyIsInR5cCI6IklEIiwiYXpwIjoiZ2Vib3JnZGVkaWVyZW5hcnRzIiwic2Vzc2lvbl9zdGF0ZSI6IjE4OTQ3OGZlLTYxZGQtNDQ5OC1hNmVhLTU1Yjg5NmY1ZTgxYyIsImFjciI6IjAiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6IkRvdXdlIGRlIFphbmdlciIsInpsb2dpbiI6eyJvcmdhbml6YXRpb24iOiJVbmlmaWVkUG9zdCBCLlYuIiwiYWdyb3N3aXRjaGlkIjoiNTAwMDMwMDI4MiIsImVtYWlsIjoiZGptLmRlLnphbmdlckBnbWFpbC5jb20iLCJ1ZG4iOiIxNTA0NDEzIn0sInByZWZlcnJlZF91c2VybmFtZSI6ImRqbWRlemFuZ2VyIiwiZ2l2ZW5fbmFtZSI6IkRvdXdlIiwiZmFtaWx5X25hbWUiOiJkZSBaYW5nZXIiLCJlbWFpbCI6ImRqbS5kZS56YW5nZXJAZ21haWwuY29tIn0.CbdIyL_hNUXvp_kcv9DeFQEoyPm0T38NyexmO6Y7l2Cee8Xp-gmVyBcyo_zh4LCqQGKDmL8vvPm_6zeuF2DgzWh4XOxjLkYF1-nppjqHpJLLU-FOXHG5mg3F7qhnj-8t7GMYtKxXBaGnJm_YtEcRY0LP7FnzDHVtoYME7vnPoqOCYdgNbq9Uba0kY80enEz8apYl-xnatpHQf4xD2HHyUw9qBLNuvn3bAwqh411LvtJLxl0kBktF-jt61gXEyPb_3FuMsIlUMHFEX6hKDGkFsYq6cfSewwyoqXGks7N-cDV45xLlgJHJLKXOKQXZSAg7wfToVoWI3OgAzvWKq9RZag',
    accessToken: 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJvSjZvS0RhX0ZTV1Q0RkdaOU5IR25rYWVEZENYTmN5TTB2NUVBdXN5UmhZIn0.eyJleHAiOjE2MTA3MDI1MjEsImlhdCI6MTYxMDcwMDcyMSwiYXV0aF90aW1lIjoxNjEwNTU3OTU5LCJqdGkiOiI0YThhMjNmMi1mOGU2LTRiNGMtYmFiMS0zOTc2Yzc4ZTJjYzEiLCJpc3MiOiJodHRwczovL2lhbS51bmlmaWVkcG9zdC5jb20vYXV0aC9yZWFsbXMvZXh0LWdlYm9yZ2RlZGllcmVuYXJ0cyIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiI1NjJmZTY4NS0xNzBhLTQwNjMtYmI5YS0zZjJkOGNkZDE2YzMiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJnZWJvcmdkZWRpZXJlbmFydHMiLCJzZXNzaW9uX3N0YXRlIjoiMTg5NDc4ZmUtNjFkZC00NDk4LWE2ZWEtNTViODk2ZjVlODFjIiwiYWNyIjoiMCIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2JncC5nZWJvcmdkZWRpZXJlbmFydHMubmwiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBnZWJvcmdkZWRpZXJlbmFydHMgZW1haWwgcHJvZmlsZSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiRG91d2UgZGUgWmFuZ2VyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZGptZGV6YW5nZXIiLCJnaXZlbl9uYW1lIjoiRG91d2UiLCJmYW1pbHlfbmFtZSI6ImRlIFphbmdlciIsImVtYWlsIjoiZGptLmRlLnphbmdlckBnbWFpbC5jb20ifQ.qJiNNQovFCfU1Cj3y125P8KT3-qMYS0TzyZwb2J9SLJH24r-RMGu_yhGEgVZAYF0JWjY4Q9IRRlRvrRd7ielwTx7ViWIqb33AHrRCOo9i3ddzZwdxAdZFKIx_c_ELhNcit7_FoFRKNgfslsCCZRCI1adRONZwEEPnMpfD2Mgj7RT-sqfZjuZwMrXJe4BUv8wS7LSABnvd50vTW1Kf8ijd-3V6j5R-qe4l_WNdx166rYs9Bn4OfeXCi_U8crCRPfA6B2HvEV4OS0LBZMxqCM_EVTargIWq1S2PbXn9XY9MiPwM_sE6mdh4-S4WfruVn6glHFa72ppVD4vISaGUiSnIw'
}


export default consts