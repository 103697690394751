<template>
    <v-container>
        <v-col cols="12">
            <v-row justify="center">
                <h2> {{$store.state.translations[$store.state.lang].datesAndDocuments}} </h2>
            </v-row>
            <v-row justify="center" v-if="loading">
                <v-progress-circular color="primary" size="100px"></v-progress-circular>
            </v-row>
            <span v-else>
                <v-row justify="center">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3" class="pa-2 ma-0">
                        <v-dialog
                            ref="dialog"
                            v-model="fromDateModal"
                            :return-value.sync="fromDate"
                            persistent
                            width="290px"

                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="fromDate"
                                :label="$store.state.translations[$store.state.lang].fromDate"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="fromDate" scrollable
                                :first-day-of-week="1"    
                            >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="fromDateModal = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.dialog.save(fromDate); fromDateModal = false">OK</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3" class="pa-2 ma-0">
                        <v-dialog
                            ref="todialog"
                            v-model="toDateModal"
                            :return-value.sync="toDate"
                            persistent
                            width="290px"

                        >
                            <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="toDate"
                                :label="$store.state.translations[$store.state.lang].toDate"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="toDate" scrollable
                                :first-day-of-week="1"    
                            >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="toDateModal = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.todialog.save(toDate); toDateModal = false">OK</v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3" class="pa-2 ma-0">
                        <v-select
                            :items="dateSelection"
                            v-model="dateToFilterOn"
                            :label="$store.state.translations[$store.state.lang].using"
                        ></v-select>
                    </v-col>

                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3" class="pa-2 ma-0">
                        <v-select
                            :items="typeSelection"
                            v-model="type"
                            :label="$store.state.translations[$store.state.lang].type"
                        ></v-select>
                    </v-col>

                     <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3" class="pa-2 ma-0">
                        <v-switch
                            v-model="includeDraft"
                            :label="$store.state.translations[$store.state.lang].includeDraft"
                        ></v-switch>
                    </v-col>

                </v-row>
                <v-row justify="center">

                </v-row>
                <v-row justify="center">
                    <v-btn
                        @click="getReportData"
                        icon
                        color="primary"
                        x-large
                    >
                        {{$store.state.translations[$store.state.lang].search}}
                        <v-icon color="primary">search</v-icon>
                    </v-btn>
                </v-row>
                <v-divider/>
                <v-row>
                    <v-col cols="12">
                        <v-card-title>
                            <v-row>
                                <v-col cols="10" class="pa-0">
                                    <v-text-field
                                        v-model="search"
                                        append-icon="search"
                                        :label="$store.state.translations[$store.state.lang].searchWithinResults"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" class="pa-0">
                                    <v-row justify="center" align="center">
                                        <v-btn
                                            @click="getReportData()"
                                            color="primary"
                                            icon
                                        >
                                            <v-icon color="primary">refresh</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-data-table
                            :headers="headers"
                            :items="tableData()"
                            :search="search"
                            calculate-widths
                            mobile-breakpoint="0"
                            >
                            <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                Your search for "{{ search }}" found no results.
                                </v-alert>
                            </template>

                            <template v-slot:item.UBN="{ item }">
                                <p class="small"> {{item.UBN}} </p>
                            </template>

                            <template v-slot:item.dateReq="{ item }">
                                <span v-if="item.dateReq != null">
                                    {{moment(item.dateReq).format("DD/MM/YYYY")}}
                                </span>
                            </template>

                            <template v-slot:item.dateComplete="{ item }">
                                <span v-if="item.dateComplete != null">
                                    {{moment(item.dateComplete).format("DD/MM/YYYY")}}
                                </span>
                            </template>

                            <template v-slot:item.overdue="{ item }">
                                <span v-bind:class="item.overdue<0 ? 'red--text' : 'green--text'">{{item.overdue}}</span>
                            </template>

                            <template v-slot:item.reportLink="{ item }">
                                <v-btn 
                                    text color="primary" small 
                                    @click="downloadFile(item.reportLink)"
                                    v-if="item.reportLink != null"
                                >
                                    {{$store.state.translations[$store.state.lang].download}}
                                </v-btn>
                            </template>

                            <!-- <template v-slot:item.farm="{ item }">
                                <p> {{item.farm}} </p>
                            </template>

                            <template v-slot:item.actions="{ item }">

                                <span v-for="form in item.completeBGP" :key="form.ID">
                                <v-col>

                                    <v-btn 
                                            text color="primary" x-small 
                                            @click="downloadFile(form.BGPPDF)"
                                            v-if="form.BGPPDF != null && form.BGPPDF.length>0"
                                        >
                                            BGP ({{moment(form.Completed).format("YYYY-MM-DD")}}) 
                                        </v-btn>
                                </v-col>
                                </span>
                                <span v-for="form in item.completeFoodSafety" :key="form.ID">
                                    <v-col>
                                

                                        <v-btn 
                                            text color="primary" x-small 
                                            @click="downloadFile(form.FoodSafetyPDF)"
                                            v-if="form.FoodSafetyPDF != null && form.FoodSafetyPDF.length>0"
                                        >
                                            {{$store.state.translations[$store.state.lang].foodSafety}} ({{moment(form.Completed).format("YYYY-MM-DD")}}) 
                                        </v-btn>

                                </v-col>
                                
                                </span>

                                <v-divider/>

                                <span v-if="item.offlineBGP.length>0">
                                    <span v-for="bgp in item.offlineBGP" v-bind:key="bgp.ID">
                                        <h4> {{item.offlineBGP.length}} items die wachten op synchronisatie </h4>
                                    </span>
                                </span>

                                <br>

                                <v-row justify="center">
                                    <v-btn class="ma-2" v-if="item.bgp" @click="resumeBGP(item, 'bgp')" color="info" outlined x-small>
                                        {{$store.state.translations[$store.state.lang].resumeBGP}}   
                                    </v-btn>
                                    <v-btn class="ma-2" v-else @click="newBGP(item, 'bgp')" color="primary" outlined x-small>
                                        {{$store.state.translations[$store.state.lang].newBGP}}   
                                    </v-btn>

                                    <v-btn class="ma-2" v-if="item.foodSafety" @click="resumeBGP(item, 'foodSafety')" color="info" outlined x-small>
                                        {{$store.state.translations[$store.state.lang].resumeFoodSafety}}   
                                    </v-btn>
                                    <v-btn class="ma-2" v-else @click="newBGP(item, 'foodSafety')" color="primary" outlined x-small>
                                        {{$store.state.translations[$store.state.lang].newFoodSafety}}   
                                    </v-btn>


                                </v-row> -->

                                <!-- <br>
                            </template> -->

                        </v-data-table>
                    </v-col>

                </v-row>
            </span>
            
        </v-col>
    </v-container>
</template>

<script>
const axios = require('axios')
import consts from './../helpers/Consts'
const moment = require('moment')

export default {
    
    data () {
        return {
            includeDraft: false,
            dateSelection: [
                {
                    'text': this.$store.state.translations[this.$store.state.lang].dateReq,
                    'value': 'Expires'
                },
                {
                    'text': this.$store.state.translations[this.$store.state.lang].dateComplete,
                    'value': 'Completed'
                },
                {
                    'text': this.$store.state.translations[this.$store.state.lang].anyDate,
                    'value': 'Any'
                },
            ],
            typeSelection: [
                {
                    'text': this.$store.state.translations[this.$store.state.lang].bgp,
                    'value': 'bgp'
                },
                {
                    'text': this.$store.state.translations[this.$store.state.lang].foodSafety,
                    'value': 'foodSafety'
                },
            ],
            loading: false,
            reportData: [],
            fromDate: moment().subtract(1, 'year').format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
            type: 'foodSafety',
            dateToFilterOn: 'Completed',
            search: '',
            fromDateModal: false,
            toDateModal: false,
            headers: [
                { text: this.$store.state.translations[this.$store.state.lang].farmUBN, 
                    value: 'UBN',
                    //width: 10 
                },
                 { text: this.$store.state.translations[this.$store.state.lang].vetUDN, 
                    value: 'UDN',
                    //width: 10 
                },
                 { text: this.$store.state.translations[this.$store.state.lang].dateReq, 
                    value: 'dateReq',
                    //width: 10 
                },
                 { text: this.$store.state.translations[this.$store.state.lang].dateComplete, 
                    value: 'dateComplete',
                    //width: 10 
                },                
                 { text: this.$store.state.translations[this.$store.state.lang].overdue, 
                    value: 'overdue',
                    //width: 10 
                },
                 { text: this.$store.state.translations[this.$store.state.lang].reportLink, 
                    value: 'reportLink',
                    //width: 10 
                },
            ]
        }
    },
    computed: {

    },
    methods: {
        downloadFile: async function(fileName){

            axios({
                url: `${consts[consts.env].apiURL}/downloadFileReportUser/${fileName}`,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    email: this.$store.state.reportUser.Email,
                    password: this.$store.state.reportUser.Password 
                }
            }).then((response)=>{
                ////console.log(`response is ${JSON.stringify(response, undefined, 2)}`)
                var blob = new Blob([response]);

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            }).catch ((e)=>{
                //console.log(e)
            })          
        },
        moment: function(pDate){
            return moment(pDate)
        },
        tableData: function(){
            let res = []

            this.reportData.forEach((report)=>{
                res.push({
                    UBN: report.UBN,
                    UDN: report.UDN,
                    dateReq: report.Expires != null ? report.Expires : null,
                    dateComplete: report.Completed != null ? report.Completed : null,
                    overdue: report.Expires != null ? moment(report.Expires).diff(moment(), 'days') : null,
                    reportLink: report.Completed != null ? (report.Type == 'bgp' ? report.BGPPDF : report.FoodSafetyPDF) : null
                })
            })

            //console.log(`res is ${JSON.stringify(res, undefined, 2)}`)
            
            return res
        },
        getReportData: async function(){
            try {
                this.loading = true
                this.reportData = await axios.post(consts[consts.env].apiURL + '/report',{
                    'fromDate': this.fromDate,
                    'toDate': this.toDate,
                    'type': this.type,
                    'dateToFilterOn': this.dateToFilterOn,
                    'includeDraft': this.includeDraft
                }, {
                    headers: {
                        email: this.$store.state.reportUser.Email,
                        password: this.$store.state.reportUser.Password
                    }
                })
                //console.log(`reportData is ${JSON.stringify(this.reportData, undefined, 2)}`)
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        }

    },
    created: async function(){

    }
}
</script>