var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loading)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('span',[_c('v-col',{key:_vm.uiKey},[_c('p',{staticClass:"smallQuestionText"},[_vm._v(" "+_vm._s(_vm.$store.state.questions['5'].sections['14'].subSections['29'].questions[0][((_vm.$store.state.lang) + "Desc")])+" ")]),_c('v-textarea',{attrs:{"id":'183',"required":"","rows":"3","auto-grow":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(_vm.$store.state.bgp.answers['183'].Value),callback:function ($$v) {_vm.$set(_vm.$store.state.bgp.answers['183'], "Value", $$v)},expression:"$store.state.bgp.answers['183'].Value"}}),_c('v-divider'),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.state.questions['5'].sections['15'].sectionDetails[((_vm.$store.state.lang) + "Desc")])+" ")]),_c('v-data-table',{attrs:{"headers":_vm.evaluationPointsHeader,"items":_vm.$store.state.bgp.evaluationPoints,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.What",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":_vm.$store.state.translations[_vm.$store.state.lang].what,"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true;}},model:{value:(item.What),callback:function ($$v) {_vm.$set(item, "What", $$v)},expression:"item.What"}})]}},{key:"item.Result",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":_vm.$store.state.translations[_vm.$store.state.lang].result,"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true;}},model:{value:(item.Result),callback:function ($$v) {_vm.$set(item, "Result", $$v)},expression:"item.Result"}})]}},{key:"item.Evaluation",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":_vm.$store.state.translations[_vm.$store.state.lang].explanationEvaluation,"rows":5},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true;}},model:{value:(item.Evaluation),callback:function ($$v) {_vm.$set(item, "Evaluation", $$v)},expression:"item.Evaluation"}})]}},{key:"item.Realised",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('p',{staticClass:"smallQuestionText"},[_vm._v(" "+_vm._s(_vm.$store.state.translations[_vm.$store.state.lang].realised)+" ")]),_c('v-radio-group',{model:{value:(item.Realised),callback:function ($$v) {_vm.$set(item, "Realised", $$v)},expression:"item.Realised"}},_vm._l((_vm.realisedOptions),function(option){return _c('v-radio',{key:option.EnDesc,attrs:{"color":"primary","label":("" + (option[_vm.$store.state.lang+'Desc'])),"value":option.NlDesc},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true;}}})}),1)],1)]}}])}),_c('v-btn',{attrs:{"fab":"","color":"primary"},on:{"click":function($event){return _vm.addEvaluationPoint()}}},[_c('v-icon',[_vm._v("add")])],1),_c('br'),_c('br'),_c('br'),_c('v-divider'),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.state.questions['5'].sections['16'].sectionDetails[((_vm.$store.state.lang) + "Desc")])+" ")]),_c('v-data-table',{attrs:{"headers":_vm.actionPointsHeader,"items":_vm.$store.state.bgp.actionPoints,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.What",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":_vm.$store.state.translations[_vm.$store.state.lang].what,"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true;}},model:{value:(item.What),callback:function ($$v) {_vm.$set(item, "What", $$v)},expression:"item.What"}})]}},{key:"item.Who",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":_vm.$store.state.translations[_vm.$store.state.lang].who,"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.Who),callback:function ($$v) {_vm.$set(item, "Who", $$v)},expression:"item.Who"}})]}},{key:"item.When",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{key:item.TempID != null ? item.TempID : item.UUID,ref:("ref" + (item.TempID != null ? item.TempID : item.UUID)),attrs:{"return-value":item.When,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "When", $event)},"update:return-value":function($event){return _vm.$set(item, "When", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({key:item.TempID != null ? item.TempID : item.UUID,attrs:{"label":_vm.$store.state.translations[_vm.$store.state.lang].date,"prepend-icon":"event","readonly":""},model:{value:(item.When),callback:function ($$v) {_vm.$set(item, "When", $$v)},expression:"item.When"}},on))]}}],null,true),model:{value:(_vm.dateModal[item.TempID != null ? item.TempID : item.UUID]),callback:function ($$v) {_vm.$set(_vm.dateModal, item.TempID != null ? item.TempID : item.UUID, $$v)},expression:"dateModal[item.TempID != null ? item.TempID : item.UUID]"}},[_c('v-date-picker',{key:item.TempID != null ? item.TempID : item.UUID,attrs:{"scrollable":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.When),callback:function ($$v) {_vm.$set(item, "When", $$v)},expression:"item.When"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateModal[item.TempID != null ? item.TempID : item.UUID]=false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs[("ref" + (item.TempID != null ? item.TempID : item.UUID))].save(item.When)}}},[_vm._v("OK")])],1)],1)]}},{key:"item.Result",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":_vm.$store.state.translations[_vm.$store.state.lang].result,"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.Result),callback:function ($$v) {_vm.$set(item, "Result", $$v)},expression:"item.Result"}})]}},{key:"item.bppRelated",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"required":"","label":item.bppRelated ? (item.bppRelated == 1 ? ("" + (_vm.$store.state.translations[_vm.$store.state.lang].yes)) : ("" + (_vm.$store.state.translations[_vm.$store.state.lang].no))) : ("" + (_vm.$store.state.translations[_vm.$store.state.lang].no))},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true;}},model:{value:(item.bppRelated),callback:function ($$v) {_vm.$set(item, "bppRelated", $$v)},expression:"item.bppRelated"}})]}}])}),_c('v-btn',{attrs:{"fab":"","color":"primary"},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }