var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{key:_vm.randomUIKey},[_c('v-toolbar',{staticClass:"stickyTop",attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.$store.state.assessmentDialog = false; _vm.saveAll()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.state.translations[_vm.$store.state.lang].assessment))]),_c('v-spacer')],1),_c('v-row',{staticClass:"pa-5"},[_c('h5',[_vm._v(" Het uitvoeren van een klinische inspectie van de lacterende koeien op basis van een a-selecte steekproef. Handvat is 10% van de koppel met min 5 max. 10 dieren.")])]),_c('v-col',[_c('v-row',{staticClass:"stickyBelowTop",attrs:{"justify":"center"}},_vm._l((_vm.$store.state.bgp.answers.cowAssessment),function(item){return _c('span',{key:("" + (item ? item.AnimalID : Math.random()))},[(item.AnimalID.length>0)?_c('v-chip',{staticClass:"pa-2",attrs:{"color":"primary","pill":""}},[_vm._v(" "+_vm._s(item.AnimalID)+" ")]):_vm._e()],1)}),0),_c('v-row',{attrs:{"justify":"center"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.state.bgp.answers.cowAssessment},scopedSlots:_vm._u([{key:"item.AnimalID",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"single-line":""},on:{"change":function($event){_vm.updateNumberOfAssessed(); _vm.$store.state.madeBGPChanges = true;}},model:{value:(item.AnimalID),callback:function ($$v) {_vm.$set(item, "AnimalID", $$v)},expression:"item.AnimalID"}})]}},{key:"item.MobilityScore",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.MobilityScore),callback:function ($$v) {_vm.$set(item, "MobilityScore", $$v)},expression:"item.MobilityScore"}})]}},{key:"item.BCS",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.bcsDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.BCS),callback:function ($$v) {_vm.$set(item, "BCS", $$v)},expression:"item.BCS"}})]}},{key:"item.ReumenFill",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.ReumenFill),callback:function ($$v) {_vm.$set(item, "ReumenFill", $$v)},expression:"item.ReumenFill"}})]}},{key:"item.HockScore",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.updateMostCommonHockScore(); _vm.$store.state.madeBGPChanges = true}},model:{value:(item.HockScore),callback:function ($$v) {_vm.$set(item, "HockScore", $$v)},expression:"item.HockScore"}})]}},{key:"item.NumberOfLesions",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"single-line":"","number":"","disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.NumberOfLesions),callback:function ($$v) {_vm.$set(item, "NumberOfLesions", $$v)},expression:"item.NumberOfLesions"}})]}},{key:"item.HygeneScore",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.updateMostCommonHygieneScore(); _vm.$store.state.madeBGPChanges = true}},model:{value:(item.HygeneScore),callback:function ($$v) {_vm.$set(item, "HygeneScore", $$v)},expression:"item.HygeneScore"}})]}},{key:"item.FaecalScore",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.FaecalScore),callback:function ($$v) {_vm.$set(item, "FaecalScore", $$v)},expression:"item.FaecalScore"}})]}}])})],1),_c('v-btn',{attrs:{"fab":"","color":"primary","disabled":this.$store.state.bgp.answers.cowAssessment[this.$store.state.bgp.answers.cowAssessment.length-1].AnimalID.length == 0},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',[_vm._v("add")])],1),_c('v-divider'),_c('part',{attrs:{"part":_vm.$store.state.questions['7']}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }