<template>
      <v-bottom-navigation
            v-if="$route.name=='BGP'"
            app
            fixed
        >
        <v-col cols="12" class="pa-0">
            <v-row justify="center">
                    <v-progress-linear
                        v-model="$store.state.progress"
                        color="primary"
                        ></v-progress-linear>
                    <!-- <p> ({{$store.state.answeredQuestions}}/{{$store.state.numberOfHighLevelQuestions}}) </p> -->
            </v-row>
            <v-row>
                <v-col cols="4" class="pa-2">
                    <v-row justify="center" class="pa-0">
                        <v-btn color="error" 
                            @click="$store.state.finishedBGP = false; $store.state.madeBGPChanges = false; $router.go(-1)"
                            small
                            class="pa-1"
                            text
                        >
                        {{$store.state.translations[$store.state.lang].cancel}}
                        <v-icon color="error">cancel</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>

                <v-col cols="4" class="pa-2">
                    <v-row justify="center" class="pa-0">
                        <v-btn color="info"
                            small text
                            @click="setComplete"
                        >
                            {{$store.state.translations[$store.state.lang].complete}}
                            <v-icon color="info">done</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>

                <!-- <v-col cols="4" class="pa-2" v-else>
                    <v-row justify="center" class="pa-0">
                        <v-btn color="info"
                            small text
                            @click="exportBGP"
                        >
                            {{$store.state.translations[$store.state.lang].export}}
                            <v-icon color="blue">import_export</v-icon>
                        </v-btn>
                    </v-row>
                </v-col> -->

                <v-col cols="4" class="pa-2">
                    <v-row justify="center" class="pa-0" v-if="!saving">
                        <v-btn color="primary"
                                @click="save" small dark text
                                :disabled="! $store.state.madeBGPChanges"
                            >
                            {{$store.state.translations[$store.state.lang].save}}
                            <v-icon color="primary" dark>save</v-icon>
                        </v-btn>
                    </v-row>
                    <v-row v-else class="pa-0" justify="center">
                        <v-progress-circular color="primary" indeterminate></v-progress-circular>
                    </v-row>
                </v-col>
            </v-row>
            <br><br><br><br>

        </v-col>
        <!-- <v-col cols="4">
        <v-row justify="center">
            <v-btn icon>
            <v-icon>check</v-icon>
            </v-btn>
        </v-row>
        </v-col> -->
    </v-bottom-navigation>
</template>

<script>
const axios = require('axios')
const _ = require('lodash')

import serverMixins from './../helpers/ServerOperations'
import consts from './../helpers/Consts'

export default {
    mixins: [serverMixins],
    data () {
        return {
            saving: false,
        }
    },
    computed: {

    },
    methods: {
        setComplete: async function(){
            this.save()
            // check if any of the forms have invalid items
            this.$store.state.invalidItems = {}

            let relevantSubSections = []
            
            switch (this.$store.state.bgp.details.Type){
                case consts.bgpBeefKey:
                    relevantSubSections = this.$store.state.bgpBeefSubSectionIDs
                    break
                case consts.bgpFoodSafetyKey:
                    relevantSubSections = this.$store.state.foodSafetySubSectionIDs
                    break
                default:
                    relevantSubSections = this.$store.state.bgpSubSectionIDs
                    break
            }

            for(let p in this.$store.state.questions){
                if(this.$store.state.questions[p].partDetails.OPTIONAL == null || (this.$store.state.questions[p].partDetails.OPTIONAL != true || this.$store.state.questions[p].partDetails.OPTIONAL != 1)) {
                    for (let s in this.$store.state.questions[p].sections){
                        if(this.$store.state.questions[p].sections[s].sectionDetails.OPTIONAL == null || (this.$store.state.questions[p].sections[s].sectionDetails.OPTIONAL != true || this.$store.state.questions[p].sectionDetails.OPTIONAL != 1)) {
                            for (let ss in this.$store.state.questions[p].sections[s].subSections){
                                if(this.$store.state.questions[p].sections[s].subSections[ss].subSectionDetails.OPTIONAL == null || (this.$store.state.questions[p].sections[s].subSections[ss].subSectionDetails.OPTIONAL != true || this.$store.state.questions[p].sections[s].subSections[ss].subSectionDetails.OPTIONAL != 1)) {
                                    if (relevantSubSections.includes(parseInt(ss))){
                                        this.$store.state.questions[p].sections[s].subSections[ss].questions.forEach((question)=>{
                                            try {
                                                if(((question.OPTIONAL != 1 || question.OPTIONAL != true) && !question.NlDesc.includes('optioneel')) && question.answer.Type != 'bool'){
                                                    if(!this.$store.state.bgp.answers[question.AppID.toString()] || this.$store.state.bgp.answers[question.AppID.toString()].Value == ' ' || this.$store.state.bgp.answers[question.AppID.toString()].Value.length == 0){     
                                                        // this.$store.state.invalidItems.push(
                                                        //     `${this.$store.state.questions[p].partDetails[`NLDesc`]}=>${this.$store.state.questions[p].sections[s].sectionDetails[`${this.$store.state.lang}Desc`]}=>${this.$store.state.questions[p].sections[s].subSections[ss].subSectionDetails[`${this.$store.state.lang}Desc`]}=>${question[`${this.$store.state.lang}Desc`]}`
                                                        // )
                                                        _.setWith(this.$store.state.invalidItems, [
                                                            this.$store.state.questions[p].partDetails[`NLDesc`],
                                                            this.$store.state.questions[p].sections[s].sectionDetails[`${this.$store.state.lang}Desc`],
                                                            question[`${this.$store.state.lang}Desc`],
                                                        ], question, Object)
                                                    }  
                                                }
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
                    
            }

            console.log(`invalid items are ${JSON.stringify(this.$store.state.invalidItems, undefined, 2)}`)

            if (Object.keys(this.$store.state.invalidItems).length > 0){
                this.$store.state.randomUIKey = Math.random()
                this.$store.state.showErrorDialog = true
            } else {
                this.$store.state.showCompleteDialog = true
            }

        },
        exportBGP: async function(){
        },
        save: async function(){
            ////console.log(`questions in store is ${JSON.stringify(this.$store.state.bgp.answers)}`)
            try {    
                this.saving = true

                let saveSuccessful = await this.saveAll()
                console.log(`saveSuccessful = ${saveSuccessful}`)
                //await this.refreshFromServer()

                if(saveSuccessful == true){
                    this.$store.state.madeBGPChanges = false
                }

                this.saving = false
                
            } catch (e) {
                console.log(e)
                this.$store.state.madeBGPChanges = true
                this.$store.state.snackBarText = this.$store.state.translations[this.$store.state.lang].errorSavingPleaseCheckNetworkConnection
                this.$store.snackBarColour = 'error'
                this.$store.state.showSnackBar = true
                this.saving = false
            }
        }
    },
    created: function(){

    }
}
</script>