import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import BGP from '@/components/BGP.vue'
import Login from '@/components/Login.vue'
import Settings from '@/components/Settings.vue'
import ReportHome from '@/components/ReportHome.vue'
import Report from '@/components/Report.vue'
import UserManagement from '@/components/ManageUsers.vue'
import ReportUserSettings from '@/components/ReportUserSettings.vue'
import ReportLogin from '@/components/ReportLogin.vue'
import Notifiable from '@/components/Notifiable.vue'

import {store} from '@/main.js'

const axios = require('axios')
import consts from './../helpers/Consts'


Vue.use(VueRouter)

let checkIfLoggedIn = async function(to, from, next){
  try {
    let loggedIn = false

    if(consts.env == 'dev'){
      loggedIn = true
    } else {
      loggedIn = await store.state.zlogin.isLoggedIn() 
    }
       
    if(loggedIn){
      next()
    } else {
      // check if we have network access
      try {
        let online = await axios.get(consts[consts.env].apiURL + '/testOnline')
        //console.log(`online is ${JSON.stringify(online, undefined, 2)}`)
        next('/login')
      } catch (e) {
        ////console.log(`no network let through = ${store.state.zlogin.tokensPresent()} ***** ${e}`)
        if(store.state.zlogin.tokensPresent()){
          next()
        } else {
          next('/login')
        }
      }
    }
  } catch (e) {
    //console.log(e)
    try {
      let online = await axios.get(consts[consts.env].apiURL + '/testOnline')
      ////console.log(`online is ${JSON.stringify(online, undefined, 2)}`)
      next('/login')
    } catch (e) {
      ////console.log(`no network let through = ${store.state.zlogin.tokensPresent()} ***** ${e}`)
        if(store.state.zlogin.tokensPresent()){
          next()
        } else {
          next('/login')
        }
    }
  }
  
}

  const routes = [
    {
      path: '/reportUserSettings',
      name: 'reportUserSettings',
      component: ReportUserSettings,
      beforeEnter: (to, from, next)=>{
        if(store.state.reportLoggedIn){
          next()
        } else {
          next('/reportLogin')
        }
      }
    },
    {
      path: '/reportLogin',
      name: 'reportLogin',
      component: ReportLogin,
      beforeEnter: (to, from, next)=>{
        next()
      }
    },
    {
      path: '/reportHome',
      name: 'reportHome',
      component: ReportHome,
      beforeEnter: (to, from, next)=>{
        if(store.state.reportLoggedIn){
          next()
        } else {
          next('/reportLogin')
        }
      }
    },
    {
      path: '/notifiable',
      name: 'notifiable',
      component: Notifiable,
      beforeEnter: (to, from, next)=>{
        if(store.state.reportLoggedIn){
          next()
        } else {
          next('/reportLogin')
        }
      }
    },
    {
      path: '/reports',
      name: 'report',
      component: Report,
      beforeEnter: (to, from, next)=>{
        if(store.state.reportLoggedIn){
          next()
        } else {
          next('/reportLogin')
        }
      }
    },
    {
      path: '/userManagement',
      name: 'userManagement',
      component: UserManagement,
      beforeEnter: (to, from, next)=>{
        if(store.state.reportLoggedIn && (store.state.reportUser.Admin == 1 || store.state.reportUser.Admin == true)){
          next()
        } else {
          next('/login')
        }
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: checkIfLoggedIn
  },
  {
    path: '/bgp',
    name: 'BGP',
    component: BGP,
    beforeEnter: checkIfLoggedIn
  },
  {
    path: '/settings',
    name: 'Instellingen',
    component: Settings,
    beforeEnter: checkIfLoggedIn
  },
  {
    path: '/zlogincallback',
    name: 'ZLogin Callback',
    beforeEnter: (to, from, next) => {
      //console.log(`zlogincallback to is ${JSON.stringify(to, undefined, 2)}`)
      store.state.zlogin.loginToZLogin(to.query.code, to.query.state).then(()=>{
    ////console.log('sending user to home')
        next('/')
      }).catch((e)=>{
        //console.log(e)
        next('/login')
      })
      //next('/login')
    }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
