<template>
    <v-container>
         <v-snackbar
            v-model="showErrorSnackBar"
            >
            {{ $store.state.translations[$store.state.lang].noDataPleaseCheckSettings }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="showErrorSnackBar = false"
                >
                OK
                </v-btn>
            </template>
        </v-snackbar>
        <v-col cols="12">
            <v-row justify="center">
                <h2> {{$store.state.translations[$store.state.lang].notifiableOfficialChecks}} </h2>
            </v-row>
            <v-row justify="center">
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3" class="pa-4 ma-4">
                        <v-subheader>
                            {{$store.state.translations[$store.state.lang].chooseYear}}
                        </v-subheader>
                        <v-select
                            :items="years"
                            v-model="chosenYear"
                            solo
                            v-on:change="getReportData"
                            >
                        </v-select>
                    </v-col>
                    <v-col justify="end" align="end" :cols="$vuetify.breakpoint.smAndDown ? 5 : 3" class="pa-4 ma-4">
                        <!-- <v-row justify="center">
                            <v-btn
                                @click="getReportData"
                                icon
                                color="primary"
                                x-large
                            >
                                {{$store.state.translations[$store.state.lang].search}}
                                <v-icon color="primary">search</v-icon>
                            </v-btn>
                        </v-row> -->

                        <v-row justify="center">
                            <JsonCSV :name="`Jaaroverzicht ${chosenYear}.csv`" :delimiter="';'" :data="getSummaryData()" v-if="initiliased">
                                  <v-btn
                                        icon
                                        color="primary"
                                        x-large
                                    >
                                        {{$store.state.translations[$store.state.lang].exportSummaryData}}
                                        <v-icon color="primary">download</v-icon>
                                    </v-btn>
                            </JsonCSV>
                        </v-row> 
                        <v-row justify="center">
                            <JsonCSV :name="`UBNs ${chosenYear}.csv`" :delimiter="';'" :data="tableData(false)" v-if="initiliased">
                                  <v-btn
                                        icon
                                        color="primary"
                                        x-large
                                    >
                                        {{$store.state.translations[$store.state.lang].exportUBNs}}
                                        <v-icon color="primary">download</v-icon>
                                    </v-btn>
                            </JsonCSV>
                        </v-row>                    
                    </v-col>

                </v-row>
                <v-row justify="center">

                </v-row>
                <v-row justify="center">
                    
                </v-row>
            <v-divider/>
            <v-row justify="center" v-if="loading">
                <v-progress-circular color="primary" size="100px"></v-progress-circular>
            </v-row>
            <span v-else-if="initiliased">
                <v-col>
                    <v-row justify="center" align="center">
                        <bar-chart v-bind:key="randomUIKey" :width="chartWidth" :chartdata="chartData"></bar-chart>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card-title>
                                <v-row>
                                    <v-col cols="10" class="pa-0">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            :label="$store.state.translations[$store.state.lang].searchWithinResults"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2" class="pa-0">
                                        <v-row justify="center" align="center">
                                            <v-btn
                                                @click="getReportData()"
                                                color="primary"
                                                icon
                                            >
                                                <v-icon color="primary">refresh</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-data-table
                                :headers="headers"
                                :items="tableData()"
                                :search="search"
                                calculate-widths
                                mobile-breakpoint="0"
                                >
                                <template v-slot:no-results>
                                    <v-alert :value="true" color="error" icon="warning">
                                    Your search for "{{ search }}" found no results.
                                    </v-alert>
                                </template>

                                <template v-slot:item.UBN="{ item }">
                                    <p class="small"> {{item.UBN}} </p>
                                </template>

                                <template v-slot:item.dateComplete="{ item }">
                                    <span v-if="item.dateComplete != null">
                                        {{moment(item.dateComplete).format("DD/MM/YYYY")}}
                                    </span>
                                </template>

                                <template v-slot:item.reportLink="{ item }">
                                    <v-btn 
                                        text color="primary" small 
                                        @click="downloadFile(item.reportLink)"
                                        v-if="item.reportLink != null"
                                    >
                                        {{$store.state.translations[$store.state.lang].download}}
                                    </v-btn>
                                </template>

                            </v-data-table>
                        </v-col>

                    </v-row>
                </v-col>
            </span>
            
        </v-col>
    </v-container>
</template>

<script>
const axios = require('axios')
import consts from './../helpers/Consts'
const moment = require('moment')
import BarChart from '@/components/BarChart.vue'
import JsonCSV from 'vue-json-csv'

export default {
    components: {
        BarChart,
        JsonCSV
    },
    data () {
        return {
            chartData: null,
            showErrorSnackBar: false,
            chartWidth: 700,
            chosenYear: moment().format('YYYY'),
            loading: false,
            initiliased: false,
            reportData: [],
            randomUIKey: Math.random(),
            jsonOptions: {
                error: this.jsonError()
            },
            search: '',
            headers: [
                { text: this.$store.state.translations[this.$store.state.lang].farmUBN, 
                    value: 'UBN',
                    //width: 10 
                },
                 { text: this.$store.state.translations[this.$store.state.lang].vetUDN, 
                    value: 'UDN',
                    //width: 10 
                },
                 { text: this.$store.state.translations[this.$store.state.lang].dateComplete, 
                    value: 'dateComplete',
                    //width: 10 
                },                
                 { text: this.$store.state.translations[this.$store.state.lang].reportLink, 
                    value: 'reportLink',
                    //width: 10 
                },
            ]
        }
    },
    computed: {
        // return array of years available to choose in dropdown
        years: function(){
            let startYear = 2020;
            let endYear = moment().format('YYYY')
            let result = [];
            //console.log(`startYear ${startYear} endYear ${endYear}`)

            for(let i = startYear; i<=endYear; i++){
                result.push(i+'')
            }

            //console.log(`list of dates is ${result}`)
            return result

        }
    },
    methods: {
        jsonError: function(error, file){
            //console.log(`JSON error ${JSON.stringify(error)} file ${JSON.stringify(file, undefined, 2)}`)
            this.showErrorSnackBar = true
        },
        getSummaryData: function(){
            let temp = this.chartData.datasets
            //console.log(`temp is ${JSON.stringify(temp, undefined, 2)}`)
            let result = []
            try{
                temp.forEach((metric)=>{
                    result.push({
                        '_': metric.label,
                        Q1: metric.data[0],
                        Q2: metric.data[1],
                        Q3: metric.data[2],
                        Q4: metric.data[3]
                    })
                })
            } catch (e) {
                console.log(e)
            }
            return result
        },
        createChartData: function(){
            let labels = ['Q1', 'Q2', 'Q3', 'Q4']
            let totalPerQuarterData = []
            let totalUBNsPerQuarter = []
            let totalNotifiablePerQuarter = []
            let datasets = []

            labels.forEach((label)=>{
                let result = {}
                let data = []

                try{
                    if(Object.keys(this.reportData.totalPerQuarter).includes(this.chosenYear)){
                        let quarter = label.split('Q')
                        quarter = quarter[1]
                        //console.log(`quarter is ${quarter}`)
                        if(Object.keys(this.reportData.totalPerQuarter[this.chosenYear]).includes(quarter)){
                            totalPerQuarterData.push(this.reportData.totalPerQuarter[this.chosenYear][quarter].Created)
                        } else {
                            //console.log(`total per quarter pushing 0`)
                            totalPerQuarterData.push(0)
                        }
                    }

                    if(Object.keys(this.reportData.totalUBNsPerQuarter).includes(this.chosenYear)){
                        let quarter = label.split('Q')
                        quarter = quarter[1]
                        //console.log(`quarter is ${quarter}`)
                        if(Object.keys(this.reportData.totalUBNsPerQuarter[this.chosenYear]).includes(quarter)){
                            totalUBNsPerQuarter.push(this.reportData.totalUBNsPerQuarter[this.chosenYear][quarter].Created)
                        } else {
                            //console.log(`total ubns per quarter pushing 0`)
                            totalUBNsPerQuarter.push(0)
                        }
                    }

                        if(Object.keys(this.reportData.numNotifiableBGPs).includes(this.chosenYear)){
                        let quarter = label.split('Q')
                        quarter = quarter[1]
                        //console.log(`quarter is ${quarter}`)
                        if(Object.keys(this.reportData.numNotifiableBGPs[this.chosenYear]).includes(quarter)){
                            totalNotifiablePerQuarter.push(this.reportData.numNotifiableBGPs[this.chosenYear][quarter].Created)
                        } else {
                            //console.log(`total notifiable per quarter pushing 0`)
                            totalNotifiablePerQuarter.push(0)
                        }
                    }
                } catch (e) {
                    console.log(e)
                }

            })

            datasets.push({
                label: this.$store.state.translations[this.$store.state.lang].totalOfficalChecksPerQuarter,
                backgroundColor: '#9C27B0',
                data: totalPerQuarterData
            })

            datasets.push({
                label: this.$store.state.translations[this.$store.state.lang].totalUBNsPerQuarter,
                backgroundColor: '#E1BEE7',
                data: totalUBNsPerQuarter
            })

            datasets.push({
                label: this.$store.state.translations[this.$store.state.lang].totalNotifiablePerQuarter,
                backgroundColor: '#C2185B',
                data: totalNotifiablePerQuarter
            })
            

            this.chartData= {
                labels: labels,
                datasets: datasets
            }

            //console.log(`chartdata is ${JSON.stringify(this.chartData, undefined, 2)}`)
        },

        downloadFile: async function(fileName){

            axios({
                url: `${consts[consts.env].apiURL}/downloadFileReportUser/${fileName}`,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    email: this.$store.state.reportUser.Email,
                    password: this.$store.state.reportUser.Password 
                }
            }).then((response)=>{
                ////console.log(`response is ${JSON.stringify(response, undefined, 2)}`)
                var blob = new Blob([response]);

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            }).catch ((e)=>{
                //console.log(e)
            })          
        },
        moment: function(pDate){
            return moment(pDate)
        },
        getCSVData: function(){
            let result = {}
            result.UBNs = this.tableData(false)
            result.data = this.createChartData()
            return result
        },
        tableData: function(includeReportLink = true, exportingViaButton = false){
            let res = []

            this.reportData.notifiableUBNs.forEach((report)=>{
                //console.log(`report is ${JSON.stringify(report, undefined, 2)}`)
                if(includeReportLink){
                    res.push({
                        UBN: report.UBN,
                        UDN: report.UDN,
                        dateComplete: report.Completed != null ? moment(report.Completed).valueOf(): null,
                        reportLink: report.Completed != null ? report.FoodSafetyPDF : null
                    })
                } else {
                    res.push({
                        UBN: report.UBN,
                        UDN: report.UDN,
                        dateComplete: report.Completed != null ? moment(report.Completed).format("DD/MM/YYYY") : null,
                    })
                }
                
            })



            //console.log(`res is ${JSON.stringify(res, undefined, 2)}`)
            // if (exportingViaButton == true && res.length == 0){
            //     console.log('showing snackbar')
            //     this.showErrorSnackBar = true
            // }
            
            return res
        },
        getReportData: async function(){
            try {
                this.loading = true
                this.reportData = await axios.get(consts[consts.env].apiURL + '/getProblematic?' + `year=${this.chosenYear}`,{
                    headers: {
                        email: this.$store.state.reportUser.Email,
                        password: this.$store.state.reportUser.Password
                    }
                })
                //console.log(`reportData is ${JSON.stringify(this.reportData, undefined, 2)}`)
                this.loading = false
                this.createChartData()
                this.initiliased = true
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        onResize() {
            this.chartWidth = window.innerWidth - 100
            // force a redraw
            this.randomUIKey = Math.random()
        }

    },
    created: async function(){
        //console.log(`getting report data`)
        this.getReportData()
    },
    mounted: function(){
         this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    }
}
</script>