<template>
    <v-container :key="
        Object.keys($store.state.questionsUIKeys).includes(question.AppID.toString()) ?
        $store.state.questionsUIKeys[question.AppID.toString()] :
        uiKey
    ">
        <!-- questions for part c condition incidence and antimicrobial usage -->
        <span v-if="(question.AppID >= 94 && question.AppID <=112) || (question.AppID >= 1094 && question.AppID <=1112)">

            <span v-if="question.SubSectionAppID == -1 || question.SubSectionAppID == -1001"> 
                <v-row class="pa-0">
                    <p class="smallQuestionText pa-0 ma-0">{{question[`${$store.state.lang}Desc`]}}</p>
                    <!-- <v-tooltip
                            bottom
                            v-if="question[`${$store.state.lang}Tooltip`] != null && question[`${$store.state.lang}Tooltip`].length>0"
                        >
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        {{question[`${$store.state.lang}Tooltip`]}}
                    </v-tooltip> -->
                </v-row>  
            </span>
            <v-row>
                <v-col :cols="$vuetify.breakpoint.xs ? 10 : 3">
                    <v-text-field
                        :id="question.AppID + '_0'"
                        :type="question.answer.Type == 'string' ? 'text' : 'number'"
                        v-model="$store.state.bgp.answers[question.AppID.toString()].PrevBGPValue"
                        :label="$store.state.translations[$store.state.lang].prevBGP"
                        @wheel="$event.target.blur()"
                        class="pr-2"
                        disabled
                        required
                        @change="$store.state.madeBGPChanges = true; $store.state.runFormValidation = true;"
                    >

                    </v-text-field>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.xs ? 10 : 3">
                    <v-text-field
                        :id="question.AppID + '_1'"
                        :type="question.answer.Type == 'string' ? 'text' : 'number'"
                        @wheel="$event.target.blur()"
                        v-model="$store.state.bgp.answers[question.AppID.toString()].Value"
                        :label="$store.state.translations[$store.state.lang].value"
                        class="pr-2"
                        required
                        :rules="getRules(question)"
                        @change="$store.state.madeBGPChanges = true; $store.state.runFormValidation = true;"
                    />
                </v-col>

                <v-col :cols="$vuetify.breakpoint.xs ? 10 : 3" v-if="(question.AppID>=109 && question.AppID<=112) || (question.AppID>=1109 && question.AppID<=1112)">
                    
                    <v-radio-group v-model="$store.state.bgp.answers[question.AppID.toString()].Usage"
                            :label="$store.state.translations[$store.state.lang].usageIs"
                            :rules="[rules.requiredValue]"
                        >
                            <v-radio
                                color="primary"
                                v-for="option in $store.state.usageOptions"
                                :key="option.AppID"
                                :label="`${option[$store.state.lang+'Desc']}`"
                                :value="option.AppID.toString()"
                                @change="$store.state.madeBGPChanges = true; refreshKey(null)"
                            ></v-radio>
                        </v-radio-group>
                
                </v-col>

                <v-col :cols="$vuetify.breakpoint.xs ? 10 : 3">
                    <!-- antimicrobial target use is options -->
                    <v-text-field
                        :id="question.AppID + '_2'"
                        :type="question.answer.Type == 'string' ? 'text' : 'number'"
                        v-model="$store.state.bgp.answers[question.AppID.toString()].TargetValue"
                        @wheel="$event.target.blur()"
                        :label="$store.state.translations[$store.state.lang].target"
                        class="pr-2"
                        required
                        :rules="getRules(question)"
                        @change="$store.state.madeBGPChanges = true; $store.state.runFormValidation = true;"
                        
                    />
                     <!-- <v-radio-group v-model="$store.state.bgp.answers[question.AppID.toString()].TargetValue" v-else
                        :label="$store.state.translations[$store.state.lang].target"
                     >
                        <v-radio
                            color="primary"
                            v-for="option in question.answer.options"
                            :key="option.AppID"
                            :label="`${option[$store.state.lang+'Desc']}`"
                            :value="option.AppID.toString()"
                            @change="$store.state.madeBGPChanges = true; refreshKey()"
                        ></v-radio>
                    </v-radio-group> -->

                </v-col>
            </v-row>
        </span>
        <span v-else>
            <v-col cols="12" v-if="question.answer.Type== 'date'">
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="$store.state.bgp.answers[question.AppID.toString()].Value"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="$store.state.bgp.answers[question.AppID.toString()].Value"
                        :label="$store.state.translations[$store.state.lang].date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        :rules="getRules(question)"
                    >
                    </v-text-field>
                    </template>
                    <v-date-picker 
                        v-model="$store.state.bgp.answers[question.AppID.toString()].Value" 
                        scrollable
                        @change="$store.state.madeBGPChanges = true"
                        :first-day-of-week="1"    
                    >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save($store.state.bgp.answers[question.AppID.toString()].Value)">OK</v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <span v-else-if="question.answer.Type == 'option'">
    
                <span v-if="question.SubSectionAppID == -1  || question.SubSectionAppID == -1001">
                    <v-row class="pa-0">
                        <p class="smallQuestionText pa-0 ma-0">{{question[`${$store.state.lang}Desc`]}}</p>
                        <v-tooltip
                                bottom
                                v-if="question[`${$store.state.lang}Tooltip`] != null && question[`${$store.state.lang}Tooltip`].length>0"
                            >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            {{question[`${$store.state.lang}Tooltip`]}}
                        </v-tooltip>
                    </v-row>
                </span>
                <v-radio-group v-model="$store.state.bgp.answers[question.AppID.toString()].Value"
                    :rules="getRules(question)"
                >
                    <v-radio
                        color="primary"
                        v-for="option in question.answer.options"
                        :key="option.AppID"
                        :label="`${option[$store.state.lang+'Desc']}`"
                        :value="option.AppID.toString()"
                        @change="$store.state.madeBGPChanges = true; refreshKey(null)"
                    ></v-radio>
                </v-radio-group>
            </span>

            <span v-else-if="question.answer.Type == 'bool'">
                <span v-if="question.SubSectionAppID == -1  || question.SubSectionAppID == -1001"> 
                    <v-row class="pa-0">
                        <p class="smallQuestionText pa-0 ma-0">{{question[`${$store.state.lang}Desc`]}}</p>
                        <v-tooltip
                                bottom
                                v-if="question[`${$store.state.lang}Tooltip`] != null && question[`${$store.state.lang}Tooltip`].length>0"
                            >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            {{question[`${$store.state.lang}Tooltip`]}}
                        </v-tooltip>
                    </v-row>
                </span>
                <v-switch 
                    v-model="$store.state.bgp.answers[question.AppID.toString()].Value"
                    :id="question.AppID.toString()"
                    required
                    @change="$store.state.madeBGPChanges = true; refreshKey(null)"
                    :label="$store.state.bgp.answers[question.AppID.toString()].Value ? ($store.state.bgp.answers[question.AppID.toString()].Value == 1 ? `${$store.state.translations[$store.state.lang].yes}` : `${$store.state.translations[$store.state.lang].no}`) : `${$store.state.translations[$store.state.lang].no}`"
                ></v-switch>
            </span>

            <span  v-else-if="question.answer.Type == 'freeText'">
                <span v-if="question.SubSectionAppID == -1  || question.SubSectionAppID == -1001"> 
                    <v-row class="pa-0">
                        <p class="smallQuestionText pa-0 ma-0">{{question[`${$store.state.lang}Desc`]}}</p>
                        <v-tooltip
                                bottom
                                v-if="question[`${$store.state.lang}Tooltip`] != null && question[`${$store.state.lang}Tooltip`].length>0"
                            >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            {{question[`${$store.state.lang}Tooltip`]}}
                        </v-tooltip>
                    </v-row>
                </span>
                <v-textarea
                    v-model="$store.state.bgp.answers[question.AppID.toString()].Value"
                    :id="question.AppID.toString()"
                    required
                    :rows='(question.answer.MaxLength != null && question.answer.MaxLength > 0) ? question.answer.MaxLength : 5'
                    @change="$store.state.madeBGPChanges = true; $store.state.runFormValidation = true;"
                    :rules="getRules(question)"
                    :auto-grow='false'
                    :no-resize='true'
                ></v-textarea>
            </span>

            <span v-else>
                <span v-if="question.SubSectionAppID == -1 || question.SubSectionAppID == -1001"> 
                    <v-row class="pa-0">
                        <p class="smallQuestionText pa-0 ma-0">{{question[`${$store.state.lang}Desc`]}}</p>
                        <v-tooltip
                                bottom
                                v-if="question[`${$store.state.lang}Tooltip`] != null && question[`${$store.state.lang}Tooltip`].length>0"
                            >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            {{question[`${$store.state.lang}Tooltip`]}}
                        </v-tooltip>
                    </v-row>
                </span>   
                <v-text-field
                    :id="question.AppID.toString()"
                    :type="question.answer.Type == 'string' ? 'text' : 'number'"
                    v-model="$store.state.bgp.answers[question.AppID.toString()].Value"
                    class="pa-0"
                    required
                    :rules="getRules(question)"
                    @wheel="$event.target.blur()"
                    @change="runCalculatedFields(question); $store.state.madeBGPChanges = true; $store.state.runFormValidation = true;"
                    :counter="question.answer.Type == 'string' && !questionsToPassForValidation.includes(question.AppID) ? (question.answer.MaxLength) : null" 
                  >
                </v-text-field>
            </span>
        </span>

        <!-- follow up question for options -->
        <answerComponent
            v-if="question.answer.Type == 'option' && 
            chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 1).hasFollowUp"
            :question="chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 1).followUpQuestion"
            :key="chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 1).followUpQuestion.ID"
        />

        <answerComponent
            v-if="question.answer.Type == 'option' && 
            chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 2).hasFollowUp"
            :question="chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 2).followUpQuestion"
            :key="chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 2).followUpQuestion.ID"
        />

        <answerComponent
            v-if="question.answer.Type == 'option' && 
            chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 3).hasFollowUp"
            :question="chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 3).followUpQuestion"
            :key="chosenOptionHasFollowUp($store.state.bgp.answers[question.AppID.toString()].Value.toString(), 3).followUpQuestion.ID"
        />        

        <!-- follow up questions for switches -->

        <answerComponent 
        v-if="question.FollowUpPositiveID != null && question.FollowUpPositiveID != 0
            && ((question.answer.Type == 'bool' && $store.state.bgp.answers[question.AppID.toString()].Value == true))"
            :question="question.FollowUpPositiveID"
            :key="question.FollowUpPositiveID.ID"
        />

        <answerComponent v-if="question.FollowUpPositiveID2 != null && question.FollowUpPositiveID2 != 0
            && ((question.answer.Type == 'bool' && $store.state.bgp.answers[question.AppID.toString()].Value == true))"
            :question="question.FollowUpPositiveID2"
            :key="question.FollowUpPositiveID2.ID"
        />

        <answerComponent v-if="question.FollowUpPositiveID3 != null && question.FollowUpPositiveID3 != 0
            && ((question.answer.Type == 'bool' && $store.state.bgp.answers[question.AppID.toString()].Value == true))"
            :question="question.FollowUpPositiveID3"
            :key="question.FollowUpPositiveID3.ID"
        />

        <answerComponent v-if="question.FollowUpNegativeID != null && question.FollowUpNegativeID != 0
            && ((question.answer.Type == 'bool' 
            && (!$store.state.bgp.answers[question.AppID.toString()] || $store.state.bgp.answers[question.AppID.toString()].Value == false)))"
            :question="question.FollowUpNegativeID"
            :key="question.FollowUpNegativeID.ID"
        />

        <answerComponent v-if="question.FollowUpNegativeID2 != null && question.FollowUpNegativeID2 != 0
            && ((question.answer.Type == 'bool'
            && (!$store.state.bgp.answers[question.AppID.toString()] || $store.state.bgp.answers[question.AppID.toString()].Value == false)))"
            :question="question.FollowUpNegativeID2"
            :key="question.FollowUpNegativeID2.ID"
        />
        

    </v-container>
</template>

<script>
const moment = require('moment')
import consts from './../helpers/Consts'

export default {
    name: 'answerComponent',
    props: ['question'],
    data () {
        return {
            modal: false,
            uiKey: Math.random(),
            questionsToPassForValidation: [17,72,73,76,191,198,1017,1072,1076],
            rules: {
                maxLengthLessThan2000: (value)=>{
                    return value.length <= 2000 ? true : `${this.$store.state.translations[this.$store.state.lang].maxLengthLessThan} 2000`
                },
                counter: (value)=>{
                    //console.log(`running counter for value ${value} max length ${JSON.stringify(this.question, undefined, 2)}`)
                    // excluding free text here because max length is used to denote the number of lines
                    if(this.question.answer.Type != 'freeText' && this.question.answer.MaxLength && this.question.answer.MaxLength != 0 && !this.questionsToPassForValidation.includes(this.question.AppID)){
                        let res = value.toString().length <= this.question.answer.MaxLength
                        return res ? true : `${this.$store.state.translations[this.$store.state.lang].maxLengthLessThan} ${this.question.answer.MaxLength.toString()}`
                    } else{
                        return true
                    }  
                },
                requiredText: (value)=>{
                    const nonWhitespace = new RegExp('(\\S+)');
                    return nonWhitespace.test(value) ? true : 'Minstens een letter of cijfer vereist.'
                },
                requiredNumber: (value)=>{
                    const numberOnly = new RegExp('\\d+\\.?\\,?\\d*');
                    return numberOnly.test(value) ? true : 'Nummer vereist.'
                },
                requiredDate: (value)=>{
                    return value.toString().length > 4 ? true : 'Datum benodigd'
                },
                requiredValue: (value)=>{
                    return value.toString().length>0 ? true : 'Optie vereist.'
                }
            }
        }
    },
    computed: {
    },
    methods: {
        getDatePickerValue: function(){
            try {
                if(this.$store.state.bgp.answers[this.question.AppID.toString()].Value.length > 9){
                    let result = moment(this.$store.state.bgp.answers[this.question.AppID.toString()].Value).format("YYYY-MM-DD")
                   if(result != 'Invalid date'){
                        return result
                    } else {
                        throw 'invalid date'
                    }
                } else {
                    throw 'invalid date'
                }
                    
            } catch (e) {
                console.log(`${e} returning ${moment().format("YYYY-MM-DD")}`)
                return moment().format("YYYY-MM-DD")
            }

        },
        getRules: function (question){
            let result = []
            // always push max length rule to ensure users are warned on optional fields
            if(question.answer.Type != 'option'){
                result.push(this.rules.maxLengthLessThan2000)
            }
            // exclude followup or optional
            if((question.SubSectionAppID != -1 && question.SubSectionAppID != -1001) && (! question.NlDesc.includes('optioneel')) && (question.OPTIONAL != null && (question.OPTIONAL != true || question.OPTIONAL != 1))){
                if(question.answer.Type == 'string' || question.answer.Type == 'freeText'){
                    result.push(this.rules.requiredText)
                } else if (question.answer.Type == 'int' || question.answer.Type == 'decimal'){
                    result.push(this.rules.requiredNumber)
                }

                if(question.answer.Type != 'option'){
                    result.push(this.rules.counter)
                }

                if(question.answer.Type == 'date'){
                    result.push(this.rules.requiredDate)
                }

                if(question.answer.Type == 'option'){
                    result.push(this.rules.requiredValue)
                }
            }
            return result
        },
        runCalculatedFields: function(question){
            try {
                //$store.state.bgp.answers[question.AppID.toString()].Value
                let forceRerunCalc = (question.AppID==6 || question.AppID==8 || question.AppID==70 || question.AppID==71 || question.AppID==1008 || question.AppID==1070)

                if(question.AppID == '17' || forceRerunCalc){
                    try {
                        //console.log(`rerunning calc for num feed diary`)
                        let numStillborn = parseInt(this.$store.state.bgp.answers["17"].Value.split('(')[0])
                        let numCalvesBorn = parseInt(this.$store.state.bgp.answers["8"].Value)
                        //console.log(`numFeedDiary ${numFeedDiary} ${isNaN(numFeedDiary)} numDiaryCows ${numDiaryCows} ${isNaN(numDiaryCows)}`)
                        if( (! isNaN(numStillborn)) && (! isNaN(numCalvesBorn))){
                            let pc = ((numStillborn / numCalvesBorn) * 100).toFixed(2)
                            //console.log(`pc is ${pc}`)
                            this.$store.state.bgp.answers["17"].Value = numStillborn + ` (${pc != Infinity ? pc : '-'}%)`
                            //console.log(`answer is ${this.$store.state.bgp.answers["72"].Value}`)
                            this.refreshKey("17")
                        }
                    } catch (e) {
                        console.log(e)
                    }  
                } 


                if(question.AppID == '72' || forceRerunCalc){
                    try {
                        //console.log(`rerunning calc for num feed diary`)
                        let numFeedDiary = parseInt(this.$store.state.bgp.answers["72"].Value.split('(')[0])
                        let numDiaryCows = parseInt(this.$store.state.bgp.answers["70"].Value)
                        //console.log(`numFeedDiary ${numFeedDiary} ${isNaN(numFeedDiary)} numDiaryCows ${numDiaryCows} ${isNaN(numDiaryCows)}`)
                        if( (! isNaN(numFeedDiary)) && (! isNaN(numDiaryCows))){
                            let pc = ((numFeedDiary / numDiaryCows) * 100).toFixed(2)
                            //console.log(`pc is ${pc}`)
                            this.$store.state.bgp.answers["72"].Value = numFeedDiary + ` (${pc != Infinity ? pc : '-'}%)`
                            //console.log(`answer is ${this.$store.state.bgp.answers["72"].Value}`)
                            this.refreshKey("72")
                        }
                    } catch (e) {
                        //console.log(e)
                    }  
                } 
                
                if(question.AppID == '73' || forceRerunCalc){
                    try {
                        let numFeedDry = parseInt(this.$store.state.bgp.answers["73"].Value.split('(')[0])
                        let numDryCows = parseInt(this.$store.state.bgp.answers["71"].Value)
                        if( (! isNaN(numFeedDry)) && (! isNaN(numDryCows))){
                            let pc = ((numFeedDry / numDryCows) * 100).toFixed(2)
                            this.$store.state.bgp.answers["73"].Value = numFeedDry + ` (${pc != Infinity ? pc : '-'}%)`
                            this.refreshKey("73")
                        }
                    } catch (e) {
                        //console.log(e)
                    }  
                } 
                
                if(question.AppID == '76' || forceRerunCalc){
                    try {
                        let num1 = parseInt(this.$store.state.bgp.answers["76"].Value.split('(')[0])
                        let num2 = parseInt(this.$store.state.bgp.answers["70"].Value)
                        if( (! isNaN(num1)) && (! isNaN(num2))){
                            let pc = ((num1 / num2) * 100).toFixed(2)
                            this.$store.state.bgp.answers["76"].Value = num1 + ` (${pc != Infinity ? pc : '-'}%)`
                            this.refreshKey("76")
                        }
                    } catch (e) {
                        //console.log(e)
                    }  
                } 
                
                if(question.AppID == '191' || forceRerunCalc){
                    try {
                        let num1 = parseInt(this.$store.state.bgp.answers["191"].Value.split('(')[0])
                        let num2 = parseInt(this.$store.state.bgp.answers["71"].Value)
                        if( (! isNaN(num1)) && (! isNaN(num2))){
                            let pc = ((num1 / num2) * 100).toFixed(2)
                            this.$store.state.bgp.answers["191"].Value = num1 + ` (${pc != Infinity ? pc : '-'}%)`
                            this.refreshKey("191")
                        }
                    } catch (e) {
                        //console.log(e)
                    }  
                }

                if(question.AppID == '198' || forceRerunCalc){
                   // if(this.$store.state.bgp.details.Type == consts.bgpBeefKey){
                   //     this.calculatePercentage('198', '1006')
                   // } else {
                        this.calculatePercentage('198', '6')
                    //}
                }

                if(question.AppID == '1017' || forceRerunCalc){
                    this.calculatePercentage('1017', '1008')
                }

                if(question.AppID == '1072' || forceRerunCalc){
                    this.calculatePercentage('1072', '1070')
                }

                if(question.AppID == '1076' || forceRerunCalc){
                    this.calculatePercentage('1076', '1070')
                }
            } catch (e) {
                //console.log(e)
            }
        },
        calculatePercentage: function(numeratorQID, denominatorQID){
            try {
                let num1 = parseInt(this.$store.state.bgp.answers[numeratorQID].Value.split('(')[0])
                let num2 = parseInt(this.$store.state.bgp.answers[denominatorQID].Value)
                if( (! isNaN(num1)) && (! isNaN(num2))){
                    let pc = ((num1 / num2) * 100).toFixed(2)
                    this.$store.state.bgp.answers[numeratorQID].Value = num1 + ` (${pc != Infinity ? pc : '-'}%)`
                    this.refreshKey(numeratorQID)
                }
            } catch (e) {
                console.log(e)
            }  
        },
        refreshKey: function(pDependantQuestionAppIDToRefresh){
            this.uiKey = Math.random()
            if(pDependantQuestionAppIDToRefresh != null){
                this.$store.state.questionsUIKeys[pDependantQuestionAppIDToRefresh]=Math.random()
            }            
            console.log(`question ui keys = ${JSON.stringify(this.$store.state.questionsUIKeys, undefined, 2)}`)
        },
        chosenOptionHasFollowUp: function(pOptionID, pLevel){

            let res = {
                'hasFollowUp': false,
                'followUpQuestion': {}
            }

            let option = this.question.answer.options.find((op)=>{
                return op.AppID == pOptionID
            })

            //console.log(`level ${pLevel} option is ${JSON.stringify(option, undefined, 2)}`)
            if(pLevel == 1){
                if(option && option.FollowUpQuestionAppID != null && option.FollowUpQuestionAppID != 0){
                    res.hasFollowUp = true
                    res.followUpQuestion = option.followUpQuestion
                }
            } else {
                if(option && option[`FollowUpQuestionAppID${pLevel}`] != null && option[`FollowUpQuestionAppID${pLevel}`] != 0){
                    res.hasFollowUp = true
                    res.followUpQuestion = option[`followUpQuestion${pLevel}`]
                }
            }
           

            //console.log(`result is ${JSON.stringify(res, undefined, 2)}`)

            return res
        }
    },
    mounted: function() {
        // store the ui key so we can refresh questions
    },
    created: function(){
        if(! this.$store.state.bgp.answers[this.question.AppID.toString()]){
            this.$store.state.bgp.answers[this.question.AppID.toString()] = {
                'Value': '',
                'PrevBGPValue': '',
                'TargetValue': '',
                'Usage': ''
            }
        }
        //initilise date (this function checks if invalid and so on)
        if (this.question.answer.Type == 'date'){
            console.log(`question is ${JSON.stringify(this.question, undefined, 2)}`)
            this.$store.state.bgp.answers[this.question.AppID.toString()].Value = this.getDatePickerValue()
        }
        
    }
}
</script>