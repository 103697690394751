var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-col',[_c('v-row',[_c('h5',[_vm._v(" Selectie vindt risico gestuurd plaats. Dat houdt in dat dieren die tijdens de koppel inspectie zijn opgevallen sowieso in de steekproef zitten. Indien er geen dieren zijn opgevallen worden er aselect dieren gekozen. Er worden minimaal 5 dieren onderzocht. ")])]),_c('v-row',{attrs:{"justify":"center"}},_vm._l((this.$store.state.bgp.answers.abnormal),function(item){return _c('span',{key:item.AnimalID},[(item.AnimalID.length>0)?_c('v-chip',{staticClass:"pa-2",attrs:{"color":"primary","pill":""}},[_vm._v(" "+_vm._s(item.AnimalID)+" ")]):_vm._e()],1)}),0),_c('v-row',{attrs:{"justify":"center"}},[_c('v-data-table',{staticClass:"tallRows",attrs:{"headers":_vm.headers,"items":this.$store.state.bgp.answers.abnormal,"mobile-breakpoint":2000},scopedSlots:_vm._u([{key:"item.AnimalID",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pr-5"},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_vm._v(" Diernummer is het vier-cijferige werknummer ")]),_c('v-text-field',{attrs:{"label":_vm.$store.state.lang == 'En' ? 'Animal ID' : 'Diernummer',"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true;}},model:{value:(item.AnimalID),callback:function ($$v) {_vm.$set(item, "AnimalID", $$v)},expression:"item.AnimalID"}})],1)]}},{key:"item.GeneralImpression",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.generalImpressionDropdownValues[_vm.$store.state.lang],"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.GeneralImpression),callback:function ($$v) {_vm.$set(item, "GeneralImpression", $$v)},expression:"item.GeneralImpression"}})],1)],1)]}},{key:"item.Respiration",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":_vm.$store.state.lang == 'en' ? 'Respiration' : 'Ademhaling',"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.Respiration),callback:function ($$v) {_vm.$set(item, "Respiration", $$v)},expression:"item.Respiration"}})]}},{key:"item.HeartRate",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":_vm.$store.state.lang == 'En' ? 'Heart Rate' : 'Pois',"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.HeartRate),callback:function ($$v) {_vm.$set(item, "HeartRate", $$v)},expression:"item.HeartRate"}})]}},{key:"item.Temperature",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":_vm.$store.state.lang == 'En' ? 'Temperature' : 'Temperatuur',"single-line":""},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.Temperature),callback:function ($$v) {_vm.$set(item, "Temperature", $$v)},expression:"item.Temperature"}})]}},{key:"item.BCS",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.BCS),callback:function ($$v) {_vm.$set(item, "BCS", $$v)},expression:"item.BCS"}})],1)],1)]}},{key:"item.ReumenFill",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.ReumenFill),callback:function ($$v) {_vm.$set(item, "ReumenFill", $$v)},expression:"item.ReumenFill"}})],1)],1)]}},{key:"item.MobilityScore",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.numericDropDownValues,"disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.MobilityScore),callback:function ($$v) {_vm.$set(item, "MobilityScore", $$v)},expression:"item.MobilityScore"}})],1)],1)]}},{key:"item.ExcessiveDischarge",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":item.AnimalID.length==0,"label":item.ExcessiveDischarge ? (item.ExcessiveDischarge == 1 ? ("" + (_vm.$store.state.translations[_vm.$store.state.lang].yes)) : ("" + (_vm.$store.state.translations[_vm.$store.state.lang].no))) : ("" + (_vm.$store.state.translations[_vm.$store.state.lang].no))},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.ExcessiveDischarge),callback:function ($$v) {_vm.$set(item, "ExcessiveDischarge", $$v)},expression:"item.ExcessiveDischarge"}},[_vm._v(_vm._s(item.discharge))])]}},{key:"item.Udder",fn:function(ref){
var item = ref.item;
return [_c('br'),_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pr-5"},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_vm._v(" Het uier wordt minimaal beoordeeld op de volgende punten: uierspanning, kleur uier, samenstelling melk ")]),_c('v-textarea',{attrs:{"rows":"2","disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.Udder),callback:function ($$v) {_vm.$set(item, "Udder", $$v)},expression:"item.Udder"}})],1),_c('br')]}},{key:"item.OtherAbnormalities",fn:function(ref){
var item = ref.item;
return [_c('br'),_c('v-textarea',{attrs:{"rows":"2","disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.OtherAbnormalities),callback:function ($$v) {_vm.$set(item, "OtherAbnormalities", $$v)},expression:"item.OtherAbnormalities"}}),_c('br')]}},{key:"item.NEBData",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"pa-0"},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pr-5"},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_vm._v(" Of er sprake is van negatieve energiebalans (NEB) is vast te stellen via de melkcontrole uitslag of (routinematige) diagnostiek ")]),_c('v-switch',{attrs:{"disabled":item.AnimalID.length==0,"label":item.NEBData ? (item.NEBData == 1 ? ("" + (_vm.$store.state.translations[_vm.$store.state.lang].yes)) : ("" + (_vm.$store.state.translations[_vm.$store.state.lang].no))) : ("" + (_vm.$store.state.translations[_vm.$store.state.lang].no))},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.NEBData),callback:function ($$v) {_vm.$set(item, "NEBData", $$v)},expression:"item.NEBData"}},[_vm._v(_vm._s(item.NEBData))])],1),(item.NEBData == 1 || item.NEBData == true)?_c('v-row',[_c('v-textarea',{attrs:{"label":_vm.$store.state.lang == 'En' ? 'If yes, is it a case of NEB?' : 'Zo ja, is er sprake van NEB?',"rows":"2","disabled":item.AnimalID.length==0},on:{"change":function($event){_vm.$store.state.madeBGPChanges = true}},model:{value:(item.CaseOfNEB),callback:function ($$v) {_vm.$set(item, "CaseOfNEB", $$v)},expression:"item.CaseOfNEB"}})],1):_vm._e()],1)]}}])})],1),_c('v-btn',{attrs:{"fab":"","color":"primary","disabled":this.$store.state.bgp.answers.abnormal[this.$store.state.bgp.answers.abnormal.length-1].AnimalID.length == 0},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }